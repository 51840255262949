import { PipesService } from 'src/app/services/pipes.service';
import { OnInit } from "@angular/core";
import { FormControlDirective } from "@angular/forms";
import { AppService } from "src/app/services/app.service";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import emailMask from "text-mask-addons/dist/emailMask";
var FormRenderComponent = /** @class */ (function () {
    function FormRenderComponent(endpoint, pipesService) {
        var _this = this;
        this.endpoint = endpoint;
        this.pipesService = pipesService;
        // REGEX
        this.name_regex = /^[\p{L}\s']*$/u;
        this.number_regex = /^[0-9]+$/;
        this.email_regex = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/;
        // MASK
        this.hn_identification_mask = [
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            "-",
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            "-",
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ];
        this.hn_phone_mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        this.number_mask = createNumberMask({
            allowNegative: false,
            allowDecimal: false,
            prefix: "",
            includeThousandsSeparator: false,
            allowLeadingZeroes: true,
        });
        this.email_mask = emailMask;
        this.integer_mask = createNumberMask({
            allowNegative: false,
            allowDecimal: false,
            integerLimit: 25,
            prefix: "",
            includeThousandsSeparator: true,
        });
        this.decimal_mask = createNumberMask({
            allowNegative: false,
            allowDecimal: true,
            integerLimit: 25,
            decimalLimit: 25,
            prefix: "",
            includeThousandsSeparator: true,
        });
        this.days = [
            { id: 0, name: "Lunes" },
            { id: 1, name: "Martes" },
            { id: 2, name: "Miércoles" },
            { id: 3, name: "Jueves" },
            { id: 4, name: "Viernes" },
            { id: 5, name: "Sábado" },
            { id: 6, name: "Domingo" }
        ];
        this.input_name_stuff = {
            paste: function (event, callback) {
                var clipboardData = event.clipboardData || window["clipboardData"];
                var pastedText = clipboardData.getData("text");
                if (callback)
                    callback(pastedText);
                if (_this.name_regex.test(pastedText))
                    return true;
                event.preventDefault();
                return false;
            },
            keypress: function (event, callback) {
                var inp = String.fromCharCode(event.keyCode);
                if (callback)
                    callback(inp);
                if (_this.name_regex.test(inp))
                    return true;
                event.preventDefault();
                return false;
            },
        };
        this.submitted = false;
    }
    FormRenderComponent.prototype.ngOnInit = function () { };
    FormRenderComponent.prototype.valid_email = function (email) {
        return this.email_regex.test(email);
    };
    FormRenderComponent.prototype.valid = function () {
        var _this = this;
        var valid = true;
        this.config.rows.forEach(function (row) {
            row.cols.forEach(function (col) {
                if (col.type != "divider" &&
                    col.type != "toogle-carousel" &&
                    col.type != "buttons" &&
                    col.type != "tags" &&
                    col.validators) {
                    if (col.validators.custom && !col.validators.custom.value(_this.data[col.name])) {
                        valid = false;
                    }
                    if (col.type == "email") {
                        if (_this.data[col.name] && col.validators.required.value() == true) {
                            if (!_this.email_regex.test(_this.data[col.name])) {
                                valid = false;
                            }
                        }
                        if (_this.data[col.name] && !_this.email_regex.test(_this.data[col.name])) {
                            valid = false;
                        }
                        else if (!_this.data[col.name] && col.validators.required.value() == true) {
                            valid = false;
                        }
                    }
                }
            });
        });
        return this.form_ref ? !this.form_ref.invalid && valid : false;
    };
    FormRenderComponent.prototype.submit = function (callback) {
        this.submitted = true;
        this.form_ref["submitted"] = true;
        callback();
    };
    FormRenderComponent.prototype.submit_event = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.config.submit(callback);
    };
    FormRenderComponent.prototype.unsubmit = function () {
        this.submitted = false;
        this.form_ref["submitted"] = false;
    };
    FormRenderComponent.prototype.checkbox_check = function (list, id) {
        if (!Array.isArray(list)) {
            list = new Array();
        }
        if (list.find(function (value) { return value == id; })) {
            list = list.filter(function (value) { return value != id; });
        }
        else {
            list.push(id);
        }
        return list;
    };
    FormRenderComponent.prototype.checkbox_is_checked = function (list, id) {
        if (!Array.isArray(list)) {
            list = new Array();
        }
        return !!list.find(function (value) { return value == id; });
    };
    FormRenderComponent.prototype.dropped = function (files) {
        return files;
    };
    FormRenderComponent.prototype.fileOver = function (event) { };
    FormRenderComponent.prototype.fileLeave = function (event) { };
    FormRenderComponent.prototype.get_upload_url = function (path, name) {
        return this.endpoint.get_file_endpoint(path, name);
    };
    FormRenderComponent.prototype.delete_upload = function (list, index) {
        if (!Array.isArray(list)) {
            list = new Array();
        }
        if (list[index]) {
            list = list.filter(function (v, i) { return i != index; });
        }
        return list;
    };
    FormRenderComponent.prototype.toogle_dropdown = function (dropdown, input) {
        dropdown.classList.toggle("show");
        input.focus();
    };
    FormRenderComponent.prototype.clean_fields = function () {
        var _this = this;
        this.config.rows.forEach(function (row) {
            row.cols.forEach(function (col) {
                if (col.type != "divider" && col.type != "toogle-carousel" && col.type != "buttons" && col.type != "tags") {
                    _this.data[col.name] = "";
                }
            });
        });
        this.unsubmit();
    };
    FormRenderComponent.prototype.upload_amount = function (list, amount) {
        return list && amount ? list.slice(0, amount) : list;
    };
    FormRenderComponent.prototype.month_year = function (input_ref, event) {
        input_ref.value = this.pipesService.monthYear(event);
        return true;
    };
    FormRenderComponent.prototype.monthSelected = function (month_picker, event, col) {
        var flag = false;
        if (col.validators) {
            if (col.validators.max && event > col.validators.max.value()) {
                flag = true;
            }
            if (col.validators.min && event < col.validators.min.value()) {
                flag = true;
            }
        }
        if (!flag) {
            month_picker.close();
            this.data[col.name] = event;
        }
        else {
            month_picker.close();
        }
    };
    FormRenderComponent.prototype.yearSelected = function (year_picker, event, col) {
        var flag = false;
        if (col.validators) {
            if (col.validators.max && event > col.validators.max.value()) {
                flag = true;
            }
            if (col.validators.min && event < col.validators.min.value()) {
                flag = true;
            }
        }
        if (!flag) {
            year_picker.close();
            this.data[col.name] = event;
        }
        else {
            year_picker.close();
        }
    };
    FormRenderComponent.prototype.just_year = function (input_ref, event) {
        input_ref.value = this.pipesService.year(event);
        return true;
    };
    FormRenderComponent.prototype.parse_month_year = function (str) {
        var _str = this.pipesService.monthYear(str);
        return _str;
    };
    FormRenderComponent.prototype.push = function (list, item) {
        if (item) {
            list.push(item.trim());
        }
    };
    FormRenderComponent.prototype.find = function (list, item) {
        return list.find(function (v) { return v.localeCompare(item, 'es', { sensitivity: 'base' }) === 0; });
    };
    return FormRenderComponent;
}());
export { FormRenderComponent };
