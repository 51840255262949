import * as tslib_1 from "tslib";
import { CustomClass } from "../custom-class";
var GrowthCurveClass = /** @class */ (function (_super) {
    tslib_1.__extends(GrowthCurveClass, _super);
    function GrowthCurveClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    GrowthCurveClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.id_consult = data.id_consult;
        this.weight = data.weight;
        this.id_weight_unit = data.id_weight_unit ? data.id_weight_unit : "4";
        this.height = data.height;
        this.id_height_unit = data.id_height_unit ? data.id_height_unit : "2";
        this.head = data.head;
        this.id_head_unit = data.id_head_unit ? data.id_head_unit : "2";
        this.IMC = (+data.IMC).toFixed(2) + "";
        this.WFH = (+data.WFH).toFixed(2) + "";
        this.ind = data.ind;
        this.date = data.date;
        this.created_at = data.created_at;
        this.created_by = data.created_by;
        this.units = 2;
    };
    GrowthCurveClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_user = null;
        this.id_consult = null;
        this.weight = "";
        this.id_weight_unit = "1";
        this.height = "";
        this.id_height_unit = "3";
        this.head = "";
        this.id_head_unit = "3";
        this.IMC = "";
        this.WFH = "";
        this.ind = "";
        this.date = new Date();
        this.created_at = new Date();
        this.created_by = null;
        this.units = 2;
    };
    GrowthCurveClass.prototype.format = function () {
        this.weight = this.weight.replace(/,/g, "");
        this.height = this.height.replace(/,/g, "");
        this.head = this.head.replace(/,/g, "");
        this.IMC = this.IMC.replace(/,/g, "");
        this.WFH = this.WFH.replace(/,/g, "");
        return this;
    };
    GrowthCurveClass.prototype.valid = function () {
        return true;
    };
    GrowthCurveClass.prototype.to_string = function () {
        var _this = this;
        var data = this;
        if (data.weight)
            data.weight += " " + this.catalogsService.catalogs.units_weight.find(function (v) { return v.id == _this.id_weight_unit; }).code;
        if (data.height)
            data.height += " " + this.catalogsService.catalogs.units_height.find(function (v) { return v.id == _this.id_height_unit; }).code;
        if (data.head)
            data.head += " " + this.catalogsService.catalogs.units_height.find(function (v) { return v.id == _this.id_head_unit; }).code;
        return data;
    };
    GrowthCurveClass.prototype.get_ideal_weight = function (value, id) {
        var element = this.catalogsService.catalogs.units_weight.find(function (v) { return v.id == id; });
        return element ? value * element.kg_conv : null;
    };
    GrowthCurveClass.prototype.get_ideal_height = function (value, id) {
        var element = this.catalogsService.catalogs.units_height.find(function (v) { return v.id == id; });
        return element ? value * element.m_conv : null;
    };
    GrowthCurveClass.prototype.get_ideal_head = function (value, id) {
        var element = this.catalogsService.catalogs.units_height.find(function (v) { return v.id == id; });
        return element ? value * element.m_conv : null;
    };
    GrowthCurveClass.prototype.raw = function () {
        return {
            id: this.id,
            id_user: this.id_user,
            id_consult: this.id_consult,
            weight: this.weight,
            id_weight_unit: this.id_weight_unit,
            height: this.height,
            id_height_unit: this.id_height_unit,
            head: this.head,
            id_head_unit: this.id_head_unit,
            IMC: this.IMC,
            WFH: this.WFH,
            date: this.date,
            ind: this.ind,
            created_at: this.created_at,
            created_by: this.created_by,
        };
    };
    GrowthCurveClass.prototype.calculate_IMC = function () {
        if (this.height && this.weight) {
            var height = this.get_ideal_height(parseFloat(((this.height || 0) + "").replace(/,/g, "")), this.id_height_unit);
            var weight = this.get_ideal_weight(parseFloat(((this.weight || 0) + "").replace(/,/g, "")), this.id_weight_unit);
            this.IMC = (weight / (height * height)).toFixed(2) + "";
        }
        else {
            this.IMC = "";
        }
    };
    return GrowthCurveClass;
}(CustomClass));
export { GrowthCurveClass };
