import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { TutorsService } from '../tutors.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { NewPatientService } from 'src/app/services/new_patient.service';
var FormComponent = /** @class */ (function () {
    function FormComponent(moduleService, expedientService, catalogsService, newPatientService, patientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.catalogsService = catalogsService;
        this.newPatientService = newPatientService;
        this.patientService = patientService;
        this.view = 1;
        this.current_date = new Date();
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            // this.moduleService.reload();
            _this.form_configs();
        });
        this.tutors_subscription = this.moduleService.current.subscribe(function (state) { return _this.tutors_state = state; });
        this.form_configs();
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        if (this.tutors_state) {
            this.moduleService.forms.tutors = this.form_ref;
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.tutors_subscription.unsubscribe();
    };
    FormComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return _this.view === 4; },
            disabled: function () { return _this.view === 4; },
            submit: function (callback) { return _this.moduleService.submit(callback); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "phone",
                            type: "phone",
                            hn: function () { return true; },
                            label: "Ingrese número de teléfono del tutor:",
                            placeholder: function () { return "####-####"; },
                            col_class: "col-md-12",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                            show: function () { return _this.view === 1; },
                        },
                        {
                            name: "firstname",
                            type: "name",
                            label: "Nombres",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Nombre"; },
                            col_class: "col-md-6",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                }
                            },
                            show: function () { return _this.view === 3; },
                            disabled: function () { return true; },
                        },
                        {
                            name: "lastname",
                            type: "name",
                            label: "Apellidos",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Apellido"; },
                            col_class: "col-md-6",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                }
                            },
                            show: function () { return _this.view === 3; },
                            disabled: function () { return true; },
                        },
                        {
                            name: "email",
                            type: "email",
                            label: "Correo Electrónico",
                            placeholder: function () { return "alguien@email.com"; },
                            col_class: "col-md-6",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            show: function () { return _this.view === 3; },
                            disabled: function () { return true; },
                        },
                        {
                            name: "identification",
                            type: "identification",
                            label: "Identificación",
                            hn: function () { return _this.tutors_state.id_country == 97; },
                            placeholder: function () { return "####-####-#####"; },
                            col_class: "col-md-6",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                            },
                            show: function () { return _this.view === 3; },
                            disabled: function () { return true; },
                        },
                        {
                            name: "id_relationship",
                            type: "select",
                            label: "Relación",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.relationships; },
                                text: "name",
                                value: "id"
                            },
                            show: function () { return _this.view === 3; },
                            disabled: function () { return false; },
                        },
                        {
                            name: "firstname",
                            type: "name",
                            label: "Nombres",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Nombre"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                                maxlength: {
                                    value: function () { return 100; },
                                },
                            },
                            disabled: function () { return false; },
                            change: function () {
                                _this.tutors_state.firstname = _this.tutors_state.firstname.trim();
                            },
                            show: function () { return _this.view === 2; },
                            input: function () { return (_this.tutors_state.firstname = _this.tutors_state.firstname.trim()); },
                        },
                        {
                            name: "lastname",
                            type: "name",
                            label: "Apellidos",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Apellido"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                                maxlength: {
                                    value: function () { return 100; },
                                },
                            },
                            disabled: function () { return false; },
                            change: function () {
                                _this.tutors_state.lastname = _this.tutors_state.lastname.trim();
                            },
                            show: function () { return _this.view === 2; },
                            input: function () { return (_this.tutors_state.lastname = _this.tutors_state.lastname.trim()); },
                        },
                        {
                            name: "birthdate",
                            type: "calendar",
                            label: "Fecha de Nacimiento",
                            placeholder: function () { return "##/##/####"; },
                            col_class: "col-md-4",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                                max: {
                                    value: function () { return _this.current_date; },
                                },
                            },
                            show: function () { return _this.view === 2; },
                            disabled: function () { return false; },
                        },
                        {
                            name: "identification",
                            type: "identification",
                            label: "Identificación",
                            hn: function () { return _this.tutors_state.id_country == 97; },
                            placeholder: function () { return "####-####-#####"; },
                            col_class: "col-md-4",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                            },
                            show: function () { return _this.view === 2; },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_country",
                            type: "select",
                            label: "Nacionalidad",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-4",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.countries; },
                                value: "id",
                                text: "name",
                            },
                            show: function () { return _this.view === 2; },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_relationship",
                            type: "select",
                            label: "Relación",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.relationships; },
                                text: "name",
                                value: "id"
                            },
                            show: function () { return _this.view === 2; },
                            disabled: function () { return false; },
                        },
                        {
                            name: "phone",
                            type: "phone",
                            hn: function () { return _this.tutors_state.id_country == "97"; },
                            label: "Teléfono",
                            small: "",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                                maxlength: {
                                    value: function () { return 30; },
                                },
                            },
                            show: function () { return _this.view === 2; },
                            disabled: function () { return false; },
                        },
                        {
                            name: "email",
                            type: "email",
                            label: "Correo Electrónico",
                            small: "",
                            placeholder: function () { return "alguien@email.com"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                            },
                            show: function () { return _this.view === 2; },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_gender",
                            type: "radio",
                            label: "Género",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.genders; },
                                value: "id",
                                text: "name",
                            },
                            disabled: function () { return false; },
                            show: function () { return _this.view === 2; },
                            change: function () {
                                _this.moduleService.save();
                            },
                        },
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12 mt-2",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    click: function () {
                                        _this.moduleService.cancel();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cerrar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "show";
                                    },
                                    click: function () {
                                        _this.moduleService.close();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-warning",
                                    label: "Forzar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    click: function () {
                                        _this.moduleService.submit();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Verificar via whatsapp",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    click: function () {
                                        // this.patientService
                                        var load = {};
                                        if (!_this.expedientService.state.id_user) {
                                            load = {
                                                phone: _this.tutors_state.phone,
                                                tutor: _this.tutors_state.firstname + " " + _this.tutors_state.lastname,
                                                tutor_gender: _this.tutors_state.id_gender,
                                                patient: _this.newPatientService.current_state.general.firstname + " " + _this.newPatientService.current_state.general.lastname,
                                                relationship: _this.catalogsService.catalogs.relationships.find(function (v) { return v.id == _this.tutors_state.id_relationship; }).name,
                                            };
                                        }
                                        else {
                                            load = {
                                                phone: _this.tutors_state.phone,
                                                tutor: _this.tutors_state.firstname + " " + _this.tutors_state.lastname,
                                                tutor_gender: _this.tutors_state.id_gender,
                                                patient: _this.patientService.patient_data.general.firstname + " " + _this.patientService.patient_data.general.lastname,
                                                relationship: _this.catalogsService.catalogs.relationships.find(function (v) { return v.id == _this.tutors_state.id_relationship; }).name,
                                            };
                                        }
                                        _this.moduleService.verify_whatsapp(load);
                                    },
                                    show: function () { return _this.view === 3; },
                                    disabled: function () { return !_this.tutors_state.id_relationship || _this.tutors_state.loading; },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Verificar via whatsapp",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    click: function () {
                                        var load = {};
                                        if (!_this.expedientService.state.id_user) {
                                            load = {
                                                phone: _this.tutors_state.phone,
                                                tutor: _this.tutors_state.firstname + " " + _this.tutors_state.lastname,
                                                tutor_gender: _this.tutors_state.id_gender,
                                                patient: _this.newPatientService.current_state.general.firstname + " " + _this.newPatientService.current_state.general.lastname,
                                                relationship: _this.catalogsService.catalogs.relationships.find(function (v) { return v.id == _this.tutors_state.id_relationship; }).name,
                                            };
                                        }
                                        else {
                                            load = {
                                                phone: _this.tutors_state.phone,
                                                tutor: _this.tutors_state.firstname + " " + _this.tutors_state.lastname,
                                                tutor_gender: _this.tutors_state.id_gender,
                                                patient: _this.patientService.patient_data.general.firstname + " " + _this.patientService.patient_data.general.lastname,
                                                relationship: _this.catalogsService.catalogs.relationships.find(function (v) { return v.id == _this.tutors_state.id_relationship; }).name,
                                            };
                                        }
                                        _this.moduleService.verify_whatsapp(load);
                                    },
                                    show: function () { return _this.view === 2; },
                                    disabled: function () { return !_this.tutors_state.id_relationship || _this.tutors_state.loading; },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Aceptar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    click: function () {
                                        _this.moduleService.validate_tutor_phone(_this.tutors_state.phone, function (data) {
                                            if (data) {
                                                _this.tutors_state.id_user = data.id_user;
                                                _this.tutors_state.firstname = data.firstname;
                                                _this.tutors_state.lastname = data.lastname;
                                                _this.tutors_state.identification = data.identification;
                                                _this.tutors_state.id_gender = data.id_gender;
                                                _this.tutors_state.email = data.email;
                                                _this.view = 3;
                                            }
                                            else {
                                                _this.view = 2;
                                            }
                                        });
                                    },
                                    show: function () { return _this.view === 1; },
                                    disabled: function () { return !_this.tutors_state.phone || _this.tutors_state.loading; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    return FormComponent;
}());
export { FormComponent };
