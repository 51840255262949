import { PipeTransform } from '@angular/core';
var LongDate = /** @class */ (function () {
    function LongDate() {
    }
    LongDate.prototype.transform = function (str) {
        return new Date(str).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
    };
    return LongDate;
}());
export { LongDate };
