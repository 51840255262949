import * as tslib_1 from "tslib";
import { CustomClass } from "../custom-class";
var MembershipsClass = /** @class */ (function (_super) {
    tslib_1.__extends(MembershipsClass, _super);
    function MembershipsClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    MembershipsClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.place = data.place;
        this.name = data.name;
        this.year = data.year;
    };
    MembershipsClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_user = null;
        this.place = "";
        this.name = "";
        this.year = "";
    };
    MembershipsClass.prototype.format = function () {
        return this;
    };
    MembershipsClass.prototype.valid = function () {
        return true;
    };
    MembershipsClass.prototype.to_string = function () {
        var data = {
            place: this.place,
            name: this.name,
            year: this.year,
        };
        return data;
    };
    MembershipsClass.prototype.raw = function () {
        return {
            id: this.id,
            id_user: this.id_user,
            place: this.place,
            name: this.name,
            year: this.year,
        };
    };
    return MembershipsClass;
}(CustomClass));
export { MembershipsClass };
