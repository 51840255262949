import { PageService } from 'src/app/services/page.service';
var ShowBackgroundPathologiesComponent = /** @class */ (function () {
    function ShowBackgroundPathologiesComponent(pageService) {
        this.pageService = pageService;
    }
    ShowBackgroundPathologiesComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowBackgroundPathologiesComponent;
}());
export { ShowBackgroundPathologiesComponent };
