import * as tslib_1 from "tslib";
import { CustomClass } from "../custom-class";
var LocationsClass = /** @class */ (function (_super) {
    tslib_1.__extends(LocationsClass, _super);
    function LocationsClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    LocationsClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.days = data.days;
        this.name = data.name;
        this.hours = data.hours;
        this.att_public = data.att_public;
        this.att_type = data.att_type;
    };
    LocationsClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_user = null;
        this.days = [
            { id: 0, init: null, finish: null },
            { id: 1, init: null, finish: null },
            { id: 2, init: null, finish: null },
            { id: 3, init: null, finish: null },
            { id: 4, init: null, finish: null },
            { id: 5, init: null, finish: null },
            { id: 6, init: null, finish: null },
        ];
        this.name = "";
        this.hours = "";
        this.att_public = 1;
        this.att_type = 1;
    };
    LocationsClass.prototype.format = function () {
        return this;
    };
    LocationsClass.prototype.valid = function () {
        return true;
    };
    LocationsClass.prototype.to_string = function () {
        var data = {
            days: this.days,
            name: this.name,
            hours: this.hours,
            att_public: this.att_public,
            att_type: this.att_type,
        };
        return data;
    };
    LocationsClass.prototype.raw = function () {
        return {
            id: this.id,
            id_user: this.id_user,
            days: this.days,
            name: this.name,
            hours: this.hours,
            att_public: this.att_public,
            att_type: this.att_type,
        };
    };
    return LocationsClass;
}(CustomClass));
export { LocationsClass };
