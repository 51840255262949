;
var CustomClass = /** @class */ (function () {
    function CustomClass() {
    }
    CustomClass.prototype.raw = function () {
    };
    CustomClass.prototype.to_string = function () {
    };
    CustomClass.prototype.overwrite = function (data) {
        this.loaded = true;
        this.loading = false;
        this.submitted = false;
    };
    CustomClass.prototype.reset = function () {
        this.loaded = false;
        this.loading = true;
        this.submitted = false;
    };
    return CustomClass;
}());
export { CustomClass };
