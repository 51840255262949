import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./pathologies/pathologies.module.ngfactory").then(function (m) { return m.BackgroundPathologiesModuleNgFactory; }); }, ɵ1 = function () { return import("./allergies/allergies.module.ngfactory").then(function (m) { return m.BackgroundAllergiesModuleNgFactory; }); };
var routes = [
    {
        path: '',
        redirectTo: 'patologicos',
        pathMatch: 'prefix'
    },
    {
        path: 'patologicos',
        loadChildren: ɵ0
    },
    {
        path: 'alergicos',
        loadChildren: ɵ1
    },
];
var BackgroundModule = /** @class */ (function () {
    function BackgroundModule() {
    }
    return BackgroundModule;
}());
export { BackgroundModule };
export { ɵ0, ɵ1 };
