import { TutorsService } from '../tutors.service';
var UpdateRelationsTutorsComponent = /** @class */ (function () {
    function UpdateRelationsTutorsComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateRelationsTutorsComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateRelationsTutorsComponent;
}());
export { UpdateRelationsTutorsComponent };
