import { Routes } from '@angular/router';
import { RelationsDependantsComponent } from './dependants.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: 'dependientes',
        component: RelationsDependantsComponent
    }
];
var RelationsDependantsModule = /** @class */ (function () {
    function RelationsDependantsModule() {
    }
    return RelationsDependantsModule;
}());
export { RelationsDependantsModule };
