import * as tslib_1 from "tslib";
import { CatalogsService } from "src/app/services/catalogs.service";
import { OnInit, OnDestroy } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { PageService } from "src/app/services/page.service";
import { PatientService } from "src/app/services/patient-service.service";
import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { ConsultGeneralService } from "src/app/pages/expedient/new_consult/general/consult.service";
import { AccountService } from "src/app/services/account.service";
import { SocketService } from "src/app/services/socket.service";
import { AlertService } from "src/app/shared/ui/alert_service/alert.service";
var SearchBarComponent = /** @class */ (function () {
    function SearchBarComponent(endpoint, patientService, accountService, pageService, consultService, _location, toastr, alertService, socketService, catalogsService) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.accountService = accountService;
        this.pageService = pageService;
        this.consultService = consultService;
        this._location = _location;
        this.toastr = toastr;
        this.alertService = alertService;
        this.socketService = socketService;
        this.catalogsService = catalogsService;
        this.orders = [
            {
                id: true,
                text: "Ascendente",
            },
            {
                id: false,
                text: "Descendente",
            },
        ];
        this.filters = {
            limit: 10,
            skip: 0,
            search_word: "",
            order_asc: true,
        };
        this.avatar_configs = { size: "2.5rem" };
        this.loading = false;
        this.data = [];
    }
    SearchBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.init_socket();
        this.page_subscription = this.pageService.current.subscribe(function (state) {
            _this.page_state = state;
        });
        this.get_patients();
    };
    SearchBarComponent.prototype.init_socket = function () {
        var _this = this;
        this.socketService.getDoctorPatientPhoneVerify().subscribe(function (data) {
            if (_this.phoneMessageSid === data.sid) {
                if (data.message === "Sí, dar acceso") {
                    _this.insert_relation(_this.selected_patient, function () {
                        _this.alertService.alert_success("¡Listo!", "El paciente ha aceptado la solicitud de acceso a su expediente medico.");
                        _this.selected_patient.id_doctors_patients_level = 1;
                        _this.goto(_this.selected_patient);
                    });
                }
                else if (data.message === "No, denegar acceso") {
                    _this.alertService.alert_error("¡Oh no!", "El paciente ha rechazado la solicitud de acceso a su expediente medico.");
                }
            }
        }, function (error) {
            console.error(error);
        });
    };
    SearchBarComponent.prototype.ngOnDestroy = function () {
        this.page_subscription.unsubscribe();
    };
    SearchBarComponent.prototype.backClicked = function () {
        this._location.back();
    };
    SearchBarComponent.prototype.get_patients = function (loading) {
        var _this = this;
        if (loading === void 0) { loading = true; }
        this.loading = loading;
        var load = tslib_1.__assign({}, this.filters, { order_by: "firstname", limit: 10 });
        var response;
        this.endpoint.get_patients(load).subscribe(function (data) { return (response = data); }, function (error) {
            console.error(error);
            _this.loading = false;
        }, function () {
            response.list.forEach(function (user) {
                var town = _this.catalogsService.catalogs.towns.find(function (v) { return v.id == user.id_town; });
                user.town = town ? town.name : "";
            });
            _this.data = response.list;
            _this.loading = false;
        });
    };
    SearchBarComponent.prototype.goto = function (item) {
        if (item.id_doctors_patients_level) {
            this.patientService.change_patient_id(item.id_user);
            this.pageService.set_searching(false);
            this.filters.search_word = "";
            this.data = [];
            this.endpoint.redirect_to("/expediente/consultas");
        }
    };
    SearchBarComponent.prototype.new_consult = function (item) {
        if (item.id_doctors_patients_level) {
            this.patientService.change_patient_id(item.id_user);
            this.pageService.set_searching(false);
            this.filters.search_word = "";
            this.data = [];
            this.endpoint.redirect_to("/expediente/nueva-consulta/general/general");
        }
    };
    SearchBarComponent.prototype.close = function () {
        this.filters.search_word = "";
        this.data = [];
        this.pageService.set_searching(false);
    };
    SearchBarComponent.prototype.change = function () {
        var _this = this;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            _this.get_patients();
        }, 1000);
    };
    SearchBarComponent.prototype.insert_relation = function (patient, callback) {
        var _this = this;
        var load = {
            id_user: patient.id_user,
        };
        this.endpoint.expedient_doctors_patients_requests_insert_doctors_patients(load).subscribe(function (data) {
            callback(data);
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
        });
    };
    SearchBarComponent.prototype.pre_add_patient = function (patient) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tutors_1, tutor_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(patient.age < 18)) return [3 /*break*/, 2];
                        tutors_1 = {};
                        patient.tutors.forEach(function (v) {
                            tutors_1[v.id_user] = v.firstname + " " + v.lastname;
                        });
                        return [4 /*yield*/, Swal.fire({
                                title: 'Añadir paciente',
                                html: "El paciente es menor de edad, se debe contar con el permiso de un tutor.",
                                input: 'select',
                                inputOptions: tutors_1,
                                inputPlaceholder: '- Seleccione un tutor -',
                                showCancelButton: true,
                                confirmButtonText: "Solicitar permiso",
                                inputValidator: function (value) {
                                    return new Promise(function (resolve) {
                                        if (!!value) {
                                            return resolve(null);
                                        }
                                        else {
                                            resolve('Es necesario que seleccione un tutor');
                                        }
                                    });
                                }
                            })];
                    case 1:
                        tutor_1 = (_a.sent()).value;
                        if (tutor_1) {
                            this.verify_phone(patient, patient.tutors.findIndex(function (v) { return v.id_user == tutor_1; }));
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.add_patient(patient);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SearchBarComponent.prototype.add_patient = function (patient, tutor_index) {
        var _this = this;
        if (tutor_index === void 0) { tutor_index = null; }
        Swal.fire({
            title: "Añadir paciente",
            html: "Enviar mensaje vía whatsapp para verificar paciente",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, enviar",
            cancelButtonText: "No",
        }).then(function (result) {
            if (result.value == true) {
                _this.verify_phone(patient);
            }
        });
    };
    SearchBarComponent.prototype.verify_phone = function (patient, tutor_index) {
        var _this = this;
        if (tutor_index === void 0) { tutor_index = null; }
        var phone = tutor_index != null ? patient.tutors[tutor_index].phone : patient.phone;
        Swal.fire({
            title: "Añadir paciente",
            html: "La solicitud de acceso al expediente m\u00E9dico, fue enviada por Whatsapp al <strong>" + (phone ? ("*".repeat((phone + "").length - 4) + (phone + "").substring((phone + "").length - 4)) : "********") + "</strong>",
            showCloseButton: true,
            onBeforeOpen: function () {
                Swal.showLoading();
                var load = {
                    phone: phone,
                    id_user: patient.id_user,
                    patient: patient.firstname + " " + patient.lastname,
                    doctor: (_this.accountService.current_state.id_gender == 1 ? "Dra. " : "Dr. ") + _this.accountService.current_state.firstname + " " + _this.accountService.current_state.lastname,
                };
                _this.endpoint.whatsapp_send_send_doctor_patient_request(load).subscribe(function (data) {
                    _this.selected_patient = patient;
                    _this.phoneMessageSid = data;
                }, function (error) {
                    console.error(error);
                    _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
                });
            },
            allowOutsideClick: function () { return !Swal.isLoading(); },
        });
        return;
    };
    SearchBarComponent.prototype.censored_id = function (id, access) {
        if (access) {
            return id;
        }
        else {
            var r_arg = (id + "").length - 5;
            return "*".repeat(r_arg > 0 ? r_arg : 0) + (id + "").substring((id + "").length - 5);
        }
    };
    return SearchBarComponent;
}());
export { SearchBarComponent };
