import { AppService } from "src/app/services/app.service";
var AvatarComponent = /** @class */ (function () {
    function AvatarComponent(endpoint) {
        this.endpoint = endpoint;
    }
    Object.defineProperty(AvatarComponent.prototype, "_name", {
        set: function (name) {
            if (name) {
                this.name = name;
                this.letter = name.charAt(0).toLowerCase();
            }
            else {
                this.name = name;
                this.letter = "n";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "_image", {
        set: function (image) {
            if (image) {
                if (image.includes("data:image")) {
                    this.image = image;
                }
                else {
                    this.image = this.endpoint.get_file_endpoint("profile_images", image);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    AvatarComponent.prototype.error_image = function () {
        this.image = null;
    };
    return AvatarComponent;
}());
export { AvatarComponent };
