import * as tslib_1 from "tslib";
import { ConnectionsClass } from "./connections-class";
import { PeopleMayKnowClass, } from "./people_may_know-class";
import { ExperiencesClass } from "./experiences-class";
import { EducationsClass } from "./educations-class";
import { InsurancesClass } from "./insurances-class";
import { MembershipsClass } from "./memberships-class";
import { CustomListClass } from "../custom-list-class";
import { LocationsClass } from "./locations-class";
import { CustomClass } from "../custom-class";
var ProfileClass = /** @class */ (function (_super) {
    tslib_1.__extends(ProfileClass, _super);
    function ProfileClass(catalogsService, pipesService, profile) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        _this.pipesService = pipesService;
        if (profile)
            _this.overwrite(profile);
        else
            _this.reset();
        return _this;
    }
    ProfileClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_user = null;
        this.firstname = "";
        this.lastname = "";
        this.username = "";
        this.id_gender = null;
        this.birthdate = new Date();
        this.birthplace = "";
        this.id_country = null;
        this.id_department = null;
        this.id_town = null;
        this.address = "";
        this.phone = "";
        this.v_phone = "";
        this.verified_phone = 2;
        this.email = "";
        this.v_email = "";
        this.verified_email = 2;
        this.id_education = null;
        this.identification = "";
        this.image = "";
        this.id_blood = null;
        this.organ_donor = null;
        this.glasses = null;
        this.connnections = null;
        this.thanks = null;
        this.patients = null;
        this.my_focus = "";
        this.specialties_areas = [];
        this.id_doctors_specialty = null;
        this.registration_number = "";
        this.job_place = "";
        this.disabled_at = new Date();
        this.disabled_by = null;
        this.deleted_at = new Date();
        this.deleted_by = null;
        this.online_at = new Date();
        this.offline_at = new Date();
        this.common = 0;
        this.is_account = false;
        this.experiences = new CustomListClass();
        this.educations = new CustomListClass();
        this.insurances = new CustomListClass();
        this.memberships = new CustomListClass();
        this.people_may_know = new CustomListClass();
        this.locations = new CustomListClass();
        this.connection = new ConnectionsClass(this.catalogsService);
        this.connections = new CustomListClass();
        this.search_connections = new CustomListClass();
    };
    ProfileClass.prototype.overwrite = function (profile) {
        var _this = this;
        _super.prototype.overwrite.call(this, profile);
        this.id = profile.id;
        this.id_user = profile.id_user;
        this.firstname = profile.firstname;
        this.lastname = profile.lastname;
        this.username = profile.username;
        this.id_gender = profile.id_gender;
        this.birthdate = profile.birthdate;
        this.birthplace = profile.birthplace;
        this.id_country = profile.id_country;
        this.id_department = profile.id_department;
        this.id_town = profile.id_town;
        this.address = profile.address;
        this.phone = profile.phone;
        this.v_phone = profile.phone;
        this.verified_phone = profile.verified_phone;
        this.email = profile.email;
        this.v_email = profile.email;
        this.verified_email = profile.verified_email;
        this.id_education = profile.id_education;
        this.identification = profile.identification;
        this.image = profile.image;
        this.id_blood = profile.id_blood;
        this.organ_donor = profile.organ_donor;
        this.glasses = profile.glasses;
        this.connnections = profile.connnections;
        this.thanks = profile.thanks;
        this.patients = profile.patients;
        this.my_focus = profile.my_focus;
        this.specialties_areas = profile.specialties_areas;
        this.id_doctors_specialty = profile.id_doctors_specialty;
        this.registration_number = profile.registration_number;
        this.job_place = profile.job_place;
        this.disabled_at = profile.disabled_at;
        this.disabled_by = profile.disabled_by;
        this.deleted_at = profile.deleted_at;
        this.deleted_by = profile.deleted_by;
        this.online_at = profile.online_at;
        this.offline_at = profile.offline_at;
        this.common = profile.common;
        this.is_account = profile.is_account;
        if (profile.experiences)
            this.experiences = new CustomListClass(profile.experiences.map(function (experiences) {
                return new ExperiencesClass(_this.catalogsService, experiences);
            }));
        if (profile.educations)
            this.educations = new CustomListClass(profile.educations.map(function (educations) {
                return new EducationsClass(_this.catalogsService, educations);
            }));
        if (profile.insurances)
            this.insurances = new CustomListClass(profile.insurances.map(function (insurances) {
                return new InsurancesClass(_this.catalogsService, insurances);
            }));
        if (profile.memberships)
            this.memberships = new CustomListClass(profile.memberships.map(function (memberships) {
                return new MembershipsClass(_this.catalogsService, memberships);
            }));
        if (profile.people_may_know)
            this.people_may_know = new CustomListClass(profile.people_may_know.map(function (people_may_know) {
                return new PeopleMayKnowClass(_this.catalogsService, people_may_know);
            }));
        if (profile.locations)
            this.locations = new CustomListClass(profile.locations.map(function (locations) {
                return new LocationsClass(_this.catalogsService, locations);
            }));
        if (profile.connections)
            this.connections = new CustomListClass(profile.connections.map(function (connections) {
                return new ConnectionsClass(_this.catalogsService, connections);
            }));
        if (profile.search_connections)
            this.search_connections = new CustomListClass(profile.search_connections.map(function (search_connections) {
                return new ConnectionsClass(_this.catalogsService, search_connections);
            }));
        if (profile.connection)
            this.connection = new ConnectionsClass(this.catalogsService, profile.connection);
    };
    ProfileClass.prototype.raw = function () {
        var data = {};
        data.id = this.id;
        data.id_user = this.id_user;
        data.firstname = this.firstname;
        data.lastname = this.lastname;
        data.username = this.username;
        data.id_gender = this.id_gender;
        data.birthdate = this.birthdate;
        data.birthplace = this.birthplace;
        data.id_country = this.id_country;
        data.id_department = this.id_department;
        data.id_town = this.id_town;
        data.address = this.address;
        data.phone = this.phone;
        data.v_phone = this.v_phone;
        data.verified_phone = this.verified_phone;
        data.email = this.email;
        data.v_email = this.v_email;
        data.verified_email = this.verified_email;
        data.id_education = this.id_education;
        data.identification = this.identification;
        data.image = this.image;
        data.id_blood = this.id_blood;
        data.organ_donor = this.organ_donor;
        data.glasses = this.glasses;
        data.connections = this.connnections;
        data.thanks = this.thanks;
        data.patients = this.patients;
        data.my_focus = this.my_focus;
        data.specialties_areas = this.specialties_areas;
        data.id_doctors_specialty = this.id_doctors_specialty;
        data.registration_number = this.registration_number;
        data.job_place = this.job_place;
        data.disabled_at = this.disabled_at;
        data.disabled_by = this.disabled_by;
        data.deleted_at = this.deleted_at;
        data.deleted_by = this.deleted_by;
        data.online_at = this.online_at;
        data.offline_at = this.offline_at;
        data.common = this.common;
        data.is_account = this.is_account;
        data.experiences = this.experiences.raw();
        data.educations = this.educations.raw();
        data.insurances = this.insurances.raw();
        data.memberships = this.memberships.raw();
        data.people_may_know = this.people_may_know.raw();
        data.locations = this.locations.raw();
        data.connection = this.connection.raw();
        data.connections = this.connections.raw();
        data.search_connections = this.search_connections.raw();
        return data;
    };
    ProfileClass.prototype.to_string = function () {
        var _this = this;
        var department = this.catalogsService.catalogs.departments.find(function (v) { return v.id == _this.id_department; });
        var town = this.catalogsService.catalogs.towns.find(function (v) { return v.id == _this.id_town; });
        var country = this.catalogsService.catalogs.countries.find(function (v) { return v.id == _this.id_country; });
        var doctors_specialty = this.catalogsService.catalogs.doctors_specialties.find(function (v) { return v.id == _this.id_doctors_specialty; });
        var specialties_areas = [];
        if (this.specialties_areas) {
            specialties_areas = this.specialties_areas
                .map(function (v) {
                var item = _this.catalogsService.catalogs.specialties_areas.find(function (c) { return c.id == v; });
                return item ? item : null;
            })
                .filter(function (v) { return v; });
        }
        this.static_to_string = {
            id: this.id,
            id_user: this.id_user,
            firstname: this.firstname,
            lastname: this.lastname,
            username: this.username,
            id_gender: this.id_gender,
            birthdate: this.birthdate,
            birthplace: this.birthplace,
            id_country: this.id_country,
            country: country ? country.name : "",
            id_department: this.id_department,
            department: department ? department.name : "",
            id_town: this.id_town,
            town: town ? town.name : "",
            address: this.address,
            phone: this.phone,
            verified_phone: this.verified_phone,
            v_phone: this.v_phone,
            email: this.email,
            verified_email: this.verified_email,
            v_email: this.v_email,
            id_education: this.id_education,
            identification: this.identification,
            image: this.image,
            id_blood: this.id_blood,
            organ_donor: this.organ_donor,
            glasses: this.glasses,
            my_focus: this.my_focus,
            specialties_areas: specialties_areas,
            connnections: this.connnections
                ? this.pipesService.number(parseFloat((this.connnections + "").replace(/,/g, "")), 0, 0)
                : "0",
            thanks: this.thanks
                ? this.pipesService.number(parseFloat((this.thanks + "").replace(/,/g, "")), 0, 0)
                : "0",
            patients: this.patients
                ? this.pipesService.number(parseFloat((this.patients + "").replace(/,/g, "")), 0, 0)
                : "0",
            id_doctors_specialty: this.id_doctors_specialty,
            doctors_specialty: doctors_specialty ? doctors_specialty.name : "",
            registration_number: this.registration_number,
            job_place: this.job_place,
            disabled_at: this.disabled_at,
            disabled_by: this.disabled_by,
            deleted_at: this.deleted_at,
            deleted_by: this.deleted_by,
            online_at: this.online_at,
            offline_at: this.offline_at,
            common: this.common,
            is_account: this.is_account,
        };
        this.experiences.to_string();
        this.educations.to_string();
        this.insurances.to_string();
        this.memberships.to_string();
        this.people_may_know.to_string();
        this.locations.to_string();
        this.connection.to_string();
        this.connections.to_string();
        this.search_connections.to_string();
        return this.static_to_string;
    };
    ProfileClass.prototype.clone = function () {
        return new ProfileClass(this.catalogsService, this.pipesService, this.raw());
    };
    return ProfileClass;
}(CustomClass));
export { ProfileClass };
