import { AccountService } from "src/app/services/account.service";
import { AppService } from "src/app/services/app.service";
import { PageService } from "../../services/page.service";
import * as i0 from "@angular/core";
import * as i1 from "../../services/page.service";
import * as i2 from "../../services/app.service";
import * as i3 from "../../services/account.service";
var OutConsultGuard = /** @class */ (function () {
    function OutConsultGuard(pageService, endpoint, accountService) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.accountService = accountService;
    }
    OutConsultGuard.prototype.canDeactivate = function (a, b, c, d) {
        var _this = this;
        var inConsult = localStorage.getItem("inConsult");
        if (inConsult) {
            this.pageService.cancel_consult(function () {
                _this.endpoint.redirect_to(d.url);
            });
            return false;
        }
        else {
            this.pageService.sidebar_3(function (data) {
                data.shadow = false;
            });
        }
        return true;
    };
    OutConsultGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OutConsultGuard_Factory() { return new OutConsultGuard(i0.ɵɵinject(i1.PageService), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.AccountService)); }, token: OutConsultGuard, providedIn: "root" });
    return OutConsultGuard;
}());
export { OutConsultGuard };
