import { ExpedientService } from 'src/app/services/expedient.service';
import { AccidentService } from '../accident.service';
var CreateInsurancesAccidentComponent = /** @class */ (function () {
    function CreateInsurancesAccidentComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateInsurancesAccidentComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateInsurancesAccidentComponent;
}());
export { CreateInsurancesAccidentComponent };
