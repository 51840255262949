import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
var SheduleDateComponent = /** @class */ (function () {
    function SheduleDateComponent(endpoint, toastr, pageService, patientServiceService, accountService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.patientServiceService = patientServiceService;
        this.accountService = accountService;
        this.data = {
            id_patient: "",
            id_doctor: "",
            description: "",
            fecha_date: "",
            hour_date: "",
            duration: ""
        };
        this.readonly = false;
        this.loading = true;
        this.pathology_timeout = null;
        this.search_word = "";
    }
    SheduleDateComponent.prototype.ngOnInit = function () {
        this.doctor_data = this.accountService.get_current_state();
        this.form_configs();
        this.get_patients();
        this.form_configs2();
    };
    SheduleDateComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            submit: function () { return _this.submit(); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "id_patient",
                            type: "select",
                            label: "Paciente",
                            placeholder: function () { return ""; },
                            col_class: "col-md-12",
                            catalog: {
                                list: function () { return _this.patients; },
                                value: "id_user",
                                text: "firstname"
                            },
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "description",
                            type: "textarea",
                            label: "Descripción",
                            placeholder: function () { return ""; },
                            col_class: "col-md-12",
                            rows: 2.5,
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "fecha_date",
                            type: "calendar",
                            label: "Fecha",
                            placeholder: function () { return "#######"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "hour_date",
                            type: "text",
                            label: "Hora",
                            placeholder: function () { return ""; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "duration",
                            type: "text",
                            label: "duración",
                            placeholder: function () { return ""; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                    ],
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'right',
                                    },
                                    disabled: function () { return false; },
                                    click: function () { return _this.close(); }
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Agregar Cita",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    disabled: function () { return _this.form_ref ? !_this.form_ref.valid() : false; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    SheduleDateComponent.prototype.submit = function () {
        var _this = this;
        this.data.id_doctor = this.doctor_data.id;
        this.endpoint.insert_dates(this.data).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.close();
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    SheduleDateComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    SheduleDateComponent.prototype.form_configs2 = function () {
        var _this = this;
        this.form_config2 = {
            pretty_view: function () { return _this.readonly; },
            disabled: function () { return _this.readonly; },
            submit: function () { return function () { }; },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "id",
                            type: "select-search",
                            label: "Paciente",
                            small: "",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.patients; },
                                value: "title",
                                text: "title"
                            },
                            loading: function () {
                                return _this.loading;
                            },
                            disabled: function () { return false; },
                            change: function (event) {
                                _this.search_word = event.target.value;
                                // clearTimeout(this.pathology_timeout);
                                // this.get_icd_pathologies();
                            },
                            input: function (event) {
                                _this.search_word = event.target.value;
                                clearTimeout(_this.pathology_timeout);
                                _this.pathology_timeout = setTimeout(function () {
                                    _this.get_patients();
                                }, 1000);
                            },
                            select: function (item) {
                                _this.patients.push(item);
                                _this.loading = true;
                            }
                        },
                    ],
                },
            ]
        };
    };
    SheduleDateComponent.prototype.get_patients = function () {
        var _this = this;
        this.endpoint.get_my_patients("").subscribe(function (data) { return _this.patients = data.list; }, function (err) { return console.error(err); });
    };
    return SheduleDateComponent;
}());
export { SheduleDateComponent };
