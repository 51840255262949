import { OnDestroy, OnInit } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
var ContactsComponent = /** @class */ (function () {
    function ContactsComponent(expedientService) {
        this.expedientService = expedientService;
        this.view = 0;
    }
    ContactsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) { return _this.expedient_state = state; });
    };
    ContactsComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
    };
    return ContactsComponent;
}());
export { ContactsComponent };
