import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from 'src/app/services/patient-service.service';
import { AppService } from 'src/app/services/app.service';
import { AccountService } from 'src/app/services/account.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, endpoint, modalInj, toastr, accountService, patientService) {
        this.formBuilder = formBuilder;
        this.endpoint = endpoint;
        this.modalInj = modalInj;
        this.toastr = toastr;
        this.accountService = accountService;
        this.patientService = patientService;
        this.release_year = 2020;
        this.year = new Date().getFullYear();
        this.endpoint.pageTitle = 'Iniciar Sesión';
        this.submitted = false;
        this.error = null;
        this.loading = false;
        this.overwrites = false;
        this.patientService.clean_patient();
        this.endpoint.set_patient_id("");
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            email: [
                '',
                [
                    Validators.required,
                ]
            ],
            password: [
                '',
                [
                    Validators.required,
                ]
            ],
        });
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.load = {
            username: this.f.email.value,
            password: this.f.password.value,
            overwrites: this.overwrites
        };
        var response;
        this.endpoint.login(this.load)
            .subscribe(function (data) { return response = data; }, function (err) {
            _this.toastr.error(err.error.message ? err.error.message : err.message, err.error.title ? err.error.title : err.status + '');
            //this.error = err.error.title;
            _this.loading = false;
        }, function () {
            _this.endpoint.set_session({ token: response.token, first_login: response.first_login });
            // Steps 2
            if (response.code == 'steps-2') {
                Swal.fire({
                    title: response.title,
                    html: response.message,
                    type: 'info',
                    confirmButtonText: 'Listo',
                    confirmButtonColor: '#66c8c8',
                }).then(function (result) {
                });
                _this.endpoint.redirect_to('/dos_pasos');
            }
            else if (response.first_login) {
                _this.accountService.reset();
                _this.endpoint.redirect_to('/primer_inicio');
            }
            else {
                _this.endpoint.redirect_to('');
            }
        });
    };
    ;
    return LoginComponent;
}());
export { LoginComponent };
