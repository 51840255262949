import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../services/app.service';
import { PageService } from '../services/page.service';
import { SocketService } from '../services/socket.service';
// import { SocketService } from '../socket.service';
var LayoutComponent = /** @class */ (function () {
    function LayoutComponent(endpoint, activeRoute, pageService, socketService) {
        this.endpoint = endpoint;
        this.activeRoute = activeRoute;
        this.pageService = pageService;
        this.socketService = socketService;
    }
    LayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.socketService.init();
        this.page_subscription = this.pageService.current.subscribe(function (state) {
            _this.page_state = state;
            if (!_this.page_state.sidebar_1.expand && (!document.body.classList.contains("sidebar-enable") || !document.body.classList.contains("enlarged"))) {
                document.body.classList.add("sidebar-enable");
                document.body.classList.add("enlarged");
            }
            else if (_this.page_state.sidebar_1.expand && (document.body.classList.contains("sidebar-enable") || document.body.classList.contains("enlarged"))) {
                document.body.classList.remove("sidebar-enable");
                document.body.classList.remove("enlarged");
            }
        });
    };
    LayoutComponent.prototype.ngOnDestroy = function () {
        this.page_subscription.unsubscribe();
    };
    LayoutComponent.prototype.url_has = function (word) {
        return this.activeRoute.snapshot["_urlSegment"].segments.map(function (value) { return value.path; }).find(function (value) { return value == word; });
    };
    LayoutComponent.prototype.show_alert = function () {
        return this.socketService.showAlert;
    };
    return LayoutComponent;
}());
export { LayoutComponent };
