import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./laboratory/laboratory.module.ngfactory").then(function (m) { return m.ExamsLaboratoryModuleNgFactory; }); };
var routes = [
    {
        path: '',
        redirectTo: 'laboratorios',
        pathMatch: 'prefix'
    },
    {
        path: 'laboratorios',
        loadChildren: ɵ0
    }
];
var ExamsModule = /** @class */ (function () {
    function ExamsModule() {
    }
    return ExamsModule;
}());
export { ExamsModule };
export { ɵ0 };
