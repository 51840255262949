import { FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var Steps2Component = /** @class */ (function () {
    function Steps2Component(formBuilder, endpoint, modalInj) {
        this.formBuilder = formBuilder;
        this.endpoint = endpoint;
        this.modalInj = modalInj;
        this.release_year = 2020;
        this.year = new Date().getFullYear();
        this.code_mask = [/[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/];
        this.endpoint.pageTitle = 'Verificación de Dos Pasos';
        this.submitted = false;
        this.error = null;
        this.loading = false;
    }
    Steps2Component.prototype.ngOnInit = function () {
        this.steps_2Form = this.formBuilder.group({
            code: [
                '',
                [
                    Validators.required
                ]
            ],
        });
    };
    Object.defineProperty(Steps2Component.prototype, "f", {
        get: function () { return this.steps_2Form.controls; },
        enumerable: true,
        configurable: true
    });
    Steps2Component.prototype.steps_2 = function () {
        var _this = this;
        this.submitted = true;
        if (this.steps_2Form.invalid) {
            return;
        }
        var params = {
            code: this.f.code.value
        };
        this.loading = true;
        var response;
        this.endpoint.steps_2(params)
            .subscribe(function (data) { return response = data; }, function (err) {
            if (err.error.code == "steps-2") {
                _this.endpoint.redirect_to('/');
            }
            Swal.fire({
                title: err.error.title,
                text: err.error.message,
                type: 'error',
                timer: 10000,
                confirmButtonText: 'Listo',
                confirmButtonColor: '#66c8c8',
            }).then(function (result) {
            });
            _this.error = err.error.error;
            _this.loading = false;
        }, function () {
            _this.endpoint.set_session({ token: response.token });
            if (response.first_login == true) {
                _this.endpoint.redirect_to('/primer_inicio');
            }
            else {
                _this.endpoint.redirect_to('');
            }
        });
    };
    ;
    Steps2Component.prototype.send_steps_2 = function () {
        var _this = this;
        this.loading = true;
        var response;
        this.endpoint.send_steps_2()
            .subscribe(function (data) { return response = data; }, function (err) {
            if (err.error.code == "steps-2") {
                _this.endpoint.redirect_to('/');
            }
            Swal.fire({
                title: err.error.title,
                text: err.error.message,
                type: 'error',
                timer: 10000,
                confirmButtonText: 'Listo',
                confirmButtonColor: '#66c8c8',
            }).then(function (result) {
            });
            _this.error = err.error.error;
            _this.loading = false;
        }, function () {
            Swal.fire({
                title: response.title,
                html: response.message,
                type: 'info',
                confirmButtonText: 'Listo',
                confirmButtonColor: '#66c8c8',
            }).then(function (result) {
            });
            _this.submitted = false;
            _this.loading = false;
        });
    };
    return Steps2Component;
}());
export { Steps2Component };
