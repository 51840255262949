import { PathologiesService } from '../pathologies.service';
var UpdateBackgroundPathologiesComponent = /** @class */ (function () {
    function UpdateBackgroundPathologiesComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateBackgroundPathologiesComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateBackgroundPathologiesComponent;
}());
export { UpdateBackgroundPathologiesComponent };
