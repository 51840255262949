import { Routes } from "@angular/router";
import { ConsultsComponent } from "../expedient/consults/consults.component";
import { DateComponent } from "../expedient/date/date.component";
import { MedicinesComponent } from "../expedient/medicines/medicines.component";
import { ExpedientComponent } from "../expedient/expedient/expedient.component";
import { NewPatientGuard } from "src/app/guards/new_patient/new-patient.guard";
import { OutNewPatientGuard } from "src/app/guards/new_patient/out-new-patient.guard";
import { PatientInternalGuard } from "src/app/guards/expedient/patient-internal.guard";
import { NewPatientComponent } from "../expedient/new_patient/new_patient.component";
import { ChatComponent } from "../chat/chat.component";
import { InConsultGuard } from "src/app/guards/consult/inconsult.guard";
import { OutConsultGuard } from "src/app/guards/consult/outconsult.guard";
import { PreviewConsultComponent } from "../expedient/consults/pages/preview_consult/preview_consult.component";
import { PreviewConsultPatientComponent } from "../expedient/consults/pages/preview_consult_patient/preview_consult_patient.component";
import { PreviewPrescriptionComponent } from "../expedient/consults/pages/preview_prescription/preview_prescription.component";
var ɵ0 = function () { return import("./../expedient/new_consult/new_consult.module.ngfactory").then(function (m) { return m.NewConsultModuleNgFactory; }); }, ɵ1 = function () { return import("./../expedient/general/general.module.ngfactory").then(function (m) { return m.ExpedientGeneralModuleNgFactory; }); }, ɵ2 = function () { return import("./../expedient/background/background.module.ngfactory").then(function (m) { return m.BackgroundModuleNgFactory; }); }, ɵ3 = function () { return import("./../expedient/contacts/contacts.module.ngfactory").then(function (m) { return m.ContactsModuleNgFactory; }); }, ɵ4 = function () { return import("./../expedient/insurances/insurances.module.ngfactory").then(function (m) { return m.InsurancesModuleNgFactory; }); }, ɵ5 = function () { return import("./../expedient/vaccines/vaccines.module.ngfactory").then(function (m) { return m.VaccinesModuleNgFactory; }); }, ɵ6 = function () { return import("./../expedient/growth_curve/growth_curve.module.ngfactory").then(function (m) { return m.GrowthCurveModuleNgFactory; }); }, ɵ7 = function () { return import("./../expedient/relations/relations.module.ngfactory").then(function (m) { return m.RelationsModuleNgFactory; }); }, ɵ8 = function () { return import("./../expedient/institutions/institutions.module.ngfactory").then(function (m) { return m.InstitutionsModuleNgFactory; }); }, ɵ9 = function () { return import("./../expedient/procedures/procedures.module.ngfactory").then(function (m) { return m.ProceduresModuleNgFactory; }); }, ɵ10 = function () { return import("./../expedient/references/references.module.ngfactory").then(function (m) { return m.ReferencesModuleNgFactory; }); }, ɵ11 = function () { return import("./../expedient/exams/exams.module.ngfactory").then(function (m) { return m.ExamsModuleNgFactory; }); };
var routes = [
    {
        path: "expediente",
        canActivate: [PatientInternalGuard],
        children: [
            {
                path: "nueva-consulta",
                pathMatch: "prefix",
                canActivate: [InConsultGuard],
                canDeactivate: [OutConsultGuard],
                loadChildren: ɵ0,
            },
            {
                path: "consulta/:id",
                component: PreviewConsultComponent,
            },
            {
                path: "consulta_paciente/:id",
                component: PreviewConsultPatientComponent,
            },
            {
                path: "receta/:id",
                component: PreviewPrescriptionComponent,
            },
            {
                path: "",
                component: ExpedientComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "consultas",
                        pathMatch: "prefix",
                    },
                    {
                        path: "perfil",
                        loadChildren: ɵ1,
                    },
                    {
                        path: "consultas",
                        component: ConsultsComponent,
                    },
                    {
                        path: "antecedentes",
                        loadChildren: ɵ2,
                    },
                    {
                        path: "contactos",
                        loadChildren: ɵ3,
                    },
                    {
                        path: "seguros",
                        loadChildren: ɵ4,
                    },
                    {
                        path: "vacunas",
                        loadChildren: ɵ5,
                    },
                    {
                        path: "curvas_crecimiento",
                        loadChildren: ɵ6,
                    },
                    {
                        path: "relaciones",
                        loadChildren: ɵ7,
                    },
                    {
                        path: "instituciones",
                        loadChildren: ɵ8,
                    },
                    {
                        path: "procedimientos",
                        loadChildren: ɵ9,
                    },
                    {
                        path: "referencias",
                        loadChildren: ɵ10,
                    },
                    {
                        path: "examenes",
                        loadChildren: ɵ11,
                    },
                    {
                        path: "medicinas",
                        component: MedicinesComponent,
                    },
                    {
                        path: "citas",
                        component: DateComponent,
                    },
                    {
                        path: "chat",
                        component: ChatComponent,
                    },
                    {
                        path: "**",
                        redirectTo: "consultas",
                    },
                ],
            },
        ],
    },
    {
        path: "nuevo_paciente",
        component: NewPatientComponent,
        canActivate: [NewPatientGuard],
        canDeactivate: [OutNewPatientGuard],
    },
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
