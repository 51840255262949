import { AnthropometriesClass } from "./anthropometries-class";
import { CustomListClass } from "./custom-list-class";
import { DiagnosisClass } from "./diagnosis-class";
import { ExamsPhysicalClass } from "./exams-physical-class";
import { ExamsRequestsClass } from "./exams-requests-class";
import { ExamsResultsClass } from "./exams-results-class";
import { GeneralClass } from "./general-class";
import { PrescriptionClass } from "./prescription-class";
import { ProceduresRequestsClass } from "./procedures-requests-class";
import { ProceduresResultsClass } from "./procedures-results-class";
import { RecommendationClass } from "./recommendation-class";
import { ReferencesClass } from "./references-class";
import { TreatmentClass } from "./treatment-class";
import { VitalsClass } from "./vitals-class";
import { GrowthCurveClass } from "./growth_curve/growth_curve-class";
var ConsultClass = /** @class */ (function () {
    function ConsultClass(catalogsService, pipesService, consult) {
        this.catalogsService = catalogsService;
        this.pipesService = pipesService;
        if (consult)
            this.init(consult);
        else
            this.reset();
    }
    ConsultClass.prototype.reset = function () {
        this.id = null;
        this.id_doctor = null;
        this.id_patient = null;
        this.id_consults_type = null;
        this.doctor_name = "";
        this.doctor_username = "";
        this.doctor_gender = "";
        this.doctor_specialty = "";
        this.doctor_image = "";
        this.patient_name = "";
        this.patient_gender = "1";
        this.own = "0";
        this.age = null;
        this.age_month = null;
        this.age_day = null;
        this.id_consults_status = null;
        this.created_at = new Date();
        this.created_by = null;
        this.updated_at = new Date();
        this.anthropometries = new AnthropometriesClass(this.catalogsService);
        this.diagnosis_list = new CustomListClass();
        this.exams_physical = new ExamsPhysicalClass();
        this.exams_requests_list = new CustomListClass();
        this.exams_results_list = new CustomListClass();
        this.general = new GeneralClass();
        this.prescription_list = new CustomListClass();
        this.treatment_list = new CustomListClass();
        this.procedures_requests_list = new CustomListClass();
        this.procedures_results_list = new CustomListClass();
        this.recommendation = new RecommendationClass();
        this.references_list = new CustomListClass();
        this.vitals = new VitalsClass(this.catalogsService);
        this.growth_curve = new GrowthCurveClass(this.catalogsService);
        this.growth_curve_list = new CustomListClass();
    };
    ConsultClass.prototype.init = function (consult) {
        var _this = this;
        this.id = consult.id ? consult.id : null;
        this.id_doctor = consult.id_doctor ? consult.id_doctor : null;
        this.id_patient = consult.id_patient ? consult.id_patient : null;
        this.id_consults_type = consult.id_consults_type ? consult.id_consults_type : null;
        this.id_consults_status = consult.id_consults_status ? consult.id_consults_status : null;
        this.created_at = consult.created_at ? consult.created_at : new Date();
        this.created_by = consult.created_by ? consult.created_by : null;
        this.updated_at = consult.updated_at ? consult.updated_at : new Date();
        this.doctor_name = consult.doctor_name ? consult.doctor_name : "";
        this.doctor_username = consult.doctor_username ? consult.doctor_username : "";
        this.doctor_gender = consult.doctor_gender ? consult.doctor_gender : "";
        this.doctor_specialty = consult.doctor_specialty ? consult.doctor_specialty : "";
        this.doctor_image = consult.doctor_image ? consult.doctor_image : "";
        this.patient_name = consult.patient_name ? consult.patient_name : "";
        this.own = consult.own ? consult.own : "";
        this.patient_gender = consult.patient_gender ? consult.patient_gender : "";
        this.age = consult.age ? consult.age : "";
        this.age_month = consult.age_month ? consult.age_month : "";
        this.age_day = consult.age_day ? consult.age_day : "";
        if (consult.anthropometries)
            this.anthropometries = new AnthropometriesClass(this.catalogsService, consult.anthropometries);
        if (consult.diagnosis_list)
            this.diagnosis_list = new CustomListClass(consult.diagnosis_list.map(function (diagnosis) { return new DiagnosisClass(_this.catalogsService, diagnosis); }));
        if (consult.exams_physical)
            this.exams_physical = new ExamsPhysicalClass(consult.exams_physical);
        if (consult.exams_requests_list)
            this.exams_requests_list = new CustomListClass(consult.exams_requests_list.map(function (exams_requests) { return new ExamsRequestsClass(_this.catalogsService, exams_requests); }));
        if (consult.exams_results_list)
            this.exams_results_list = new CustomListClass(consult.exams_results_list.map(function (exams_results) { return new ExamsResultsClass(_this.catalogsService, _this.pipesService, exams_results); }));
        if (consult.general)
            this.general = new GeneralClass(consult.general);
        if (consult.prescription_list)
            this.prescription_list = new CustomListClass(consult.prescription_list.map(function (prescription) { return new PrescriptionClass(_this.catalogsService, prescription); }));
        if (consult.treatment_list)
            this.treatment_list = new CustomListClass(consult.treatment_list.map(function (treatment) { return new TreatmentClass(_this.catalogsService, treatment); }));
        if (consult.procedures_requests_list)
            this.procedures_requests_list = new CustomListClass(consult.procedures_requests_list.map(function (procedures_requests) { return new ProceduresRequestsClass(_this.catalogsService, procedures_requests); }));
        if (consult.procedures_results_list)
            this.procedures_results_list = new CustomListClass(consult.procedures_results_list.map(function (procedures_results) { return new ProceduresResultsClass(_this.catalogsService, _this.pipesService, procedures_results); }));
        if (consult.recommendation)
            this.recommendation = new RecommendationClass(consult.recommendation);
        if (consult.references_list)
            this.references_list = new CustomListClass(consult.references_list.map(function (references) { return new ReferencesClass(_this.catalogsService, references); }));
        if (consult.vitals)
            this.vitals = new VitalsClass(this.catalogsService, consult.vitals);
        if (consult.growth_curve)
            this.growth_curve = new GrowthCurveClass(this.catalogsService, consult.growth_curve);
        else
            this.growth_curve = new GrowthCurveClass(this.catalogsService);
        if (consult.growth_curve_list)
            this.growth_curve_list = new CustomListClass(consult.growth_curve_list.map(function (growth_curve) { return new GrowthCurveClass(_this.catalogsService, growth_curve); }));
    };
    ConsultClass.prototype.raw = function () {
        var data = {};
        data.id = this.id;
        data.id_doctor = this.id_doctor;
        data.doctor_name = this.doctor_name;
        data.doctor_username = this.doctor_username;
        data.doctor_gender = this.doctor_gender;
        data.doctor_specialty = this.doctor_specialty;
        data.doctor_image = this.doctor_image;
        data.patient_name = this.patient_name;
        data.id_patient = this.id_patient;
        data.id_consults_type = this.id_consults_type;
        data.id_consults_status = this.id_consults_status;
        data.created_at = this.created_at;
        data.created_by = this.created_by;
        data.updated_at = this.updated_at;
        data.own = this.own;
        data.patient_gender = this.patient_gender;
        data.age = this.age;
        data.age_month = this.age_month;
        data.age_day = this.age_day;
        data.anthropometries = this.anthropometries.raw();
        data.diagnosis_list = this.diagnosis_list.raw();
        data.exams_physical = this.exams_physical.raw();
        data.exams_requests_list = this.exams_requests_list.raw();
        data.exams_results_list = this.exams_results_list.raw();
        data.general = this.general.raw();
        data.prescription_list = this.prescription_list.raw();
        data.procedures_requests_list = this.procedures_requests_list.raw();
        data.procedures_results_list = this.procedures_results_list.raw();
        data.recommendation = this.recommendation.raw();
        data.references_list = this.references_list.raw();
        data.treatment_list = this.treatment_list.raw();
        data.vitals = this.vitals.raw();
        data.growth_curve = this.growth_curve.raw();
        data.growth_curve_list = this.growth_curve_list.raw();
        return data;
    };
    ConsultClass.prototype.to_string = function () {
        var data = {};
        data.id = this.id;
        data.id_doctor = this.id_doctor;
        data.id_patient = this.id_patient;
        data.id_consults_type = this.id_consults_type;
        data.id_consults_status = this.id_consults_status;
        data.created_at = this.created_at;
        data.created_by = this.created_by;
        data.updated_at = this.updated_at;
        data.doctor_name = this.doctor_name;
        data.doctor_username = this.doctor_username;
        data.doctor_gender = this.doctor_gender;
        data.doctor_specialty = this.doctor_specialty;
        data.doctor_image = this.doctor_image;
        data.patient_name = this.patient_name;
        data.own = this.own;
        data.patient_gender = this.patient_gender;
        data.age = this.age;
        data.age_month = this.age_month;
        data.age_day = this.age_day;
        data.anthropometries = this.anthropometries.to_string();
        data.diagnosis_list = this.diagnosis_list.to_string();
        data.exams_physical = this.exams_physical.to_string();
        data.exams_requests_list = this.exams_requests_list.to_string();
        data.exams_results_list = this.exams_results_list.to_string();
        data.general = this.general.to_string();
        data.prescription_list = this.prescription_list.to_string();
        data.procedures_requests_list = this.procedures_requests_list.to_string();
        data.procedures_results_list = this.procedures_results_list.to_string();
        data.recommendation = this.recommendation.to_string();
        data.references_list = this.references_list.to_string();
        data.treatment_list = this.treatment_list.to_string();
        data.vitals = this.vitals.to_string();
        data.growth_curve = this.growth_curve.to_string();
        data.growth_curve_list = this.growth_curve_list.to_string();
        return data;
    };
    ConsultClass.prototype.pretty = function () { };
    ConsultClass.prototype.valid = function () {
        if (this.general.reason && this.general.reason.trim().length > 0) {
            return true;
        }
        return false;
    };
    return ConsultClass;
}());
export { ConsultClass };
