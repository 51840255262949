import { Socket } from 'ngx-socket-io';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io";
var SocketService = /** @class */ (function () {
    function SocketService(socket) {
        this.socket = socket;
        this.showAlert = false;
        this.socketStatus = false;
    }
    SocketService.prototype.init = function () {
        this.listen();
    };
    SocketService.prototype.listen = function () {
        var _this = this;
        this.socket.on('connect', function () {
            console.info("Conectado al Servidor");
            _this.socketStatus = true;
            _this.showAlert = false;
        });
        this.socket.on('error', function () {
            console.info("Sin Conexión con el Servidor");
            _this.socketStatus = false;
        });
        this.socket.on('disconnect', function () {
            console.info("Desconectado del Servidor");
            _this.socketStatus = false;
            _this.showAlert = true;
        });
        this.socket.on('notification', function (data) {
            console.info(data);
        });
    };
    // AUTH
    SocketService.prototype.login = function (id) {
        this.socket.emit('login', { id: id });
    };
    SocketService.prototype.logout = function () {
        this.socket.emit('logout');
    };
    // AUTH
    SocketService.prototype.sendMessage = function (msg) {
        this.socket.emit('message', msg);
    };
    SocketService.prototype.getMessage = function () {
        return this.socket.fromEvent('message');
    };
    SocketService.prototype.getTutorPhoneVerify = function () {
        return this.socket.fromEvent('TutorPhoneVerify');
    };
    SocketService.prototype.getUserPhoneVerify = function () {
        return this.socket.fromEvent('UserPhoneVerify');
    };
    SocketService.prototype.getUserEmailVerify = function () {
        return this.socket.fromEvent('UserEmailVerify');
    };
    SocketService.prototype.getDoctorPatientPhoneVerify = function () {
        return this.socket.fromEvent('DoctorPatientPhoneVerify');
    };
    SocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.ɵɵinject(i1.Socket)); }, token: SocketService, providedIn: "root" });
    return SocketService;
}());
export { SocketService };
