import { EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AppService } from "src/app/services/app.service";
import { ExpedientService } from "src/app/services/expedient.service";
import { PageService } from "src/app/services/page.service";
import { SweelService } from "src/app/shared/services/sweel.service";
import { DatatableComponent } from "src/app/shared/ui/datatable/datatable.component";
import { SearchButtonComponent } from "src/app/shared/ui/search_button/search_button.component";
import { TutorsService } from "./tutors.service";
var RelationsTutorsComponent = /** @class */ (function () {
    function RelationsTutorsComponent(endpoint, expedientService, pageService, moduleService, sweel, toastr) {
        this.endpoint = endpoint;
        this.expedientService = expedientService;
        this.pageService = pageService;
        this.moduleService = moduleService;
        this.sweel = sweel;
        this.toastr = toastr;
        this._change_view_event = new EventEmitter();
        this.moduleService.init_list();
        this.datatable_config();
        this.dynamic_tabs_config();
    }
    RelationsTutorsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            _this.moduleService.reload_list(_this.datatable.filters);
            _this.dynamic_tabs_config();
        });
        this.list_subscription = this.moduleService.obv_list.subscribe(function (state) { return (_this.list_state = state); });
        this.page_subscription = this.pageService.current.subscribe(function (state) { return (_this.page_state = state); });
    };
    RelationsTutorsComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.list_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
    };
    RelationsTutorsComponent.prototype.dynamic_tabs_config = function () {
        if (this.expedient_state) {
            if (this.expedient_state.version == "expedient") {
                this.dynamic_tabs = {
                    list: [
                        {
                            name: "tutores",
                            class: "mx-xl-5 mx-md-3 mx-2",
                            url: ["../tutores"],
                        },
                    ],
                };
            }
            else {
                this.dynamic_tabs = {
                    selected: 0,
                    list: [
                        {
                            name: "tutores",
                            class: "mx-xl-5 mx-md-3 mx-2",
                            click: function () { },
                        },
                    ],
                };
            }
        }
    };
    RelationsTutorsComponent.prototype.datatable_config = function () {
        this.datatable = {
            configs: {
                title: "Relaciones de Tutores",
                //icon: "fa-duotone fa-address-book",
                paginate: false,
                search: false,
            },
            filters: {
                limit: 20,
                skip: 0,
            },
            headers: [
                {
                    label: "Nombres",
                    name: "firstname",
                    order_by: "firstname",
                },
                {
                    label: "Apellidos",
                    name: "lastname",
                    order_by: "lastname",
                },
                {
                    label: "Identificación",
                    name: "identification",
                    order_by: "identification",
                },
                {
                    label: "Teléfono",
                    name: "phone",
                    order_by: "phone",
                },
            ],
            events: [
                // {
                //     name: "Detalles",
                //     icon: "fa-duotone fa-file-circle-info text-info",
                // },
                // {
                //     name: "Modificar",
                //     icon: "fa-duotone fa-edit text-warning",
                //     disabled: item => item.accepted,
                // },
                // {
                //   name: "Vincular",
                //   icon: "fa-duotone fa-check text-success",
                //   disabled: item => item.accepted
                // },
                {
                    name: "Eliminar",
                    icon: "fa-duotone fa-trash-alt text-danger",
                },
            ],
        };
    };
    RelationsTutorsComponent.prototype.on_update = function () {
        if (this.datatable_ref)
            this.datatable_ref.update();
    };
    RelationsTutorsComponent.prototype.update = function (data) {
        if (this.search_button_ref)
            data.search_word = this.search_button_ref.data;
        this.moduleService.reload_list(data);
    };
    RelationsTutorsComponent.prototype.events = function (data) {
        var _this = this;
        this.selected_item = data.item;
        // if (data.name == "Detalles" || data.name == "row_click") {
        //     this.expedientService.change_method("show");
        //     this.moduleService.init(this.selected_item);
        //     this.pageService.openModal(this.showModal, "lg");
        // }
        if (data.name == "Eliminar") {
            this.moduleService.init(this.selected_item);
            this.sweel
                .confirm("Eliminar Relación", "Por favor confirme que desea eliminar la relación.", "warning")
                .then(function (res) {
                if (res) {
                    _this.moduleService.delete();
                }
            });
        }
        // if (data.name == "Modificar") {
        //     this.expedientService.change_method("update");
        //     this.moduleService.init(this.selected_item);
        //     this.pageService.openModal(this.updateModal, "lg");
        // }
    };
    RelationsTutorsComponent.prototype.create = function () {
        this.pageService.openModal(this.createModal, "lg");
    };
    return RelationsTutorsComponent;
}());
export { RelationsTutorsComponent };
