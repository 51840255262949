import { EmergencyService } from '../emergency.service';
var UpdateContactsEmergencyComponent = /** @class */ (function () {
    function UpdateContactsEmergencyComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateContactsEmergencyComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateContactsEmergencyComponent;
}());
export { UpdateContactsEmergencyComponent };
