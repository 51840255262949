var ChartDatasetInterface = /** @class */ (function () {
    function ChartDatasetInterface() {
    }
    return ChartDatasetInterface;
}());
export { ChartDatasetInterface };
var ChartDataset = /** @class */ (function () {
    function ChartDataset(data) {
        this.label = data.label;
        this.data = data.data;
        this.backgroundColor = data.backgroundColor;
        this.borderColor = data.borderColor;
        this.borderWidth = data.borderWidth;
        this.tension = data.tension;
        this.borderJoinStyle = data.borderJoinStyle;
        this.pointBackgroundColor = data.pointBackgroundColor;
        this.pointBorderColor = data.pointBorderColor;
        this.pointRadius = data.pointRadius;
        this.pointHoverRadius = data.pointHoverRadius;
        this.fill = data.fill;
        this.pointStyle = data.pointStyle;
        this.tooltip = data.tooltip;
        this.cubicInterpolationMode = data.cubicInterpolationMode;
    }
    ChartDataset.prototype.updateLabel = function (label) {
        this.label = label;
    };
    ChartDataset.prototype.updateData = function (data) {
        this.data = data;
    };
    ChartDataset.prototype.updateBackgroundColor = function (backgroundColor) {
        this.backgroundColor = backgroundColor;
    };
    ChartDataset.prototype.updateBorderColor = function (borderColor) {
        this.borderColor = borderColor;
    };
    ChartDataset.prototype.updateBorderWidth = function (borderWidth) {
        this.borderWidth = borderWidth;
    };
    return ChartDataset;
}());
export { ChartDataset };
