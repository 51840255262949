import { Routes } from '@angular/router';
import { InstitutionsComponent } from './institutions.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: '',
        component: InstitutionsComponent
    }
];
var InstitutionsModule = /** @class */ (function () {
    function InstitutionsModule() {
    }
    return InstitutionsModule;
}());
export { InstitutionsModule };
