import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./tutors/tutors.module.ngfactory").then(function (m) { return m.RelationsTutorsModuleNgFactory; }); }, ɵ1 = function () { return import("./dependants/dependants.module.ngfactory").then(function (m) { return m.RelationsDependantsModuleNgFactory; }); };
var routes = [
    {
        path: '',
        redirectTo: 'tutores',
        pathMatch: 'prefix'
    },
    {
        path: 'tutores',
        loadChildren: ɵ0
    },
    {
        path: 'dependientes',
        loadChildren: ɵ1
    },
];
var RelationsModule = /** @class */ (function () {
    function RelationsModule() {
    }
    return RelationsModule;
}());
export { RelationsModule };
export { ɵ0, ɵ1 };
