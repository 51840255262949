import { PageService } from 'src/app/services/page.service';
var ShowBackgroundAllergiesComponent = /** @class */ (function () {
    function ShowBackgroundAllergiesComponent(pageService) {
        this.pageService = pageService;
    }
    ShowBackgroundAllergiesComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowBackgroundAllergiesComponent;
}());
export { ShowBackgroundAllergiesComponent };
