import { OnInit } from '@angular/core';
var PreloaderComponent = /** @class */ (function () {
    function PreloaderComponent() {
        this.display = false;
    }
    PreloaderComponent.prototype.ngOnInit = function () {
    };
    /**
     * Shows the loader
     */
    PreloaderComponent.prototype.show = function () {
        this.display = true;
    };
    /**
     * Hides the loader
     */
    PreloaderComponent.prototype.hide = function () {
        this.display = false;
    };
    return PreloaderComponent;
}());
export { PreloaderComponent };
