import { Routes } from '@angular/router';
import { BackgroundAllergiesComponent } from './allergies.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: 'alergicos',
        component: BackgroundAllergiesComponent
    }
];
var BackgroundAllergiesModule = /** @class */ (function () {
    function BackgroundAllergiesModule() {
    }
    return BackgroundAllergiesModule;
}());
export { BackgroundAllergiesModule };
