import * as tslib_1 from "tslib";
import { OwlDateTimeIntl } from 'ng-pick-datetime';
var DefaultIntl = /** @class */ (function (_super) {
    tslib_1.__extends(DefaultIntl, _super);
    function DefaultIntl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cancelBtnLabel = 'Cancelar';
        _this.setBtnLabel = 'Aceptar';
        return _this;
    }
    return DefaultIntl;
}(OwlDateTimeIntl));
export { DefaultIntl };
;
var FormRenderModule = /** @class */ (function () {
    function FormRenderModule() {
    }
    return FormRenderModule;
}());
export { FormRenderModule };
