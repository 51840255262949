import { OnInit, OnDestroy } from '@angular/core';
import { PatientService } from 'src/app/services/patient-service.service';
import { DatatableComponent } from 'src/app/shared/ui/datatable/datatable.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';
import { PageService } from '../../services/page.service';
import { data_billing_info } from 'src/app/data/billing';
import { AppService } from 'src/app/services/app.service';
import { ConsultGeneralService } from '../expedient/new_consult/general/consult.service';
var MyPatientsComponent = /** @class */ (function () {
    function MyPatientsComponent(endpoint, patientService, pageService, consultService) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.pageService = pageService;
        this.consultService = consultService;
        this.data = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
        this.datatable_config();
    }
    MyPatientsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.page_subscription = this.pageService.current.subscribe(function (state) { return _this.page_state = state; });
    };
    MyPatientsComponent.prototype.ngOnDestroy = function () {
        this.page_subscription.unsubscribe();
    };
    MyPatientsComponent.prototype.datatable_config = function () {
        this.datatable = {
            configs: {
                title: "Pacientes",
                icon: "fa-duotone fa-users",
                paginate: true,
                search: true
            },
            filters: {
                limit: 50,
                skip: 0
            },
            headers: [
                {
                    label: "Nombres",
                    name: "firstname",
                    order_by: "firstname"
                },
                {
                    label: "Apellidos",
                    name: "lastname",
                    order_by: "lastname"
                },
                {
                    label: "Identificación",
                    name: "identification",
                    order_by: "identification",
                },
            ],
            events: [
                {
                    name: "Detalles",
                    icon: "fa-duotone fa-file-circle-info text-info"
                },
            ]
        };
    };
    MyPatientsComponent.prototype.update = function (data) {
        var _this = this;
        if (this.search_button_ref)
            data.search_word = this.search_button_ref.data;
        this.endpoint.get_my_patients(data).subscribe(function (data) { return _this.data = data; }, function (err) { return console.error(err); });
    };
    MyPatientsComponent.prototype.events = function (data) {
        if (data.name == "Detalles" || data.name == "row_click") {
            this.consultService.remove_storage();
            this.consultService.full_clean();
            this.patientService.change_patient_id(data.item.id_user);
            data_billing_info.splice(0, data_billing_info.length);
            this.endpoint.redirect_to("/expediente/consultas");
        }
        else if (data.name == "Editar") {
        }
        else if (data.name == "Deshabilitar") {
        }
    };
    MyPatientsComponent.prototype.on_update = function () {
        if (this.datatable_ref)
            this.datatable_ref.update();
    };
    return MyPatientsComponent;
}());
export { MyPatientsComponent };
