import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ConsultClass } from 'src/app/classes/consult-class';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { PageService } from 'src/app/services/page.service';
import { PipesService } from 'src/app/services/pipes.service';
var ShowConsultMedicalComponent = /** @class */ (function () {
    function ShowConsultMedicalComponent(pageService, endpoint, accountService, catalogsService, pipesService) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.accountService = accountService;
        this.catalogsService = catalogsService;
        this.pipesService = pipesService;
        this.data = {
            created_at: new Date()
        };
        this.close_event = new EventEmitter();
        this.avatar_configs = {
            size: "6rem",
            font_size: "4.3rem",
        };
    }
    ShowConsultMedicalComponent.prototype.ngOnInit = function () {
        this.pageService.set_sidebar_3_state({
            expand: true,
            scroll: false,
            show: true
        });
        this.get_data();
    };
    ShowConsultMedicalComponent.prototype.ngOnDestroy = function () {
        this.pageService.set_sidebar_3_state({
            expand: true,
            scroll: true,
            show: true
        });
    };
    ShowConsultMedicalComponent.prototype.get_data = function () {
        var _this = this;
        this.endpoint.expedient_consult_general_get_consult({ id: this.data.id }).subscribe(function (data) {
            _this.data.consult = new ConsultClass(_this.catalogsService, _this.pipesService, data);
            _this.data.consult_l = _this.data.consult.to_string();
        }, function (error) { return _this.close(); });
    };
    ShowConsultMedicalComponent.prototype.close = function () {
        this.close_event.emit("");
    };
    ShowConsultMedicalComponent.prototype.get_file_endpoint = function (path, name) {
        return this.endpoint.get_file_endpoint(path, name);
    };
    ShowConsultMedicalComponent.prototype.is_author = function () {
        return this.data.consult.id_doctor == this.accountService.get_current_state().id;
    };
    ShowConsultMedicalComponent.prototype.error_image = function () {
        if (this.data.patient_gender == "1")
            this.data.patient_image = 'User-Female.svg';
        if (this.data.patient_gender == "2")
            this.data.patient_image = 'User-Male.svg';
    };
    return ShowConsultMedicalComponent;
}());
export { ShowConsultMedicalComponent };
