import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { EmergencyService } from '../emergency.service';
import { ExpedientService } from 'src/app/services/expedient.service';
var FormComponent = /** @class */ (function () {
    function FormComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            // this.moduleService.reload();
            _this.form_configs();
        });
        this.emergency_subscription = this.moduleService.current.subscribe(function (state) { return _this.emergency_state = state; });
        this.form_configs();
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        if (this.emergency_state) {
            this.moduleService.forms.emergency = this.form_ref;
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.emergency_subscription.unsubscribe();
    };
    FormComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return _this.expedient_state.method == "show"; },
            disabled: function () { return _this.expedient_state.method == "show"; },
            submit: function (callback) { return _this.moduleService.submit(callback); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "firstname",
                            type: "name",
                            label: "Nombres",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Nombre"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "lastname",
                            type: "name",
                            label: "Apellidos",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Apellido"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "phone",
                            type: "phone",
                            hn: function () { return true; },
                            label: "Teléfono",
                            small: "",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 30; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "cellphone",
                            type: "phone",
                            hn: function () { return true; },
                            label: "Celular",
                            small: "",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 30; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "email",
                            type: "email",
                            label: "Correo Electrónico",
                            placeholder: function () { return "alguien@email.com"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_relationship",
                            type: "select",
                            label: "Parentesco",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.moduleService.catalogs.relationships; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                        },
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    click: function () {
                                        _this.moduleService.cancel();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cerrar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "show";
                                    },
                                    click: function () {
                                        _this.moduleService.close();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Guardar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    disabled: function () { return (_this.form_ref ? !_this.form_ref.valid() : false) || _this.emergency_state.loading; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    return FormComponent;
}());
export { FormComponent };
