import { PipeTransform } from '@angular/core';
import { PipesService } from 'src/app/services/pipes.service';
var timeToString = /** @class */ (function () {
    function timeToString(pipesService) {
        this.pipesService = pipesService;
    }
    timeToString.prototype.transform = function (str) {
        return this.pipesService.time_tostring(str);
    };
    return timeToString;
}());
export { timeToString };
