import { CatalogsService } from "src/app/services/catalogs.service";
import { WebDBService } from "./services/webdb.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(webdbService, catalogsService) {
        this.webdbService = webdbService;
        this.catalogsService = catalogsService;
    }
    return AppComponent;
}());
export { AppComponent };
