import { OnInit, OnDestroy } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { PatientService, } from "src/app/services/patient-service.service";
import { PageService } from "../../../services/page.service";
var BriefComponent = /** @class */ (function () {
    function BriefComponent(endpoint, patientService, pageService) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.pageService = pageService;
        this.avatar_configs = {
            size: "5rem",
        };
        this.show = true;
    }
    BriefComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patient_subscription =
            this.patientService.patient_data_subscription.subscribe(function (data) { return (_this.patient_state = data); });
        this.page_subscription = this.pageService.current.subscribe(function (state) { return (_this.page_state = state); });
    };
    BriefComponent.prototype.ngOnDestroy = function () {
        this.patient_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
    };
    return BriefComponent;
}());
export { BriefComponent };
