import { OnInit, EventEmitter } from '@angular/core';
var SearchButtonComponent = /** @class */ (function () {
    function SearchButtonComponent() {
        this.submit = new EventEmitter;
        this.change = new EventEmitter;
        this.data = "";
        this.display = false;
        this.timeout = null;
    }
    SearchButtonComponent.prototype.ngOnInit = function () {
    };
    SearchButtonComponent.prototype.show = function () {
        return this.display || this.data;
    };
    SearchButtonComponent.prototype.on_submit = function () {
        this.submit.emit();
    };
    SearchButtonComponent.prototype.on_input = function () {
        var _this = this;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            _this.on_change();
        }, 1000);
    };
    SearchButtonComponent.prototype.on_change = function () {
        this.change.emit();
    };
    return SearchButtonComponent;
}());
export { SearchButtonComponent };
