import * as tslib_1 from "tslib";
import { CustomClass } from "./custom-class";
;
;
var TreatmentClass = /** @class */ (function (_super) {
    tslib_1.__extends(TreatmentClass, _super);
    function TreatmentClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    TreatmentClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_patient = data.id_patient;
        this.id_consult = data.id_consult;
        this.id_diagnosis = data.id_diagnosis;
        this.name = data.name;
        this.amount = data.amount;
        this.id_presentation = data.id_presentation;
        this.id_concentration = data.id_concentration;
        this.id_way = data.id_way;
        this.presentation = data.presentation;
        this.concentration = data.concentration;
        this.way = data.way;
        this.image = data.image;
        this.brand = data.brand;
        this.principle = data.principle;
        this.dosage = data.dosage;
        this.dosage_unit = data.dosage_unit;
        this.frecuency = data.frecuency;
        this.frecuency_unit = data.frecuency_unit;
        this.period = data.period;
        this.period_unit = data.period_unit;
        this.instructions = data.instructions;
        this.comments = data.comments;
        this.continues = data.continues;
    };
    TreatmentClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_patient = null;
        this.id_consult = null;
        this.name = "";
        this.amount = "";
        this.id_presentation = null;
        this.id_concentration = null;
        this.id_way = null;
        this.presentation = "";
        this.concentration = "";
        this.way = "";
        this.image = "";
        this.brand = "";
        this.principle = "";
        this.dosage = "";
        this.dosage_unit = "mg";
        this.frecuency = "";
        this.frecuency_unit = "Horas";
        this.period = "";
        this.period_unit = "Días";
        this.instructions = "";
        this.comments = "";
        this.continues = null;
    };
    TreatmentClass.prototype.format = function () {
        return this;
    };
    TreatmentClass.prototype.valid = function () {
        return true;
    };
    TreatmentClass.prototype.to_string = function () {
        var _this = this;
        var presentation = this.catalogsService.catalogs.medicines_presentations.find(function (v) { return v.id == _this.id_presentation; });
        var way = this.catalogsService.catalogs.medicines_ways.find(function (v) { return v.id == _this.id_way; });
        var data = {
            name: this.name,
            amount: this.amount,
            presentation: presentation ? presentation.name : "",
            concentration: this.concentration,
            way: way ? way.name : "",
        };
        return data;
    };
    TreatmentClass.prototype.raw = function () {
        return {
            id: this.id,
            id_patient: this.id_patient,
            id_consult: this.id_consult,
            id_diagnosis: this.id_diagnosis,
            name: this.name,
            amount: this.amount,
            id_presentation: this.id_presentation,
            id_concentration: this.id_concentration,
            id_way: this.id_way,
            presentation: this.presentation,
            concentration: this.concentration,
            way: this.way,
            image: this.image,
            brand: this.brand,
            principle: this.principle,
            dosage: this.dosage,
            dosage_unit: this.dosage_unit,
            frecuency: this.frecuency,
            frecuency_unit: this.frecuency_unit,
            period: this.period,
            period_unit: this.period_unit,
            instructions: this.instructions,
            comments: this.comments,
            continues: this.continues,
        };
    };
    return TreatmentClass;
}(CustomClass));
export { TreatmentClass };
