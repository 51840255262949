import { PipeTransform } from '@angular/core';
var shortDate = /** @class */ (function () {
    function shortDate() {
        this.months = [{ value: '01', label: 'Enero', code: "Ene" }, { value: '02', label: 'Febrero', }, { value: '03', label: 'Marzo' }, { value: '04', label: 'Abril' }, { value: '05', label: 'Mayo' }, { value: '06', label: 'Junio' }, { value: '07', label: 'Julio' }, { value: '08', label: 'Agosto' }, { value: '09', label: 'Septiembre' }, { value: '10', label: 'Octubre' }, { value: '11', label: 'Noviembre' }, { value: '12', label: 'Diciembre' }];
    }
    shortDate.prototype.transform = function (str) {
        if (str) {
            var raw = new Date(str).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit' });
            var raw_array_1 = raw.split("/");
            return raw_array_1[0] + " / " + this.months.find(function (val) { return val.value == raw_array_1[1]; }).label + " / " + raw_array_1[2];
        }
        return "";
    };
    return shortDate;
}());
export { shortDate };
