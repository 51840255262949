import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
var longOnlyTime = /** @class */ (function () {
    function longOnlyTime() {
    }
    longOnlyTime.prototype.transform = function (str, timeZone) {
        var date = new Date(str).toLocaleDateString('es-HN', tslib_1.__assign({ hour: '2-digit', minute: '2-digit', hour12: true }, timeZone ? { timeZone: timeZone } : {}));
        var fragments = date.split(" ").slice(1).join(" ").split(":");
        return fragments[0].replace("00", "12") + ":" + fragments[1];
    };
    return longOnlyTime;
}());
export { longOnlyTime };
