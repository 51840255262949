import { ExpedientService } from 'src/app/services/expedient.service';
import { DependantsService } from '../dependants.service';
var CreateRelationsDependantsComponent = /** @class */ (function () {
    function CreateRelationsDependantsComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateRelationsDependantsComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateRelationsDependantsComponent;
}());
export { CreateRelationsDependantsComponent };
