import { OnInit, OnDestroy, AfterViewInit, } from "@angular/core";
import { Router } from "@angular/router";
import { PageService } from "../../services/page.service";
import { PatientService } from "src/app/services/patient-service.service";
import { AppService } from "src/app/services/app.service";
import { AccountService } from "src/app/services/account.service";
var TopbarComponent = /** @class */ (function () {
    function TopbarComponent(router, patientService, pageService, accountService, endpoint) {
        this.router = router;
        this.patientService = patientService;
        this.pageService = pageService;
        this.accountService = accountService;
        this.endpoint = endpoint;
        this.avatar_configs = {
            size: "2.5rem",
            font_size: "1.5rem",
        };
    }
    TopbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.account_subscription = this.accountService.current.subscribe(function (data) { return (_this.account_state = data); });
        this.patient_subscription =
            this.patientService.patient_data_subscription.subscribe(function (data) { return (_this.patient_data = data); });
        this.page_subscription = this.pageService.current.subscribe(function (state) { return (_this.page_state = state); });
    };
    TopbarComponent.prototype.ngAfterViewInit = function () { };
    TopbarComponent.prototype.ngOnDestroy = function () {
        this.account_subscription.unsubscribe();
        this.patient_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
    };
    TopbarComponent.prototype.sidebar_1_toggle = function () {
        this.pageService.set_sidebar_1_state({
            expand: !this.page_state.sidebar_1.expand,
            scroll: true,
            show: this.page_state.sidebar_1.show,
        });
    };
    TopbarComponent.prototype.click_search = function () {
        this.endpoint.redirect_to("/buscar");
    };
    TopbarComponent.prototype.logout = function () {
        var _this = this;
        this.endpoint.logout().subscribe(function () {
            _this.endpoint.reset_session();
            _this.router.navigate(["/conectarse"]);
        });
    };
    TopbarComponent.prototype.new_consult = function () {
        this.pageService.set_consult(true);
    };
    return TopbarComponent;
}());
export { TopbarComponent };
