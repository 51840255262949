import { OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { PatientService } from 'src/app/services/patient-service.service';
import { AppService } from 'src/app/services/app.service';
import { AccountService } from 'src/app/services/account.service';
var FirstLoginComponent = /** @class */ (function () {
    function FirstLoginComponent(endpoint, accountService, patientService) {
        this.endpoint = endpoint;
        this.accountService = accountService;
        this.patientService = patientService;
        this.password_regex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/g;
        this.release_year = 2020;
        this.year = new Date().getFullYear();
        this.fileUploadControl = new FileUploadControl(FileUploadValidators.filesLimit(1));
        this.fileData = null;
        this.fileUploadProgress = null;
        this.uploadedFilePath = null;
        this.data = {
            general: {
                firstname: "",
                lastname: "",
                gender: "",
                phone: "",
                email: ""
            },
            change_password: {
                password: "",
                confirm_password: "",
            }
        };
        this.endpoint.pageTitle = 'Configuración de Cuenta';
        this.view = 1;
        this.completedView = 0;
        this.submittedUpload = false;
        this.form_configs();
        this.patientService.clean_patient();
    }
    FirstLoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.account_subscription = this.accountService.current.subscribe(function (state) {
            _this.data.general = state;
            _this.data.general.gender = state.id_gender;
        });
    };
    FirstLoginComponent.prototype.ngOnDestroy = function () {
        this.account_subscription.unsubscribe();
    };
    FirstLoginComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_general_config = {
            submit: function () { return _this.next(); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "firstname",
                            type: "name",
                            label: "Nombres",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Nombre"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "lastname",
                            type: "name",
                            label: "Apellidos",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Apellido"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "email",
                            type: "email",
                            label: "Correo Electrónico",
                            placeholder: function () { return "alguien@email.com"; },
                            col_class: "col-md-12",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                            },
                            disabled: function () { return true; },
                        },
                        {
                            name: "phone",
                            type: "phone",
                            hn: function () { return true; },
                            label: "Teléfono",
                            small: "",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 30; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "gender",
                            type: "select",
                            label: "Género",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return [{ id: 1, name: "Femenino" }, { id: 2, name: "Maculino" }]; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                        }
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-primary ml-auto",
                                    label: "Siguiente",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-arrow-right",
                                        position: 'right',
                                    },
                                    disabled: function () { return false; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
        this.form_change_password_config = {
            submit: function () { return _this.next(); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "password",
                            type: "password",
                            label: "Nueva Contraseña",
                            small: "",
                            col_class: "col-md-12",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                custom: {
                                    value: function () { return _this.password_regex.test(_this.data.change_password.password); },
                                    error: "Las contraseñas no coinciden"
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "confirm_password",
                            type: "password",
                            label: "Confirmar Contraseña",
                            small: "",
                            col_class: "col-md-12",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                custom: {
                                    value: function () { return _this.data.change_password.password == _this.data.change_password.confirm_password; },
                                    error: "Las contraseñas no coinciden"
                                }
                            },
                            disabled: function () { return false; },
                        },
                    ]
                },
                {
                    class: "row mt-3",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-outline-danger",
                                    label: "Anterior",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-arrow-left",
                                        position: 'left',
                                    },
                                    disabled: function () { return false; },
                                    click: function () { return _this.previous(); }
                                },
                                {
                                    class: "btn btn-primary ml-auto",
                                    label: "Aceptar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    disabled: function () { return _this.form_change_password_ref ? !_this.form_change_password_ref.valid() : false; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    FirstLoginComponent.prototype.next = function () {
        var _this = this;
        if (this.view == 1) {
            if (!this.form_general_ref.valid())
                return false;
            this.view = 2;
        }
        else if (this.view == 2) {
            if (!this.form_change_password_ref.valid())
                return false;
            var _a = this.data.general, firstname = _a.firstname, lastname = _a.lastname, gender = _a.gender, phone = _a.phone, email = _a.email;
            var _b = this.data.change_password, password = _b.password, confirm_password = _b.confirm_password;
            var formData = new FormData();
            if (this.uploadedFiles && this.uploadedFiles[0]) {
                formData.append("file", this.uploadedFiles[0], this.uploadedFiles[0]['name']);
            }
            formData.append("src", this.image_src);
            formData.append("firstname", firstname);
            formData.append("lastname", lastname);
            formData.append("gender", gender);
            formData.append("password", password);
            formData.append("confirm", confirm_password);
            formData.append("phone", phone);
            formData.append("email", email);
            this.endpoint.first_login(formData).subscribe(function (data) {
                _this.view = 3;
                _this.endpoint.set_session(data);
            }, function (err) {
                Swal.fire({
                    title: err.error.title,
                    text: err.error.message,
                    type: 'error',
                    timer: 10000,
                    confirmButtonText: 'Listo',
                    confirmButtonColor: '#66c8c8',
                });
            });
        }
    };
    FirstLoginComponent.prototype.previous = function () {
        this.view -= 1;
    };
    FirstLoginComponent.prototype.finish = function () {
        this.endpoint.redirect_to('');
    };
    FirstLoginComponent.prototype.open_file_chooser = function () {
        this.fileUploadControl.clear();
        this.fileUploadControl.click();
    };
    FirstLoginComponent.prototype.fileProgress = function () {
        this.fileData = this.uploadedFiles[0];
        this.preview();
    };
    FirstLoginComponent.prototype.preview = function () {
        var _this = this;
        var mimeType = this.fileData.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = function (_event) {
            _this.image_src = reader.result;
        };
    };
    return FirstLoginComponent;
}());
export { FirstLoginComponent };
