var SpinnerBigComponent = /** @class */ (function () {
    function SpinnerBigComponent() {
    }
    SpinnerBigComponent.prototype.ngOnChanges = function (changes) {
        if (changes.loading) {
            this.loading = changes.loading.currentValue;
        }
        if (changes.title) {
            this.title = changes.title.currentValue;
        }
        if (changes.class) {
            this.class = changes.class.currentValue;
        }
        if (changes.inline) {
            this.inline = changes.inline.currentValue;
        }
    };
    return SpinnerBigComponent;
}());
export { SpinnerBigComponent };
