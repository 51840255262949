var CustomHolderComponent = /** @class */ (function () {
    function CustomHolderComponent() {
        var _this = this;
        this.message_texts = [];
        this.message_interval = 10;
        this.message = '';
        this.message_index = 0;
        setInterval(function () {
            if (_this.message_texts.length > 0) {
                if (_this.message_index++ >= _this.message_texts.length) {
                    _this.message_index = 0;
                }
                _this.message = _this.message_texts.length[_this.message_index];
            }
        }, this.message_interval);
    }
    CustomHolderComponent.prototype.ngOnChanges = function (changes) {
        if (changes.icon) {
            this.icon = changes.icon.currentValue;
        }
        if (changes.icon_show) {
            this.icon_show = changes.icon_show.currentValue;
        }
        if (changes.title) {
            this.title = changes.title.currentValue;
        }
        if (changes.title_text) {
            this.title_text = changes.title_text.currentValue;
        }
        if (changes.title_show) {
            this.title_show = changes.title_show.currentValue;
        }
        if (changes.message) {
            this.message = changes.message.currentValue;
        }
        if (changes.message_show) {
            this.message_show = changes.message_show.currentValue;
        }
        if (changes.message_texts) {
            this.message_texts = changes.message_texts.currentValue;
        }
        if (changes.message_interval) {
            this.message_interval = changes.message_interval.currentValue;
        }
        if (changes.inline) {
            this.inline = changes.inline.currentValue;
        }
    };
    return CustomHolderComponent;
}());
export { CustomHolderComponent };
