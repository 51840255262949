import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { PathologiesService } from '../pathologies.service';
import { ExpedientService } from 'src/app/services/expedient.service';
var FormComponent = /** @class */ (function () {
    function FormComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            // this.moduleService.reload();
            _this.form_configs();
        });
        this.pathologies_subscription = this.moduleService.current.subscribe(function (state) { return _this.pathologies_state = state; });
        this.form_configs();
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        if (this.pathologies_state) {
            this.moduleService.forms.pathologies = this.form_ref;
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.pathologies_subscription.unsubscribe();
    };
    FormComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return _this.expedient_state.method == "show"; },
            disabled: function () { return _this.expedient_state.method == "show"; },
            submit: function (callback) { return _this.moduleService.submit(callback); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "id_pathology",
                            type: "select-search",
                            label: "Diagnóstico",
                            small: "",
                            placeholder: function () { return "Buscar patología"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.moduleService.catalogs.pathologies; },
                                value: "title",
                                text: "title"
                            },
                            loading: function () {
                                return _this.loading;
                            },
                            show: function () { return _this.expedient_state.method != "show"; },
                            disabled: function () { return false; },
                            change: function (event) {
                                _this.search_word = event.target.value;
                                // clearTimeout(this.pathology_timeout);
                                // this.get_icd_pathologies();
                            },
                            input: function (event) {
                                _this.search_word = event.target.value;
                                clearTimeout(_this.pathology_timeout);
                                _this.pathology_timeout = setTimeout(function () {
                                    _this.get_icd_pathologies();
                                }, 1000);
                            },
                            select: function (item, input_ref) {
                                input_ref.s_value = item.name;
                                _this.pathologies_state.pathologies.pop();
                                _this.pathologies_state.pathologies.push(tslib_1.__assign({}, item, { label: item.title }));
                                _this.pathologies_state.pathologies_parse = item.title;
                                _this.pathologies_state.name = item.title;
                                _this.pathologies_state.icd = item.id;
                                _this.loading = false;
                            },
                        },
                        {
                            type: "text",
                            col_class: "col-md-6",
                            class: "no-border",
                            label: this.expedient_state.method == "show" ? "Diagnóstico" : "⠀",
                            name: "pathologies_parse",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                }
                            },
                            disabled: function () { return true; }
                        },
                        {
                            name: "treatment",
                            type: "textarea",
                            label: "Tratamiento",
                            small: "",
                            rows: 2.5,
                            col_class: "col-12",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    click: function () {
                                        _this.moduleService.cancel();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cerrar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "show";
                                    },
                                    click: function () {
                                        _this.moduleService.close();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Guardar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    disabled: function () { return (_this.form_ref ? !_this.form_ref.valid() : false) || _this.pathologies_state.loading || !_this.pathologies_state.pathologies_parse; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    FormComponent.prototype.get_icd_pathologies = function () {
        var _this = this;
        this.loading = true;
        this.moduleService.get_icd_pathologies(this.search_word, function () {
            _this.loading = false;
        });
    };
    return FormComponent;
}());
export { FormComponent };
