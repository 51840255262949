import { PipeTransform } from '@angular/core';
var BooleanPipe = /** @class */ (function () {
    function BooleanPipe() {
    }
    BooleanPipe.prototype.transform = function (str) {
        return str == true ? "Sí" : "No";
    };
    return BooleanPipe;
}());
export { BooleanPipe };
