import { Routes } from '@angular/router';
import { InsurancesAccidentComponent } from './accident.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: 'accidentes',
        component: InsurancesAccidentComponent
    }
];
var InsurancesAccidentModule = /** @class */ (function () {
    function InsurancesAccidentModule() {
    }
    return InsurancesAccidentModule;
}());
export { InsurancesAccidentModule };
