import { Routes } from "@angular/router";
import { LayoutComponent } from "./layout.component";
var ɵ0 = function () { return import("./pages/general/general.module.ngfactory").then(function (m) { return m.ConsultGeneralModuleNgFactory; }); }, ɵ1 = function () { return import("./pages/anthropometries/anthropometries.module.ngfactory").then(function (m) { return m.ConsultAnthropometriesModuleNgFactory; }); }, ɵ2 = function () { return import("./pages/vitals/vitals.module.ngfactory").then(function (m) { return m.ConsultVitalsModuleNgFactory; }); }, ɵ3 = function () { return import("./pages/growth_curve/growth_curve.module.ngfactory").then(function (m) { return m.ConsultGrowthCurveModuleNgFactory; }); }, ɵ4 = function () { return import("./pages/exams_physical/exams_physical.module.ngfactory").then(function (m) { return m.ConsultExamsPhysicalModuleNgFactory; }); }, ɵ5 = function () { return import("./pages/diagnosis/diagnosis.module.ngfactory").then(function (m) { return m.ConsultDiagnosisModuleNgFactory; }); }, ɵ6 = function () { return import("./pages/prescription/prescription.module.ngfactory").then(function (m) { return m.ConsultPrescriptionModuleNgFactory; }); }, ɵ7 = function () { return import("./pages/recommendation/recommendation.module.ngfactory").then(function (m) { return m.ConsultRecommendationModuleNgFactory; }); }, ɵ8 = function () { return import("./pages/procedures/procedures.module.ngfactory").then(function (m) { return m.ConsultProceduresModuleNgFactory; }); }, ɵ9 = function () { return import("./pages/references/references.module.ngfactory").then(function (m) { return m.ConsultReferencesModuleNgFactory; }); }, ɵ10 = function () { return import("./pages/exams_laboratory/exams_laboratory.module.ngfactory").then(function (m) { return m.ConsultExamsLaboratoriesModuleNgFactory; }); };
var routes = [
    {
        path: "",
        component: LayoutComponent,
        children: [
            { path: "", redirectTo: "/expediente/nueva-consulta/general/general", pathMatch: "full" },
            {
                path: "general",
                loadChildren: ɵ0,
            },
            {
                path: "antropometria",
                outlet: "secondary",
                loadChildren: ɵ1,
            },
            {
                path: "vitales",
                loadChildren: ɵ2,
            },
            {
                path: "curva_crecimiento",
                outlet: "secondary",
                loadChildren: ɵ3,
            },
            {
                path: "examen_fisico",
                loadChildren: ɵ4,
            },
            {
                path: "diagnostico",
                loadChildren: ɵ5,
            },
            {
                path: "receta",
                loadChildren: ɵ6,
            },
            {
                path: "recomendaciones_notas",
                loadChildren: ɵ7,
            },
            {
                path: "procedimientos",
                loadChildren: ɵ8,
            },
            {
                path: "referencias",
                loadChildren: ɵ9,
            },
            {
                path: "examenes",
                loadChildren: ɵ10,
            },
        ],
    },
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
