import { Routes } from '@angular/router';
import { GeneralComponent } from './general.component';
var routes = [
    {
        path: '',
        component: GeneralComponent
    }
];
var ExpedientGeneralModule = /** @class */ (function () {
    function ExpedientGeneralModule() {
    }
    return ExpedientGeneralModule;
}());
export { ExpedientGeneralModule };
