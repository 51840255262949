import { OnDestroy, OnInit, } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { AppService } from "src/app/services/app.service";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PageService } from "src/app/services/page.service";
import { PipesService } from "src/app/services/pipes.service";
import { ConsultClass } from "../../../../../classes/consult-class";
import { ActivatedRoute } from "@angular/router";
import { GrowthCurveService } from "src/app/services/growth_curve.service";
var PreviewConsultComponent = /** @class */ (function () {
    function PreviewConsultComponent(pageService, endpoint, accountService, activeRoute, catalogsService, growthCurveService, pipesService) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.accountService = accountService;
        this.activeRoute = activeRoute;
        this.catalogsService = catalogsService;
        this.growthCurveService = growthCurveService;
        this.pipesService = pipesService;
        this.data = {
            id: 1,
            consult: new ConsultClass(this.catalogsService, this.pipesService)
        };
        this.avatar_configs = { size: "67.8106pt" };
        this.data.id = activeRoute.snapshot.paramMap.get("id");
        this.carrousel = {
            1: true,
            2: true,
            3: true,
            4: true,
        };
    }
    PreviewConsultComponent.prototype.ngOnInit = function () {
        this.pageService.set_sidebar_3_state({
            expand: true,
            scroll: false,
            show: true,
        });
        this.get_data();
    };
    PreviewConsultComponent.prototype.ngOnDestroy = function () {
        this.pageService.set_sidebar_3_state({
            expand: true,
            scroll: true,
            show: true,
        });
    };
    PreviewConsultComponent.prototype.get_data = function () {
        var _this = this;
        this.endpoint
            .expedient_consult_general_get_consult({ id: this.data.id })
            .subscribe(function (data) {
            _this.data.consult = new ConsultClass(_this.catalogsService, _this.pipesService, data);
            _this.growthCurveService.search_percentil(_this.data.consult.growth_curve, _this.data.consult.patient_gender);
        }, function (error) { return _this.close(); });
    };
    PreviewConsultComponent.prototype.close = function () {
    };
    PreviewConsultComponent.prototype.get_catalogs = function (name, value, text) {
        var _this = this;
        if (value === void 0) { value = "id"; }
        if (text === void 0) { text = "name"; }
        return {
            list: function () { return _this.catalogsService.catalogs[name]; },
            value: value,
            text: text
        };
    };
    PreviewConsultComponent.prototype.get_specialty = function (id) {
        var specialty = this.catalogsService.catalogs.doctors_specialties.find(function (v) { return v.id == id; });
        return specialty ? specialty.name : "";
    };
    PreviewConsultComponent.prototype.get_file_endpoint = function (path, name) {
        return this.endpoint.get_file_endpoint(path, name);
    };
    PreviewConsultComponent.prototype.is_author = function () {
        return (this.data.consult.id_doctor == this.accountService.get_current_state().id);
    };
    return PreviewConsultComponent;
}());
export { PreviewConsultComponent };
