var OverlayComponent = /** @class */ (function () {
    function OverlayComponent() {
        this.message = '';
        this.title = '';
    }
    OverlayComponent.prototype.ngOnChanges = function (changes) {
        if (changes.message) {
            this.message = changes.message.currentValue;
        }
        if (changes.title) {
            this.title = changes.title.currentValue;
        }
        if (changes.show) {
            this.show = changes.show.currentValue;
        }
        if (changes.icon) {
            this.icon = changes.icon.currentValue;
        }
    };
    return OverlayComponent;
}());
export { OverlayComponent };
