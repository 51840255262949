import { LaboratoryService } from '../laboratory.service';
var UpdateExamsLaboratoryComponent = /** @class */ (function () {
    function UpdateExamsLaboratoryComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateExamsLaboratoryComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateExamsLaboratoryComponent;
}());
export { UpdateExamsLaboratoryComponent };
