import * as tslib_1 from "tslib";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import Swal from "sweetalert2";
import { AppService } from "./app.service";
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
var PageService = /** @class */ (function () {
    function PageService(endpoint, modalService) {
        this.endpoint = endpoint;
        this.modalService = modalService;
        this.page_session = "unimed-page-session";
        var stored = localStorage.getItem(this.page_session);
        if (stored)
            stored = JSON.parse(stored);
        this.current_state = {
            profile: stored && stored["profile"] ? stored["profile"] : false,
            expedient: stored && stored["expedient"] ? stored["expedient"] : false,
            consult: stored && stored["consult"] ? stored["consult"] : false,
            searching: stored && stored["searching"] ? stored["searching"] : false,
            sidebar_1: {
                show: stored && stored["sidebar_1"] && stored["sidebar_1"]["show"] ? stored["sidebar_1"]["show"] : true,
                scroll: stored && stored["sidebar_1"] && stored["sidebar_1"]["scroll"] ? stored["sidebar_1"]["scroll"] : true,
                expand: stored && stored["sidebar_1"] && stored["sidebar_1"]["expand"] ? stored["sidebar_1"]["expand"] : false,
            },
            sidebar_2: {
                show: stored && stored["sidebar_2"] && stored["sidebar_2"]["show"] ? stored["sidebar_2"]["show"] : false,
                scroll: stored && stored["sidebar_2"] && stored["sidebar_2"]["scroll"] ? stored["sidebar_2"]["scroll"] : false,
                expand: stored && stored["sidebar_2"] && stored["sidebar_2"]["expand"] ? stored["sidebar_2"]["expand"] : false,
            },
            sidebar_3: {
                show: stored && stored["sidebar_3"] && stored["sidebar_3"]["show"] ? stored["sidebar_3"]["show"] : false,
                scroll: stored && stored["sidebar_3"] && stored["sidebar_3"]["scroll"] ? stored["sidebar_3"]["scroll"] : false,
                expand: stored && stored["sidebar_3"] && stored["sidebar_3"]["expand"] ? stored["sidebar_3"]["expand"] : false,
                shadow: stored && stored["sidebar_3"] && stored["sidebar_3"]["shadow"] ? stored["sidebar_3"]["shadow"] : false,
                background: stored && stored["sidebar_3"] && stored["sidebar_3"]["background"] ? stored["sidebar_3"]["background"] : false,
                breadcrumbs: [
                    {
                        name: "Inicio",
                        active: false,
                    },
                ],
            },
            loading: false,
            timestamp: new Date(),
        };
        this.page_state = new BehaviorSubject(this.current_state);
        this.current = this.page_state.asObservable();
        // this.set_searching(true); // REMOVE
    }
    PageService.prototype.reload = function () {
        this.page_state.timestamp = new Date();
        this.page_state.next(this.current_state);
    };
    PageService.prototype.get_current_state = function () {
        return this.current_state;
    };
    PageService.prototype.set_searching = function (status) {
        this.current_state.searching = status;
        this.page_state.next(this.current_state);
    };
    PageService.prototype.set_sidebar_1_state = function (status) {
        this.current_state.sidebar_1 = status;
        this.page_state.next(this.current_state);
    };
    PageService.prototype.set_sidebar_2_state = function (status) {
        this.current_state.sidebar_2 = status;
        this.page_state.next(this.current_state);
    };
    PageService.prototype.set_sidebar_3_state = function (status) {
        this.current_state.sidebar_3 = tslib_1.__assign({}, status, { background: this.current_state.sidebar_3.background, shadow: this.current_state.sidebar_3.shadow });
        this.page_state.next(this.current_state);
    };
    PageService.prototype.sidebar_3 = function (callback) {
        callback(this.current_state.sidebar_3);
        // this.page_state.next(this.current_state);
    };
    PageService.prototype.get_account_data = function () { };
    PageService.prototype.set_doctor_data = function (data) {
        if (data) {
            this.page_state.next(this.current_state);
        }
    };
    PageService.prototype.set_consult = function (status) {
        this.current_state.consult = status;
        if (status) {
            this.current_state.sidebar_2.show = true;
            this.current_state.sidebar_3.show = true;
            this.current_state.sidebar_3.background = false;
            this.current_state.sidebar_3.shadow = true;
        }
        this.save();
        this.page_state.next(this.current_state);
    };
    PageService.prototype.set_expedient = function (status) {
        // localStorage.setItem("ExpedientVersion", JSON.stringify({ method: "update" }));
        this.current_state.expedient = status;
        if (status) {
            this.current_state.sidebar_2.show = true;
            this.current_state.sidebar_3.show = true;
            this.current_state.sidebar_3.background = true;
        }
        this.current_state.sidebar_3.shadow = false;
        this.save();
        this.page_state.next(this.current_state);
    };
    PageService.prototype.set_profile = function (status) {
        this.current_state.profile = status;
        if (status) {
            this.current_state.sidebar_2.show = false;
            this.current_state.sidebar_3.show = true;
            this.current_state.sidebar_3.background = true;
        }
        this.current_state.sidebar_3.shadow = false;
        this.save();
        this.page_state.next(this.current_state);
    };
    PageService.prototype.set_loading = function (status) {
        this.current_state.loading = status;
        this.page_state.next(this.current_state);
    };
    PageService.prototype.get_loading = function () {
        return this.current_state.loading;
    };
    PageService.prototype.save = function () {
        localStorage.setItem(this.page_session, JSON.stringify(this.current_state));
    };
    PageService.prototype.openModal = function (content, size, callback) {
        if (size === void 0) { size = "lg"; }
        if (callback === void 0) { callback = function (callback_data) { }; }
        var options = {
            backdrop: "static",
            keyboard: true,
            size: size,
            beforeDismiss: function () {
                callback();
                return true;
            },
        };
        this.modalService.open(content, options);
    };
    PageService.prototype.closeModal = function (data) {
        this.modalService.dismissAll(data);
    };
    PageService.prototype.cancel_consult = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        Swal.fire({
            title: "Cancelar Consulta",
            html: "¿Está seguro de cancelar la consulta?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, cancelar",
            cancelButtonText: "No",
        }).then(function (result) {
            if (result.value == true) {
                localStorage.removeItem("inConsult");
                callback();
            }
        });
    };
    PageService.prototype.close_consult = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        Swal.fire({
            title: "Guardar y Cerrar Consulta",
            html: "¿Está seguro de guardar y cerrar la consulta?",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, guardar y cerrar",
            cancelButtonText: "No",
        }).then(function (result) {
            if (result.value == true) {
                localStorage.removeItem("inConsult");
                callback();
            }
        });
    };
    PageService.prototype.cancel_new_patient = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        Swal.fire({
            title: "Cancelar Creación de Paciente",
            html: "¿Está seguro de cancelar la creación del paciente actual?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, cancelar",
            cancelButtonText: "No",
        }).then(function (result) {
            if (result.value == true) {
                localStorage.removeItem("inNewPatient");
                callback();
            }
        });
    };
    PageService.prototype.cancel_expedient = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        Swal.fire({
            title: "Cancelar Actualización de Paciente",
            html: "¿Está seguro de cancelar la actualización del paciente actual?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, cancelar",
            cancelButtonText: "No",
        }).then(function (result) {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                callback();
            }
        });
    };
    PageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PageService_Factory() { return new PageService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.NgbModal)); }, token: PageService, providedIn: "root" });
    return PageService;
}());
export { PageService };
