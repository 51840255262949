import { InstitutionsService } from '../institutions.service';
var UpdateInstitutionsComponent = /** @class */ (function () {
    function UpdateInstitutionsComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateInstitutionsComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateInstitutionsComponent;
}());
export { UpdateInstitutionsComponent };
