import * as tslib_1 from "tslib";
import { CustomClass } from "./custom-class";
;
;
var GeneralClass = /** @class */ (function (_super) {
    tslib_1.__extends(GeneralClass, _super);
    function GeneralClass(data) {
        var _this = _super.call(this) || this;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    GeneralClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.reason = data.reason;
        this.description = data.description;
    };
    GeneralClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.reason = "";
        this.description = "";
    };
    GeneralClass.prototype.format = function () {
        if (this.reason)
            this.reason = this.reason.trim();
        if (this.description)
            this.description = this.description.trim();
        return this;
    };
    GeneralClass.prototype.valid = function () {
        if (this.reason.trim().length > 0) {
            return true;
        }
        return false;
    };
    GeneralClass.prototype.to_string = function () {
        var data = this.format();
        return data;
    };
    GeneralClass.prototype.raw = function () {
        return {
            id: this.id,
            reason: this.reason,
            description: this.description,
        };
    };
    return GeneralClass;
}(CustomClass));
export { GeneralClass };
