import { EventEmitter, OnDestroy, OnInit, } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { AppService } from "src/app/services/app.service";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PageService } from "src/app/services/page.service";
import { PipesService } from "src/app/services/pipes.service";
import { ConsultClass } from "../../../../classes/consult-class";
var ShowConsultComponent = /** @class */ (function () {
    function ShowConsultComponent(pageService, endpoint, accountService, catalogsService, pipesService) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.accountService = accountService;
        this.catalogsService = catalogsService;
        this.pipesService = pipesService;
        this.close_event = new EventEmitter();
        this.avatar_configs = {
            size: "6rem",
            font_size: "4.3rem",
        };
    }
    ShowConsultComponent.prototype.ngOnInit = function () {
        this.pageService.set_sidebar_3_state({
            expand: true,
            scroll: false,
            show: true,
        });
        this.get_data();
    };
    ShowConsultComponent.prototype.ngOnDestroy = function () {
        this.pageService.set_sidebar_3_state({
            expand: true,
            scroll: true,
            show: true,
        });
    };
    ShowConsultComponent.prototype.get_data = function () {
        var _this = this;
        this.endpoint
            .expedient_consult_general_get_consult({ id: this.data.id })
            .subscribe(function (data) {
            _this.data.consult = new ConsultClass(_this.catalogsService, _this.pipesService, data);
            _this.data.consult_l = _this.data.consult.to_string();
        }, function (error) { return _this.close(); });
    };
    ShowConsultComponent.prototype.close = function () {
        this.close_event.emit("");
    };
    ShowConsultComponent.prototype.get_specialty = function (id) {
        var specialty = this.catalogsService.catalogs.doctors_specialties.find(function (v) { return v.id == id; });
        return specialty ? specialty.name : "";
    };
    ShowConsultComponent.prototype.get_file_endpoint = function (path, name) {
        return this.endpoint.get_file_endpoint(path, name);
    };
    ShowConsultComponent.prototype.is_author = function () {
        return (this.data.consult.id_doctor == this.accountService.get_current_state().id);
    };
    return ShowConsultComponent;
}());
export { ShowConsultComponent };
