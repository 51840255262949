import * as tslib_1 from "tslib";
import { CustomClass } from "./custom-class";
;
;
var ReferencesClass = /** @class */ (function (_super) {
    tslib_1.__extends(ReferencesClass, _super);
    function ReferencesClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    ReferencesClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_patient = data.id_patient;
        this.id_consult = data.id_consult;
        this.id_doctors_specialty = data.id_doctors_specialty;
        this.place = data.place;
        this.doctor = data.doctor;
        this.doctor_phone = data.doctor_phone;
        this.cause = data.cause;
        this.created_at = data.created_at;
        this.created_by = data.created_by;
    };
    ReferencesClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_patient = null;
        this.id_consult = null;
        this.id_doctors_specialty = null;
        this.place = "";
        this.doctor = "";
        this.doctor_phone = "";
        this.cause = "";
        this.created_at = null;
        this.created_by = null;
    };
    ReferencesClass.prototype.format = function () {
        return this;
    };
    ReferencesClass.prototype.valid = function () {
        return true;
    };
    ReferencesClass.prototype.to_string = function () {
        var _this = this;
        var doctors_specialties = this.catalogsService.catalogs.doctors_specialties.find(function (v) { return v.id == _this.id_doctors_specialty; });
        var data = {
            doctors_specialties: doctors_specialties ? doctors_specialties.name : "",
            place: this.place,
            doctor: this.doctor,
            doctor_phone: this.doctor_phone,
            cause: this.cause,
        };
        return data;
    };
    ReferencesClass.prototype.raw = function () {
        return {
            id: this.id,
            id_patient: this.id_patient,
            id_consult: this.id_consult,
            id_doctors_specialty: this.id_doctors_specialty,
            place: this.place,
            doctor: this.doctor,
            doctor_phone: this.doctor_phone,
            cause: this.cause,
            created_at: this.created_at,
            created_by: this.created_by,
        };
    };
    return ReferencesClass;
}(CustomClass));
export { ReferencesClass };
