import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { ConsultGeneralService } from '../../new_consult/general/consult.service';
var ShowDateComponent = /** @class */ (function () {
    function ShowDateComponent(endpoint, toastr, patientServiceService, pageService, consult) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.patientServiceService = patientServiceService;
        this.pageService = pageService;
        this.consult = consult;
        this.data = {
            description: "",
            fecha_date: "",
            hour_date: "",
            duration: "",
            id_date: "",
        };
    }
    ShowDateComponent.prototype.ngOnInit = function () {
        this.form_configs();
    };
    ShowDateComponent.prototype.form_configs = function () {
        this.form_config = {
            pretty_view: function () { return true; },
            disabled: function () { return true; },
            submit: function () { },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "description",
                            type: "textarea",
                            label: "Descripción",
                            placeholder: function () { return ""; },
                            col_class: "col-md-12",
                            rows: 2.5,
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "fecha_date",
                            type: "calendar",
                            label: "Fecha",
                            placeholder: function () { return "#######"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "hour_date",
                            type: "text",
                            label: "Hora",
                            placeholder: function () { return ""; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "duration",
                            type: "text",
                            label: "duración",
                            placeholder: function () { return ""; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                    ],
                },
            ]
        };
    };
    ShowDateComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowDateComponent;
}());
export { ShowDateComponent };
