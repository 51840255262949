import { OnDestroy, OnInit } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
var BackgroundComponent = /** @class */ (function () {
    function BackgroundComponent(expedientService) {
        this.expedientService = expedientService;
        this.view = 0;
    }
    BackgroundComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) { return _this.expedient_state = state; });
    };
    BackgroundComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
    };
    return BackgroundComponent;
}());
export { BackgroundComponent };
