import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import { PageService } from 'src/app/services/page.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { DatatableComponent } from 'src/app/shared/ui/datatable/datatable.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';
import { InstitutionsService } from './institutions.service';
var InstitutionsComponent = /** @class */ (function () {
    function InstitutionsComponent(endpoint, expedientService, pageService, moduleService, sweel, toastr) {
        this.endpoint = endpoint;
        this.expedientService = expedientService;
        this.pageService = pageService;
        this.moduleService = moduleService;
        this.sweel = sweel;
        this.toastr = toastr;
        this._change_view_event = new EventEmitter();
        this.moduleService.init_list();
        this.datatable_config();
    }
    InstitutionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            _this.moduleService.reload_list(_this.datatable.filters);
        });
        this.list_subscription = this.moduleService.obv_list.subscribe(function (state) { return _this.list_state = state; });
        this.page_subscription = this.pageService.current.subscribe(function (state) { return _this.page_state = state; });
    };
    InstitutionsComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.list_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
    };
    InstitutionsComponent.prototype.datatable_config = function () {
        this.datatable = {
            configs: {
                title: "Instituciones",
                //icon: "fa-duotone fa-address-book",
                paginate: false,
                search: false
            },
            filters: {
                limit: 20,
                skip: 0
            },
            headers: [
                {
                    label: "Nombre",
                    name: "institution_name",
                    order_by: "institution_name"
                },
                {
                    label: "Cargo",
                    name: "institutions_role_name",
                    order_by: "institutions_role_name"
                },
                {
                    label: "Área",
                    name: "institutions_area_name",
                    order_by: "institutions_area_name"
                },
                {
                    label: "Grado",
                    name: "institutions_grade_name",
                    order_by: "institutions_grade_name"
                },
                {
                    label: "Sección",
                    name: "institutions_grades_section_name",
                    order_by: "institutions_grades_section_name"
                },
            ],
            events: [
                {
                    name: "Detalles",
                    icon: "fa-duotone fa-file-circle-info text-info"
                },
                {
                    name: "Modificar",
                    icon: "fa-duotone fa-edit text-warning"
                },
                {
                    name: "Eliminar",
                    icon: "fa-duotone fa-trash-alt text-danger",
                },
            ]
        };
    };
    InstitutionsComponent.prototype.on_update = function () {
        if (this.datatable_ref)
            this.datatable_ref.update();
    };
    InstitutionsComponent.prototype.update = function (data) {
        if (this.search_button_ref)
            data.search_word = this.search_button_ref.data;
        this.moduleService.reload_list(data);
    };
    InstitutionsComponent.prototype.events = function (data) {
        var _this = this;
        this.selected_item = data.item;
        if (data.name == "Detalles" || data.name == "row_click") {
            this.expedientService.change_method("show");
            this.moduleService.init(this.selected_item);
            this.pageService.openModal(this.showModal, "lg");
        }
        if (data.name == "Eliminar") {
            this.moduleService.init(this.selected_item);
            this.sweel.confirm("Eliminar Institución", "Por favor confirme que desea eliminar la institución.", "warning").then(function (res) {
                if (res) {
                    _this.moduleService.delete();
                }
            });
        }
        if (data.name == "Modificar") {
            this.expedientService.change_method("update");
            this.moduleService.init(this.selected_item);
            this.pageService.openModal(this.updateModal, "lg");
        }
    };
    InstitutionsComponent.prototype.create = function () {
        this.pageService.openModal(this.createModal, "lg");
    };
    return InstitutionsComponent;
}());
export { InstitutionsComponent };
