var v = 11;
export var dbConfig = {
    name: "unimed-v" + v,
    version: v,
    objectStoresMeta: [
        {
            store: "pathologies",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "allergies_reactions_levels",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "allergies_reactions_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "bloods",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "chat_conversation_end_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "chat_message_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "civil_statuses",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "consults_statuses",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "consults_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "countries",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "departments",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "doctors_patients_levels",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "doctors_specialties",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "educations",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "exams_names",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "exams_names_2",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "exams_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "file_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "genders",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "institutions_areas",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "institutions_roles",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "institutions_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "institutions_users_roles",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "medicines_concentrations",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "medicines_days",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "medicines_frecuencies",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "medicines_presentations",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "medicines_ways",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "procedures_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "towns",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_breathing_rate",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_diastolic_pressure",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_eye_vision",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_glasgow_eye",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_glasgow_move",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_glasgow_talk",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_heart_rate",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_height",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_IMC",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_pulse",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_sistolic_pressure",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_temp",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_weight",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "units_medicine_dosage",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "users_roles",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "users_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "tf",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "profiles_educations_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "profiles_educations_study_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "profiles_educations_modes",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "profiles_educations_honors",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "profiles_educations_places",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "profiles_educations_specialties",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "profiles_educations_subspecialties",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "profiles_experiences_types",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "specialties_areas",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "vaccines",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "vaccines_dosis_statuses",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "vaccines_people_groups",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
        {
            store: "relationships",
            storeConfig: {
                keyPath: "id",
                autoIncrement: false,
            },
            storeSchema: [],
        },
    ],
};
var AppWebDBModule = /** @class */ (function () {
    function AppWebDBModule() {
    }
    return AppWebDBModule;
}());
export { AppWebDBModule };
