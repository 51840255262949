import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ConsultGeneralService } from '../general/consult.service';
var ConsultMinimizedHeaderComponent = /** @class */ (function () {
    function ConsultMinimizedHeaderComponent(endpoint, consultService) {
        this.endpoint = endpoint;
        this.consultService = consultService;
    }
    ConsultMinimizedHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.consult_subscription = this.consultService.current.subscribe(function (state) {
            _this.consult_state = state;
        });
    };
    ConsultMinimizedHeaderComponent.prototype.ngOnDestroy = function () {
        this.consult_subscription.unsubscribe();
    };
    ConsultMinimizedHeaderComponent.prototype.maximize = function () {
        this.endpoint.redirect_to("/expediente/nueva-consulta");
    };
    ConsultMinimizedHeaderComponent.prototype.close = function () {
        this.consultService.remove_storage();
    };
    ConsultMinimizedHeaderComponent.prototype.has_consult = function () {
        return this.consultService.get_storage();
    };
    return ConsultMinimizedHeaderComponent;
}());
export { ConsultMinimizedHeaderComponent };
