import * as tslib_1 from "tslib";
import { CustomListClass } from "./custom-list-class";
import { TreatmentClass } from "./treatment-class";
import { PrescriptionClass } from "./prescription-class";
import { CustomClass } from "./custom-class";
var DiagnosisClass = /** @class */ (function (_super) {
    tslib_1.__extends(DiagnosisClass, _super);
    function DiagnosisClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    DiagnosisClass.prototype.overwrite = function (data) {
        var _this = this;
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_consult = data.id_consult;
        this.id_patient = data.id_patient;
        this.name = data.name;
        this.icd = data.icd;
        this.date = data.date;
        this.secure = data.secure;
        this.treatment = data.treatment;
        this.chronic = data.chronic == 1 ? [1] : [];
        if (data.prescription_list)
            this.prescription_list = new CustomListClass(data.prescription_list.map(function (prescription) { return new PrescriptionClass(_this.catalogsService, prescription); }));
        else
            this.prescription_list = new CustomListClass();
        if (data.treatment_list)
            this.treatment_list = new CustomListClass(data.treatment_list.map(function (treatment) { return new TreatmentClass(_this.catalogsService, treatment); }));
        else
            this.treatment_list = new CustomListClass();
    };
    DiagnosisClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_consult = null;
        this.id_patient = null;
        this.name = "";
        this.icd = "";
        this.date = new Date();
        this.secure = true;
        this.treatment = "";
        this.chronic = [];
        this.prescription_list = new CustomListClass();
        this.treatment_list = new CustomListClass();
    };
    DiagnosisClass.prototype.format = function () {
        return this;
    };
    DiagnosisClass.prototype.valid = function () {
        return true;
    };
    DiagnosisClass.prototype.to_string = function () {
        var data = {};
        return data;
    };
    DiagnosisClass.prototype.raw = function () {
        return {
            id: this.id,
            id_consult: this.id_consult,
            id_patient: this.id_patient,
            name: this.name,
            icd: this.icd,
            date: this.date,
            secure: this.secure,
            treatment: this.treatment,
            chronic: this.chronic,
            prescription_list: this.prescription_list.raw(),
            treatment_list: this.treatment_list.raw(),
        };
    };
    return DiagnosisClass;
}(CustomClass));
export { DiagnosisClass };
