import { OnDestroy, OnInit } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
var RelationsComponent = /** @class */ (function () {
    function RelationsComponent(expedientService) {
        this.expedientService = expedientService;
        this.view = 0;
    }
    RelationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) { return _this.expedient_state = state; });
    };
    RelationsComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
    };
    return RelationsComponent;
}());
export { RelationsComponent };
