import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from '../../services/page.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/page.service";
import * as i2 from "../../services/app.service";
import * as i3 from "../../services/account.service";
var OutNewPatientGuard = /** @class */ (function () {
    function OutNewPatientGuard(pageService, endpoint, accountService) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.accountService = accountService;
    }
    OutNewPatientGuard.prototype.canDeactivate = function (a, b, c, d) {
        var _this = this;
        var inNewPatient = localStorage.getItem("inNewPatient");
        if (inNewPatient) {
            this.pageService.cancel_new_patient(function () {
                _this.endpoint.redirect_to(d.url);
            });
            return false;
        }
        return true;
    };
    OutNewPatientGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OutNewPatientGuard_Factory() { return new OutNewPatientGuard(i0.ɵɵinject(i1.PageService), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.AccountService)); }, token: OutNewPatientGuard, providedIn: "root" });
    return OutNewPatientGuard;
}());
export { OutNewPatientGuard };
