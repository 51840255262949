import { ExpedientService } from 'src/app/services/expedient.service';
import { InstitutionsService } from '../institutions.service';
var CreateInstitutionsComponent = /** @class */ (function () {
    function CreateInstitutionsComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateInstitutionsComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateInstitutionsComponent;
}());
export { CreateInstitutionsComponent };
