import * as tslib_1 from "tslib";
import { CustomClass } from "./custom-class";
;
;
var ExamsPhysicalClass = /** @class */ (function (_super) {
    tslib_1.__extends(ExamsPhysicalClass, _super);
    function ExamsPhysicalClass(data) {
        var _this = _super.call(this) || this;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    ExamsPhysicalClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id_consult = data.id_consult;
        this.neurological = data.neurological;
        this.eyes = data.eyes;
        this.ears = data.ears;
        this.nose = data.nose;
        this.mouth = data.mouth;
        this.neck = data.neck;
        this.cardiac = data.cardiac;
        this.pulmonar = data.pulmonar;
        this.abdomen = data.abdomen;
        this.genitals = data.genitals;
        this.extremities = data.extremities;
        this.others = data.others;
    };
    ExamsPhysicalClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id_consult = null;
        this.neurological = "";
        this.eyes = "";
        this.ears = "";
        this.nose = "";
        this.mouth = "";
        this.neck = "";
        this.cardiac = "";
        this.pulmonar = "";
        this.abdomen = "";
        this.genitals = "";
        this.extremities = "";
        this.others = "";
    };
    ExamsPhysicalClass.prototype.format = function () {
        return this;
    };
    ExamsPhysicalClass.prototype.valid = function () {
        return true;
    };
    ExamsPhysicalClass.prototype.to_string = function () {
        var data;
        data = {
            neurological: this.neurological,
            eyes: this.eyes,
            ears: this.ears,
            nose: this.nose,
            mouth: this.mouth,
            neck: this.neck,
            cardiac: this.cardiac,
            pulmonar: this.pulmonar,
            abdomen: this.abdomen,
            genitals: this.genitals,
            extremities: this.extremities,
            others: this.others,
        };
        return data;
    };
    ExamsPhysicalClass.prototype.raw = function () {
        return {
            id_consult: this.id_consult,
            neurological: this.neurological,
            eyes: this.eyes,
            ears: this.ears,
            nose: this.nose,
            mouth: this.mouth,
            neck: this.neck,
            cardiac: this.cardiac,
            pulmonar: this.pulmonar,
            abdomen: this.abdomen,
            genitals: this.genitals,
            extremities: this.extremities,
            others: this.others,
        };
    };
    return ExamsPhysicalClass;
}(CustomClass));
export { ExamsPhysicalClass };
