import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from '../../services/page.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/page.service";
import * as i2 from "../../services/app.service";
import * as i3 from "../../services/account.service";
var OutExpedientGuard = /** @class */ (function () {
    function OutExpedientGuard(pageService, endpoint, accountService) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.accountService = accountService;
    }
    OutExpedientGuard.prototype.canDeactivate = function (a, b, c, d) {
        var _this = this;
        var inExpedient = localStorage.getItem("inExpedient");
        if (inExpedient) {
            this.pageService.cancel_expedient(function () {
                _this.endpoint.redirect_to(d.url);
            });
            return false;
        }
        return true;
    };
    OutExpedientGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OutExpedientGuard_Factory() { return new OutExpedientGuard(i0.ɵɵinject(i1.PageService), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.AccountService)); }, token: OutExpedientGuard, providedIn: "root" });
    return OutExpedientGuard;
}());
export { OutExpedientGuard };
