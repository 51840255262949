import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { DatatableComponent } from 'src/app/shared/ui/datatable/datatable.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';
var MedicinesComponent = /** @class */ (function () {
    function MedicinesComponent(endpoint, patientService, pageService, toast, sweel) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.pageService = pageService;
        this.toast = toast;
        this.sweel = sweel;
        this.data = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
        this.datatable_config();
    }
    MedicinesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patient_subscription = this.patientService.patient_data_subscription.subscribe(function (data) { return _this.patient_data = data; });
        this.page_subscription = this.pageService.current.subscribe(function (state) { return _this.page_state = state; });
    };
    MedicinesComponent.prototype.datatable_config = function () {
        this.datatable = {
            configs: {
                title: "Medicinas",
                //icon: "fa-duotone fa-address-book",
                paginate: false,
                search: false
            },
            filters: {
                limit: 20,
                skip: 0
            },
            headers: [
                {
                    label: "Nombre",
                    name: "name",
                    order_by: "name"
                },
                {
                    label: "Cantidad",
                    name: "amount",
                    order_by: "amount"
                },
                {
                    label: "Presentación",
                    name: "presentation",
                    order_by: "presentation",
                },
                {
                    label: "Concentración",
                    name: "concentration",
                    order_by: "concentration",
                },
                {
                    label: "Vía",
                    name: "way",
                    order_by: "way",
                },
                {
                    label: "Frecuencia",
                    name: "frecuency_parsed",
                    order_by: "frecuency",
                },
                {
                    label: "Duración",
                    name: "days_parsed",
                    order_by: "days",
                },
                {
                    label: "Estado",
                    name: "verification",
                    order_by: "verification",
                }
            ],
            events: [
                {
                    name: "Detalles",
                    icon: "fa-duotone fa-file-circle-info text-info"
                },
                {
                    name: "Modificar",
                    icon: "fa-duotone fa-edit text-warning"
                },
                {
                    name: "Eliminar",
                    icon: "fa-duotone fa-trash-alt text-danger",
                },
            ]
        };
    };
    MedicinesComponent.prototype.on_update = function () {
        if (this.datatable_ref)
            this.datatable_ref.update();
    };
    MedicinesComponent.prototype.update = function (data) {
        var _this = this;
        if (this.patient_data.general.id_user) {
            if (this.search_button_ref)
                data.search_word = this.search_button_ref.data;
            var load = tslib_1.__assign({ id_patient: this.patient_data.general.id_user }, data);
            this.endpoint.get_medicines_prescription(load).subscribe(function (data) { return _this.data = data; }, function (err) { return console.error(err); });
        }
    };
    MedicinesComponent.prototype.events = function (data) {
        var _this = this;
        this.selected_item = data.item;
        if (data.name == "Detalles" || data.name == "row_click") {
            this.pageService.openModal(this.showModal, "lg");
        }
        if (data.name == "Eliminar") {
            this.sweel.confirm("Eliminar Medicina", "Por favor confirme que desea eliminar la medicina seleccionada?", "warning").then(function (res) {
                if (res) {
                    _this.delete(data.item.id);
                }
            });
        }
        if (data.name == "Modificar") {
            this.selected_item = data.item;
            this.pageService.openModal(this.updateModal, "lg");
        }
    };
    MedicinesComponent.prototype.delete = function (id) {
        var _this = this;
        var params = {
            id: id
        };
        this.endpoint.delete_medicines_prescription(params).subscribe(function (res) {
            if (res) {
                _this.toast.success("Operación Exitosa", "Medicina eliminada correctamente.");
                _this.on_update();
            }
        });
    };
    MedicinesComponent.prototype.create = function () {
        this.pageService.openModal(this.createModal, "lg");
    };
    return MedicinesComponent;
}());
export { MedicinesComponent };
