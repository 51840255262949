import * as tslib_1 from "tslib";
import { CustomClass } from "./custom-class";
;
;
var AnthropometriesClass = /** @class */ (function (_super) {
    tslib_1.__extends(AnthropometriesClass, _super);
    function AnthropometriesClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    AnthropometriesClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id_consult = data.id_consult;
        this.weight = data.weight;
        this.id_weight_unit = data.id_weight_unit ? data.id_weight_unit : "1";
        this.height = data.height;
        this.id_height_unit = data.id_height_unit ? data.id_height_unit : "1";
        this.IMC = (+data.IMC).toFixed(2) + "";
        this.id_imc_unit = "1";
    };
    AnthropometriesClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id_consult = null;
        this.weight = "";
        this.id_weight_unit = "1";
        this.height = "";
        this.id_height_unit = "1";
        this.IMC = "";
        this.id_imc_unit = "1";
    };
    AnthropometriesClass.prototype.format = function () {
        this.weight = this.weight.replace(/,/g, "");
        this.height = this.height.replace(/,/g, "");
        this.IMC = this.IMC.replace(/,/g, "");
        return this;
    };
    AnthropometriesClass.prototype.valid = function () {
        return true;
    };
    AnthropometriesClass.prototype.to_string = function () {
        var _this = this;
        var data = this;
        var weight = this.catalogsService.catalogs.units_weight.find(function (v) { return v.id == _this.id_weight_unit; });
        var height = this.catalogsService.catalogs.units_height.find(function (v) { return v.id == _this.id_height_unit; });
        if (data.weight)
            data.weight += " " + (weight ? weight.code : "");
        if (data.height)
            data.height += " " + (height ? height.code : "");
        return data;
    };
    AnthropometriesClass.prototype.get_ideal_weight = function (value, id) {
        var element = this.catalogsService.catalogs.units_weight.find(function (v) { return v.id == id; });
        return element ? value * element.kg_conv : null;
    };
    AnthropometriesClass.prototype.get_ideal_height = function (value, id) {
        var element = this.catalogsService.catalogs.units_height.find(function (v) { return v.id == id; });
        return element ? value * element.m_conv : null;
    };
    AnthropometriesClass.prototype.raw = function () {
        return {
            id_consult: this.id_consult,
            weight: this.weight,
            id_weight_unit: this.id_weight_unit,
            height: this.height,
            id_height_unit: this.id_height_unit,
            IMC: this.IMC,
        };
    };
    AnthropometriesClass.prototype.calculate_IMC = function () {
        if (this.height && this.weight) {
            var height = this.get_ideal_height(parseFloat(this.height.replace(/,/g, "")), this.id_height_unit);
            var weight = this.get_ideal_weight(parseFloat(this.weight.replace(/,/g, "")), this.id_weight_unit);
            this.IMC = (weight / (height * height)).toFixed(2) + "";
        }
        else {
            this.IMC = "";
        }
    };
    return AnthropometriesClass;
}(CustomClass));
export { AnthropometriesClass };
