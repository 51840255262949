import Swal from 'sweetalert2';
var AlertService = /** @class */ (function () {
    function AlertService() {
    }
    AlertService.prototype.alert_message = function (status, error) {
        if (status > 0 && status != 500 && error.title && error.message) {
            this.alert_error(error.title, error.message);
        }
        else if (status == 500) {
            this.alert_internal_server_error(error.title, error.message);
        }
        else {
            this.alert_conexion();
        }
    };
    AlertService.prototype.alert_error = function (title, message) {
        Swal.fire({
            title: title,
            text: message,
            type: "error",
            allowOutsideClick: true,
            allowEscapeKey: true,
        });
    };
    AlertService.prototype.alert_conexion = function () {
        Swal.fire({
            title: "Error de Conexión",
            text: "Error al acceder al servidor. Revise su conexión de internet o inténtelo más tarde.",
            type: "warning",
            allowOutsideClick: true,
            allowEscapeKey: true,
        });
    };
    AlertService.prototype.alert_internal_server_error = function (title, message) {
        Swal.fire({
            title: title,
            text: message,
            type: "warning",
            allowOutsideClick: true,
            allowEscapeKey: true,
        });
    };
    AlertService.prototype.alert_aplication_error = function (title) {
        Swal.fire({
            title: title,
            text: "Error interno del aplicativo.  Revise su conexión de internet o inténtelo más tarde.",
            type: "warning",
            allowOutsideClick: true,
            allowEscapeKey: true,
        });
    };
    AlertService.prototype.alert_success = function (title, message) {
        Swal.fire({
            title: title,
            text: message,
            type: "success",
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };
    AlertService.prototype.option_alert = function (title, message, button) {
        return Swal.fire({
            title: '<i class="fa-duotone fa-exclamation-triangle text-danger"></i><br>',
            html: "<span class='Swal-title'>" + title + "</span><br><span class='Swal-text'>" + message + "</span><br>",
            width: "400px",
            showCancelButton: true,
            confirmButtonColor: '#D32C53',
            cancelButtonColor: '#57889c',
            confirmButtonText: button,
            cancelButtonText: 'No',
            allowOutsideClick: false,
            allowEscapeKey: true
        });
    };
    AlertService.prototype.option_alert_upload = function (title, message, button) {
        return Swal.fire({
            title: '<i class="fa-duotone fa-upload text-success"></i><br>',
            html: "<span class='Swal-title'>" + title + "</span><br><span class='Swal-text'>" + message + "</span><br>",
            width: "400px",
            showCancelButton: true,
            confirmButtonColor: '#02BC77',
            cancelButtonColor: '#57889c',
            confirmButtonText: button,
            cancelButtonText: 'No',
            allowOutsideClick: false,
            allowEscapeKey: true
        });
    };
    AlertService.prototype.alert_multiple_error = function (title, message, errors) {
        var errors_string = "";
        for (var i = 0; i < errors.length; i++) {
            errors_string = errors_string + "<br>- " + errors[i];
        }
        Swal.fire({
            title: title,
            html: "<span class='Swal-text'>" + message + "</span><br>" + errors_string,
            type: "error",
            allowOutsideClick: true,
            allowEscapeKey: true,
        });
    };
    return AlertService;
}());
export { AlertService };
