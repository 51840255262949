import * as tslib_1 from "tslib";
import { CustomClass } from "./custom-class";
;
;
var VitalsClass = /** @class */ (function (_super) {
    tslib_1.__extends(VitalsClass, _super);
    function VitalsClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    VitalsClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id_consult = data.id_consult;
        this.glasgow_total = data.glasgow_total;
        this.glasgow_eye = data.glasgow_eye;
        this.glasgow_move = data.glasgow_move;
        this.glasgow_talk = data.glasgow_talk;
        this.pulse = data.pulse;
        this.id_pulse_unit = data.id_pulse_unit ? data.id_pulse_unit : "1";
        this.sat02 = data.sat02;
        this.temp = data.temp ? data.temp : "";
        this.sistolic_pressure = data.sistolic_pressure;
        this.diastolic_pressure = data.diastolic_pressure;
        this.PAM = data.PAM;
        this.id_pam_unit = "1";
        this.id_sistolic_pressure_unit = data.id_sistolic_pressure_unit ? data.id_sistolic_pressure_unit : "1";
        this.id_diastolic_pressure_unit = data.id_diastolic_pressure_unit ? data.id_diastolic_pressure_unit : "1";
        this.id_pressure_pulse_unit = data.id_pressure_pulse_unit ? data.id_pressure_pulse_unit : "1";
        this.id_temp_unit = data.id_temp_unit ? data.id_temp_unit : "1";
        this.id_heart_rate_unit = data.id_heart_rate_unit ? data.id_heart_rate_unit : "1";
        this.id_breathing_rate_unit = data.id_breathing_rate_unit ? data.id_breathing_rate_unit : "1";
        this.pressure_pulse = data.pressure_pulse;
        this.heart_rate = data.heart_rate;
        this.breathing_rate = data.breathing_rate;
        this.eye_vision_right = data.eye_vision_right;
        this.eye_vision_left = data.eye_vision_left;
        this.toogle_pressure = true;
        this.glasgow_calc();
        this.pressure_calc();
    };
    VitalsClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id_consult = null;
        this.glasgow_total = "";
        this.glasgow_eye = 0;
        this.glasgow_move = 0;
        this.glasgow_talk = 0;
        this.pulse = "";
        this.id_pulse_unit = "1";
        this.sat02 = "";
        this.temp = "";
        this.sistolic_pressure = "";
        this.diastolic_pressure = "";
        this.PAM = "";
        this.id_pam_unit = "1";
        this.id_sistolic_pressure_unit = "1";
        this.id_diastolic_pressure_unit = "1";
        this.id_pressure_pulse_unit = "1";
        this.id_temp_unit = "1";
        this.id_heart_rate_unit = "1";
        this.id_breathing_rate_unit = "1";
        this.pressure_pulse = "";
        this.heart_rate = "";
        this.breathing_rate = "";
        this.eye_vision_right = "";
        this.eye_vision_left = "";
        this.toogle_pressure = true;
    };
    VitalsClass.prototype.format = function () {
        return this;
    };
    VitalsClass.prototype.valid = function () {
        return true;
    };
    VitalsClass.prototype.to_string = function () {
        var _this = this;
        this.glasgow_calc();
        this.pressure_calc();
        var heart_rate = this.catalogsService.catalogs.units_heart_rate.find(function (v) { return v.id == _this.id_heart_rate_unit; });
        var breathing_rate = this.catalogsService.catalogs.units_breathing_rate.find(function (v) { return v.id == _this.id_breathing_rate_unit; });
        var pulse = this.catalogsService.catalogs.units_pulse.find(function (v) { return v.id == _this.id_pulse_unit; });
        var temp = this.catalogsService.catalogs.units_temp.find(function (v) { return v.id == _this.id_temp_unit; });
        var sistolic_pressure = this.catalogsService.catalogs.units_sistolic_pressure.find(function (v) { return v.id == _this.id_sistolic_pressure_unit; });
        var diastolic_pressure = this.catalogsService.catalogs.units_diastolic_pressure.find(function (v) { return v.id == _this.id_diastolic_pressure_unit; });
        var pressure_pulse = this.catalogsService.catalogs.units_sistolic_pressure.find(function (v) { return v.id == _this.id_pressure_pulse_unit; });
        var glasgow_eye = this.catalogsService.catalogs.units_glasgow_eye.find(function (v) { return v.id == _this.glasgow_eye; });
        var glasgow_move = this.catalogsService.catalogs.units_glasgow_move.find(function (v) { return v.id == _this.glasgow_move; });
        var glasgow_talk = this.catalogsService.catalogs.units_glasgow_talk.find(function (v) { return v.id == _this.glasgow_talk; });
        var data = {
            glasgow_total: this.glasgow_total && this.glasgow_total != "0/0" ? (this.glasgow_total + "") : null,
            glasgow_eye: this.glasgow_eye ? (glasgow_eye ? glasgow_eye.name + " " + glasgow_eye.code : "") : null,
            glasgow_move: this.glasgow_move ? (glasgow_move ? glasgow_move.name + " " + glasgow_move.code : "") : null,
            glasgow_talk: this.glasgow_talk ? (glasgow_talk ? glasgow_talk.name + " " + glasgow_talk.code : "") : null,
            pulse: this.pulse ? (this.pulse + (pulse ? " " + pulse.code : "")) : null,
            sat02: this.sat02 ? (this.sat02 + (this.sat02 ? "%" : "")) : null,
            temp: this.temp ? (this.temp + (temp ? " " + temp.code : "")) : null,
            sistolic_pressure: this.sistolic_pressure ? (this.sistolic_pressure + (sistolic_pressure ? " " + sistolic_pressure.code : "")) : null,
            diastolic_pressure: this.diastolic_pressure ? (this.diastolic_pressure + (diastolic_pressure ? " " + diastolic_pressure.code : "")) : null,
            PAM: this.PAM ? (this.PAM + "") : null,
            pressure_pulse: this.pressure_pulse ? (this.pressure_pulse + (pressure_pulse ? " " + pressure_pulse.code : "")) : null,
            heart_rate: this.heart_rate ? (this.heart_rate + (heart_rate ? " " + heart_rate.code : "")) : null,
            breathing_rate: this.breathing_rate ? (this.breathing_rate + (breathing_rate ? " " + breathing_rate.code : "")) : null,
            eye_vision_right: this.eye_vision_right ? (this.eye_vision_right + "") : null,
            eye_vision_left: this.eye_vision_left ? (this.eye_vision_left + "") : null,
        };
        return data;
    };
    VitalsClass.prototype.raw = function () {
        return {
            id_consult: this.id_consult,
            glasgow_total: this.glasgow_total,
            glasgow_eye: this.glasgow_eye,
            glasgow_move: this.glasgow_move,
            glasgow_talk: this.glasgow_talk,
            pulse: this.pulse,
            id_pulse_unit: this.id_pulse_unit,
            sat02: this.sat02,
            temp: this.temp,
            sistolic_pressure: this.sistolic_pressure,
            diastolic_pressure: this.diastolic_pressure,
            PAM: this.PAM,
            id_sistolic_pressure_unit: this.id_sistolic_pressure_unit,
            id_diastolic_pressure_unit: this.id_diastolic_pressure_unit,
            id_pressure_pulse_unit: this.id_pressure_pulse_unit,
            id_temp_unit: this.id_temp_unit,
            id_heart_rate_unit: this.id_heart_rate_unit,
            id_breathing_rate_unit: this.id_breathing_rate_unit,
            pressure_pulse: this.pressure_pulse,
            heart_rate: this.heart_rate,
            breathing_rate: this.breathing_rate,
            eye_vision_right: this.eye_vision_right,
            eye_vision_left: this.eye_vision_left,
            toogle_pressure: this.toogle_pressure,
        };
    };
    VitalsClass.prototype.glasgow_calc = function () {
        var glasgow_amount = (this.glasgow_eye > 0 ? 4 : 0) +
            (this.glasgow_move > 0 ? 6 : 0) +
            (this.glasgow_talk > 0 ? 5 : 0);
        this.glasgow_total =
            (+this.glasgow_eye + +this.glasgow_move + +this.glasgow_talk) +
                "/" +
                glasgow_amount;
    };
    VitalsClass.prototype.pressure_calc = function () {
        if (this.sistolic_pressure && this.diastolic_pressure) {
            this.PAM = parseFloat(((+parseFloat((this.sistolic_pressure + "").replace(/,/g, "")) + (2 * +parseFloat((this.diastolic_pressure + "").replace(/,/g, "")))) / 3).toFixed(2));
            this.pressure_pulse = parseFloat((parseFloat((this.sistolic_pressure + "").replace(/,/g, "")) - parseFloat((this.diastolic_pressure + "").replace(/,/g, ""))).toFixed(2));
        }
    };
    VitalsClass.prototype.temp_calc = function () {
        var _this = this;
        if (this.temp) {
            var unit = this.catalogsService.catalogs.units_temp.find(function (value) { return value.id == _this.id_temp_unit; });
            this.temp = this.eval(unit.conv.replace("U", this.temp));
        }
    };
    VitalsClass.prototype.eval = function (fn) {
        return new Function('return ' + fn)();
    };
    return VitalsClass;
}(CustomClass));
export { VitalsClass };
