import { ExpedientService } from 'src/app/services/expedient.service';
import { PageService } from '../../services/page.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/page.service";
import * as i2 from "../../services/expedient.service";
var NewPatientGuard = /** @class */ (function () {
    function NewPatientGuard(pageService, expedientService) {
        this.pageService = pageService;
        this.expedientService = expedientService;
    }
    NewPatientGuard.prototype.canActivate = function () {
        localStorage.setItem("inNewPatient", "true");
        this.pageService.set_consult(false);
        this.pageService.set_profile(false);
        this.pageService.set_expedient(true);
        this.expedientService.init({
            version: "new_patient",
            method: "insert"
        });
        this.pageService.set_sidebar_2_state({
            expand: false,
            scroll: true,
            show: false
        });
        this.pageService.set_sidebar_3_state({
            expand: false,
            scroll: true,
            show: true
        });
        return true;
    };
    NewPatientGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewPatientGuard_Factory() { return new NewPatientGuard(i0.ɵɵinject(i1.PageService), i0.ɵɵinject(i2.ExpedientService)); }, token: NewPatientGuard, providedIn: "root" });
    return NewPatientGuard;
}());
export { NewPatientGuard };
