import * as tslib_1 from "tslib";
import { BehaviorSubject, from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/shared/ui/alert_service/alert.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { AppService } from 'src/app/services/app.service';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../shared/ui/alert_service/alert.service";
import * as i4 from "./page.service";
import * as i5 from "./patient-service.service";
;
;
var NewPatientService = /** @class */ (function () {
    function NewPatientService(endpoint, toastr, alertService, pageService, patientService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.alertService = alertService;
        this.pageService = pageService;
        this.patientService = patientService;
        this.store_name = "unimed-new-patient-data";
        var outmessage = 'Está creando un paciente, ¿está seguro de salir y cancelar la creación del paciente actual?.';
        this.error = false;
        this.loading = false;
        this.submitted = false;
        var stored = localStorage.getItem(this.store_name);
        if (stored)
            stored = JSON.parse(stored);
        this.forms = {
            general: null
        };
        this.current_state = {
            general: {
                id: stored && stored["general"] && stored["general"]["id"]
                    ? stored["general"]["id"] :
                    "",
                firstname: stored && stored["general"] && stored["general"]["firstname"]
                    ? stored["general"]["firstname"] :
                    "",
                lastname: stored && stored["general"] && stored["general"]["lastname"]
                    ? stored["general"]["lastname"] :
                    "",
                id_gender: stored && stored["general"] && stored["general"]["id_gender"]
                    ? stored["general"]["id_gender"] :
                    "1",
                birthdate: stored && stored["general"] && stored["general"]["birthdate"]
                    ? stored["general"]["birthdate"] :
                    "",
                birthplace: stored && stored["general"] && stored["general"]["birthplace"]
                    ? stored["general"]["birthplace"] :
                    "",
                id_country: stored && stored["general"] && stored["general"]["id_country"]
                    ? stored["general"]["id_country"] :
                    "97",
                id_department: stored && stored["general"] && stored["general"]["id_department"]
                    ? stored["general"]["id_department"] :
                    "",
                id_town: stored && stored["general"] && stored["general"]["id_town"]
                    ? stored["general"]["id_town"] :
                    "",
                address: stored && stored["general"] && stored["general"]["address"]
                    ? stored["general"]["address"] :
                    "",
                phone: stored && stored["general"] && stored["general"]["phone"]
                    ? stored["general"]["phone"] :
                    "",
                email: stored && stored["general"] && stored["general"]["email"]
                    ? stored["general"]["email"] :
                    "",
                tutor_email: stored && stored["general"] && stored["general"]["tutor_email"]
                    ? stored["general"]["tutor_email"] :
                    "",
                id_education: stored && stored["general"] && stored["general"]["id_education"]
                    ? stored["general"]["id_education"] :
                    "",
                identification: stored && stored["general"] && stored["general"]["identification"]
                    ? stored["general"]["identification"] :
                    "",
                id_blood: stored && stored["general"] && stored["general"]["id_blood"]
                    ? stored["general"]["id_blood"] :
                    "",
                organ_donor: stored && stored["general"] && stored["general"]["organ_donor"]
                    ? stored["general"]["organ_donor"] :
                    "",
            },
            contacts: {
                emergency: stored && stored["contacts"] && stored["contacts"]["emergency"]
                    ? stored["contacts"]["emergency"] :
                    [],
                doctor: stored && stored["contacts"] && stored["contacts"]["doctor"]
                    ? stored["contacts"]["doctor"] :
                    [],
            },
            procedures: {
                doctor: stored && stored["procedures"] && stored["procedures"]["doctor"]
                    ? stored["procedures"]["doctor"] :
                    [],
            },
            background: {
                pathology: stored && stored["background"] && stored["background"]["pathology"]
                    ? stored["background"]["pathology"] :
                    [],
                allergy: stored && stored["background"] && stored["background"]["allergy"]
                    ? stored["background"]["allergy"] :
                    [],
            },
            insurance: {
                accident: stored && stored["insurance"] && stored["insurance"]["accident"]
                    ? stored["insurance"]["accident"] :
                    [],
            },
            exam: {
                laboratory: stored && stored["exam"] && stored["exam"]["laboratory"]
                    ? stored["exam"]["laboratory"] :
                    [],
            },
            institutions: stored && stored["institutions"]
                ? stored["institutions"] :
                [],
            url: {
                name: "general",
                link: "/nuevo_paciente/general"
            },
            loading: false,
            submitted: false,
            timestamp: stored ? stored["timestamp"] : new Date,
        };
        this.patient_state = new BehaviorSubject(this.current_state);
        this.current = this.patient_state.asObservable();
        this.forms_state == new BehaviorSubject(this.forms);
        this.obv_forms = this.patient_state.asObservable();
        this.catalogs = {
            genders: [],
            countries: [],
            departments: [],
            towns: [],
            educations: [],
            relationships: [],
            doctors_specialties: [],
            procedures_types: [],
            exams_types: [],
            exams_names: [],
            exams_names_2: [],
            allergies_reactions_levels: [],
            allergies_reactions_types: [],
            pathologies: [],
            institutions: [],
            institutions_roles: [],
            institutions_grades: [],
            institutions_grades_sections: [],
            institutions_areas: [],
            diseases: [],
            bloods: [],
            tf: []
        };
        this.reload_catalogs();
    }
    NewPatientService.prototype.reload = function () {
        this.get_patient_data();
    };
    NewPatientService.prototype.get_current_state = function () {
        return this.current_state;
    };
    NewPatientService.prototype.get_patient_data = function () {
        var _this = this;
        this.endpoint.get_user_data().subscribe(function (data) { return _this.current_state = tslib_1.__assign({ general: {
                id: data["id"],
                firstname: data["firstname"],
                lastname: data["lastname"],
                id_gender: data["id_gender"],
                birthdate: data["birthdate"],
                birthplace: data["birthplace"],
                id_country: data["id_country"],
                id_department: data["id_department"],
                id_town: data["id_town"],
                address: data["address"],
                phone: data["phone"],
                id_education: data["id_education"],
                identification: data["identification"],
                diseases: data["diseases"]
            }, timestamp: new Date() }, _this.current_state); }, function (error) { return console.error(error); }, function () {
            _this.save();
            _this.patient_state.next(_this.current_state);
        });
    };
    NewPatientService.prototype.refresh_forms = function () {
        this.forms_state.next(this.forms);
    };
    NewPatientService.prototype.valid = function () {
        return !this.current_state.loading &&
            this.forms && this.forms.general && this.forms.general.valid ? this.forms.general.valid() : false;
    };
    NewPatientService.prototype.set_url = function (url) {
        this.current_state.url = url;
        this.patient_state.next(this.current_state);
    };
    NewPatientService.prototype.set_form = function (form) {
        this.forms[from.name] = form.module;
    };
    NewPatientService.prototype.save = function () {
        var store = {};
        for (var item in this.current_state) {
            if (item != "form") {
                store[item] = this.current_state[item];
            }
        }
        localStorage.setItem(this.store_name, JSON.stringify(store));
    };
    NewPatientService.prototype.reload_catalogs = function () {
        var _this = this;
        this.endpoint.new_patient_catalogs().subscribe(function (data) { return _this.catalogs = {
            genders: data["genders"],
            countries: data["countries"],
            departments: data["departments"],
            towns: data["towns"],
            educations: data["educations"],
            relationships: data["relationships"],
            doctors_specialties: data["doctors_specialties"],
            procedures_types: data["procedures_types"],
            exams_types: data["exams_types"],
            exams_names: data["exams_names"],
            exams_names_2: data["exams_names_2"],
            allergies_reactions_levels: data["allergies_reactions_levels"],
            allergies_reactions_types: data["allergies_reactions_types"],
            pathologies: data["pathologies"],
            institutions: data["institutions"],
            institutions_grades: data["institutions_grades"],
            institutions_grades_sections: data["institutions_grades_sections"],
            institutions_roles: data["institutions_roles"],
            institutions_areas: data["institutions_areas"],
            diseases: data["diseases"],
            bloods: data["bloods"],
            tf: data["tf"],
        }; }, function (error) { return console.error(error); });
    };
    NewPatientService.prototype.submit = function () {
        var _this = this;
        this.forms.general.submit(function () { });
        this.current_state.submitted = true;
        this.patient_state.next(this.current_state);
        if (!this.current_state.loading &&
            this.forms.general.valid()) {
            var store = {};
            for (var item in this.current_state) {
                if (item != "form") {
                    store[item] = this.current_state[item];
                }
            }
            this.current_state.loading = true;
            this.endpoint.insert_patient_by_doctor(store).subscribe(function (data) {
                localStorage.removeItem("inNewPatient");
                _this.toastr.success(data["message"], data["title"]);
                _this.current_state.loading = false;
                _this.patient_state.next(_this.current_state);
                _this.clean();
                _this.patientService.change_patient_id(data["id_user"]);
                _this.endpoint.redirect_to("/expediente/consultas");
            }, function (error) {
                console.error(error);
                _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
                _this.current_state.loading = false;
                _this.patient_state.next(_this.current_state);
            });
        }
    };
    NewPatientService.prototype.cancel = function () {
        var _this = this;
        this.pageService.cancel_new_patient(function () {
            _this.clean();
            _this.endpoint.redirect_to("/mis_pacientes");
        });
    };
    NewPatientService.prototype.clean = function () {
        this.current_state = {
            general: {
                id: "",
                firstname: "",
                lastname: "",
                id_gender: "1",
                birthdate: "",
                birthplace: "",
                id_country: "97",
                id_department: "",
                id_town: "",
                address: "",
                phone: "",
                email: "",
                tutor_email: "",
                id_education: "",
                identification: "",
                id_blood: "",
                organ_donor: "1",
            },
            contacts: {
                emergency: [],
                doctor: [],
            },
            procedures: {
                doctor: []
            },
            background: {
                pathology: [],
                allergy: [],
            },
            insurance: {
                accident: [],
            },
            exam: {
                laboratory: [],
            },
            institutions: [],
            url: {
                name: "general",
                link: "/nuevo_paciente/general"
            },
            loading: false,
            submitted: false,
            timestamp: new Date(),
        };
        this.patient_state.next(this.current_state);
        this.save();
    };
    NewPatientService.prototype.set_attribute = function (data, name) {
        this.current_state[name] = data;
    };
    NewPatientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewPatientService_Factory() { return new NewPatientService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.AlertService), i0.ɵɵinject(i4.PageService), i0.ɵɵinject(i5.PatientService)); }, token: NewPatientService, providedIn: "root" });
    return NewPatientService;
}());
export { NewPatientService };
