import { OnInit, OnDestroy } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { ConsultGeneralService } from "./consult.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
var LayoutComponent = /** @class */ (function () {
    function LayoutComponent(endpoint, activeRoute, consultService, toast) {
        this.endpoint = endpoint;
        this.activeRoute = activeRoute;
        this.consultService = consultService;
        this.toast = toast;
        this.dynamic_tabs_config();
    }
    LayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.consult_subscription = this.consultService.current.subscribe(function (state) {
            _this.consult_state = state;
            _this.validate_direct();
            _this.dynamic_tabs_config();
        });
    };
    LayoutComponent.prototype.ngOnDestroy = function () {
        this.consultService.set_loading(true, 0);
        this.consultService.clean();
        this.consultService.next();
        this.consult_subscription.unsubscribe();
    };
    LayoutComponent.prototype.dynamic_tabs_config = function () {
        this.dynamic_tabs = {
            no_border: true,
            list: [
                {
                    name: "general",
                    icon: "id-card-clip",
                    class: "mx-xl-5 mx-md-3 mx-2",
                    font_size: "0.9rem",
                    url: "/expediente/nueva-consulta/general/general",
                    show: function () { return true; },
                },
                {
                    name: "vitales",
                    icon: "monitor-waveform",
                    class: "mx-xl-5 mx-md-3 mx-2",
                    font_size: "0.9rem",
                    url: "/expediente/nueva-consulta/general/vitales",
                    show: function () { return true; },
                },
                // {
                //     name: "curva crecimiento",
                //     icon: "monitor-waveform",
                //     class: "mx-xl-5 mx-md-3 mx-2",
                //     font_size: "0.9rem",
                //     url: "/expediente/nueva-consulta/general/curva_crecimiento",
                //     show: () => true,
                // },
                {
                    name: "examen físico",
                    icon: "person-half-dress",
                    class: "mx-xl-5 mx-md-3 mx-2",
                    font_size: "0.9rem",
                    url: "/expediente/nueva-consulta/general/examen_fisico",
                    show: function () { return true; },
                },
                {
                    name: "diagnóstico",
                    icon: "bacteria",
                    class: "mx-xl-5 mx-md-3 mx-2",
                    font_size: "0.9rem",
                    url: "/expediente/nueva-consulta/general/diagnostico",
                    show: function () { return true; },
                },
                {
                    name: "exámenes",
                    url: "/expediente/nueva-consulta/general/examenes",
                    show: function () { return false; },
                },
                {
                    name: "procedimientos",
                    url: "/expediente/nueva-consulta/general/procedimientos",
                    show: function () { return false; },
                },
                {
                    name: "referencias",
                    url: "/expediente/nueva-consulta/general/referencias",
                    show: function () { return false; },
                },
                {
                    name: "recomendaciones y notas",
                    url: "/expediente/nueva-consulta/general/recomendaciones_notas",
                    show: function () { return false; },
                },
            ],
        };
    };
    LayoutComponent.prototype.print = function () {
        var _this = this;
        this.consultService.set_loading(true);
        setTimeout(function () {
            _this.consultService.create_pdf();
            _this.consultService.set_loading(false);
        }, 3000);
    };
    LayoutComponent.prototype.cancel = function () {
        this.consultService.cancel();
    };
    LayoutComponent.prototype.valid = function () {
        return this.consultService.valid();
    };
    LayoutComponent.prototype.submit = function () {
        this.consultService.finish_consult();
    };
    LayoutComponent.prototype.get_next = function () {
        var _this = this;
        var index = this.dynamic_tabs.list.findIndex(function (value) { return "/" + _this.activeRoute.snapshot["_urlSegment"].segments.map(function (value) { return value.path; }).join("/") == value.url; });
        if (index + 1 < this.dynamic_tabs.list.length) {
            return this.dynamic_tabs.list[index + 1].url;
        }
        else {
            return false;
        }
    };
    LayoutComponent.prototype.get_back = function () {
        var _this = this;
        var index = this.dynamic_tabs.list.findIndex(function (value) { return "/" + _this.activeRoute.snapshot["_urlSegment"].segments.map(function (value) { return value.path; }).join("/") == value.url; });
        if (index > 0) {
            return this.dynamic_tabs.list[index - 1].url;
        }
        else {
            return false;
        }
    };
    LayoutComponent.prototype.save_consult = function () {
        this.consultService.save_consult();
    };
    LayoutComponent.prototype.save_minimize_consult = function () {
        this.consultService.save_minimize_consult();
    };
    LayoutComponent.prototype.save_close_consult = function () {
        this.consultService.save_close_consult();
    };
    LayoutComponent.prototype.add_extra = function (name) {
        var extra = this.has_extra(name);
        if (extra) {
            if (extra == 1) {
                if (this.activeRoute.snapshot["_urlSegment"].segments.map(function (value) { return value.path; }).pop() == name) {
                    var back = this.get_back();
                    if (back)
                        this.endpoint.redirect_to(back);
                    var i = this.consult_state.extras.findIndex(function (value) { return value == name; });
                    this.consult_state.extras.splice(i, 1);
                    this.dynamic_tabs_config();
                }
                else {
                    var i = this.consult_state.extras.findIndex(function (value) { return value == name; });
                    this.consult_state.extras.splice(i, 1);
                    this.dynamic_tabs_config();
                    var back = this.get_back();
                    if (back)
                        this.endpoint.redirect_to(back);
                }
            }
            else {
                this.endpoint.redirect_to("/expediente/nueva-consulta/general/" + name);
            }
        }
        else {
            this.consult_state.extras.push(name);
            this.endpoint.redirect_to("/expediente/nueva-consulta/general/" + name);
        }
        this.dynamic_tabs_config();
    };
    LayoutComponent.prototype.has_extra = function (name) {
        if (this.consult_state) {
            var exist = this.consult_state.extras && this.consult_state.extras.find(function (value) { return value == name; }) ? 1 : 0;
            if (name == "examenes") {
                return exist + (this.consult_state.consult.exams_requests_list.list.length > 0 || this.consult_state.consult.exams_results_list.list.length > 0 ? 2 : 0);
            }
            else if (name == "procedimientos") {
                return exist + (this.consult_state.consult.procedures_requests_list.list.length > 0 || this.consult_state.consult.procedures_results_list.list.length > 0 ? 2 : 0);
            }
            else if (name == "referencias") {
                return exist + (this.consult_state.consult.references_list.list.length > 0 ? 2 : 0);
            }
        }
        return 0;
    };
    LayoutComponent.prototype.validate_direct = function () {
        var name = this.activeRoute.snapshot["_urlSegment"].segments.map(function (value) { return value.path; }).pop();
        if (["examenes", "procedimientos", "referencias"].includes(name) && !this.has_extra(name)) {
            this.consult_state.extras.push(name);
        }
    };
    LayoutComponent.prototype.onActivate = function (e) {
    };
    LayoutComponent.prototype.onDeactivate = function (e) {
    };
    LayoutComponent.prototype.onAttach = function (e) {
    };
    LayoutComponent.prototype.onDetach = function (e) {
    };
    return LayoutComponent;
}());
export { LayoutComponent };
