import { Routes } from '@angular/router';
import { ContactsEmergencyComponent } from './emergency.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: 'emergencia',
        component: ContactsEmergencyComponent
    }
];
var ContactsEmergencyModule = /** @class */ (function () {
    function ContactsEmergencyModule() {
    }
    return ContactsEmergencyModule;
}());
export { ContactsEmergencyModule };
