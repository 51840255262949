import * as i0 from "@angular/core";
var PipesService = /** @class */ (function () {
    function PipesService() {
        this.months = [
            { value: "01", label: "Enero" },
            { value: "02", label: "Febrero" },
            { value: "03", label: "Marzo" },
            { value: "04", label: "Abril" },
            { value: "05", label: "Mayo" },
            { value: "06", label: "Junio" },
            { value: "07", label: "Julio" },
            { value: "08", label: "Agosto" },
            { value: "09", label: "Septiembre" },
            { value: "10", label: "Octubre" },
            { value: "11", label: "Noviembre" },
            { value: "12", label: "Diciembre" },
        ];
    }
    PipesService.prototype.micro_date = function (str) {
        if (str) {
            var raw = new Date(str).toLocaleDateString("es-HN", { year: "numeric", month: "2-digit", day: "2-digit" });
            if (raw) {
                var raw_array_1 = raw.split("/");
                var month = this.months.find(function (val) { return val.value == raw_array_1[1]; });
                return raw_array_1[0] + " / " + (month ? month.label.substring(0, 3) : "") + " / " + raw_array_1[2];
            }
        }
        else {
            return "";
        }
    };
    PipesService.prototype.long_date = function (str) {
        if (str) {
            return new Date(str).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true, formatMatcher: "basic" }).replace(/00:/g, "12:");
        }
        else {
            return "";
        }
    };
    PipesService.prototype.number = function (num, min, max) {
        if (min === void 0) { min = 2; }
        if (max === void 0) { max = 2; }
        return num.toLocaleString("es-HN", {
            minimumFractionDigits: min,
            maximumFractionDigits: max,
        });
    };
    PipesService.prototype.phone = function (num, id_country) {
        return num;
    };
    PipesService.prototype.monthYear = function (str) {
        var date = new Date(str).toLocaleDateString('es-HN', {
            year: 'numeric',
            month: 'long',
        });
        var fragments = date.split(' ');
        return fragments[0].charAt(0).toUpperCase() + fragments[0].slice(1) + " " + fragments.slice(2).join(' ');
    };
    PipesService.prototype.year = function (str) {
        var date = new Date(str).toLocaleDateString('es-HN', {
            year: 'numeric'
        });
        var fragments = date.split(' ');
        return date;
    };
    PipesService.prototype.time_tostring = function (str) {
        var str_f = str.split(":");
        var _date = new Date();
        _date.setHours(+str_f[0]);
        _date.setMinutes(+str_f[1]);
        _date.setMilliseconds(+str_f[2]);
        var date = _date.toLocaleDateString('es-HN', { hour: '2-digit', minute: '2-digit', hour12: true });
        var fragments = date.split(" ").slice(1).join(" ").split(":");
        return fragments[0].replace("00", "12") + ":" + fragments[1];
    };
    PipesService.prototype.names = function (str) {
        return str.replace(/\b[a-zA-Z\u00C0-\u00ff']+/g, function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
    };
    PipesService.prototype.dateDif = function (date1, date2, lapse) {
        var msPerDay = 1000 * 60 * 60 * 24;
        var msPerMonth = 1000 * 60 * 60 * 24 * (30.4375);
        var msPerYear = 1000 * 60 * 60 * 24 * (30.4375 * 12);
        var d1 = date1 instanceof Date ? date1 : new Date(date1);
        var d2 = date2 instanceof Date ? date2 : new Date(date2);
        var diffInMs = Math.abs(d2.getTime() - d1.getTime());
        switch (lapse) {
            case "days":
                return Math.floor(diffInMs / msPerDay);
            case "months":
                return Math.floor(diffInMs / msPerMonth);
            case "years":
                return Math.floor(diffInMs / msPerYear);
            default:
                throw new Error("The lapse argument must be 'days', 'months', or 'years'");
        }
    };
    PipesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PipesService_Factory() { return new PipesService(); }, token: PipesService, providedIn: "root" });
    return PipesService;
}());
export { PipesService };
