import { PipeTransform } from "@angular/core";
var CatalogPipe = /** @class */ (function () {
    function CatalogPipe() {
    }
    CatalogPipe.prototype.transform = function (id, catalog) {
        if (catalog) {
            var item = catalog.list().find(function (v) { return v[catalog.value] == id; });
            return item ? item[catalog.text] : "—";
        }
        else {
            return "—";
        }
    };
    return CatalogPipe;
}());
export { CatalogPipe };
