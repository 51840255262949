import { ExpedientService } from 'src/app/services/expedient.service';
import { EmergencyService } from '../emergency.service';
var CreateContactsEmergencyComponent = /** @class */ (function () {
    function CreateContactsEmergencyComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateContactsEmergencyComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateContactsEmergencyComponent;
}());
export { CreateContactsEmergencyComponent };
