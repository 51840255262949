import Swal from "sweetalert2";
import * as i0 from "@angular/core";
var SweelService = /** @class */ (function () {
    function SweelService() {
    }
    //SA normal
    SweelService.prototype.normal = function (title, text, type) {
        Swal.fire(title.toUpperCase(), text.toUpperCase(), type);
    };
    //Mensaje con confirmacion
    SweelService.prototype.confirm = function (title, html, type) {
        return new Promise(function (resolve, reject) {
            Swal.fire({
                title: title,
                html: html,
                type: type,
                showCancelButton: true,
                confirmButtonColor: 'var(--success)',
                cancelButtonColor: 'var(--danger)',
                confirmButtonText: "Sí, confirmar",
                cancelButtonText: "No",
                focusCancel: true,
            }).then(function (result) { return resolve(!!result.value); });
        });
    };
    //Mensaje con input
    SweelService.prototype.input = function (title) {
        return new Promise(function (resolve, reject) {
            Swal.fire({
                title: title,
                input: "text",
                inputAttributes: {
                    autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonColor: 'var(--success)',
                cancelButtonColor: 'var(--danger)',
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                allowOutsideClick: function () { return !Swal.isLoading(); },
            }).then(function (result) {
                if (!!result) {
                }
            });
        });
    };
    SweelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SweelService_Factory() { return new SweelService(); }, token: SweelService, providedIn: "root" });
    return SweelService;
}());
export { SweelService };
