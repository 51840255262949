import { EventEmitter } from '@angular/core';
var InsuranceCardComponent = /** @class */ (function () {
    function InsuranceCardComponent() {
        this.event = new EventEmitter();
    }
    InsuranceCardComponent.prototype._event = function (name, item) {
        this.event.emit({ name: name, item: item });
    };
    return InsuranceCardComponent;
}());
export { InsuranceCardComponent };
