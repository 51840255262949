import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { LaboratoryService } from '../laboratory.service';
import { ExpedientService } from 'src/app/services/expedient.service';
var FormComponent = /** @class */ (function () {
    function FormComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            // this.moduleService.reload();
            _this.form_configs();
        });
        this.laboratory_subscription = this.moduleService.current.subscribe(function (state) { return _this.laboratory_state = state; });
        this.form_configs();
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        if (this.laboratory_state) {
            this.moduleService.forms.laboratory = this.form_ref;
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.laboratory_subscription.unsubscribe();
    };
    FormComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return _this.expedient_state.method == "show"; },
            disabled: function () { return _this.expedient_state.method == "show"; },
            submit: function (callback) { return _this.moduleService.submit(callback); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "id_exams_type",
                            type: "select",
                            label: "Tipo de Examen",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.moduleService.catalogs.exams_types; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                            change: function () {
                                _this.laboratory_state.id_exams_name = "";
                                _this.laboratory_state.id_exams_name_2 = "";
                            }
                        },
                        {
                            name: "id_exams_name",
                            type: "select",
                            label: "Nombre de Examen",
                            placeholder: function () { return _this.laboratory_state.id_exams_type ? "- Seleccione -" : "- Seleccione un Tipo de Examen -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.moduleService.catalogs.exams_names.filter(function (value) { return value.id_exams_type == _this.laboratory_state.id_exams_type; }); },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return !_this.laboratory_state.id_exams_type; },
                            change: function () {
                                _this.laboratory_state.id_exams_name_2 = "";
                            }
                        },
                        {
                            name: "id_exams_name_2",
                            type: "select",
                            label: "Nombre Secundario",
                            placeholder: function () { return _this.laboratory_state.id_exams_name ? "- Seleccione -" : "- Seleccione un Nombre de Examen -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.moduleService.catalogs.exams_names_2.filter(function (value) { return value.id_exams_name == _this.laboratory_state.id_exams_name; }); },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return !_this.laboratory_state.id_exams_name; },
                            show: function () { return _this.laboratory_state.id_exams_type == 2; }
                        },
                        {
                            name: "observations",
                            type: "textarea",
                            label: "Observaciones",
                            rows: 2.5,
                            col_class: "col-12",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "realization_date",
                            type: "calendar",
                            label: "Fecha de Realización",
                            placeholder: function () { return "##/##/####"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        }
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    click: function () {
                                        _this.moduleService.cancel();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cerrar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "show";
                                    },
                                    click: function () {
                                        _this.moduleService.close();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Guardar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    disabled: function () { return (_this.form_ref ? !_this.form_ref.valid() : false) || _this.laboratory_state.loading; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    return FormComponent;
}());
export { FormComponent };
