import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import "rxjs/add/observable/of";
import "rxjs/add/operator/do";
import "rxjs/add/operator/delay";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import "rxjs/add/observable/throw";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
var FormQueryEncoder = /** @class */ (function () {
    function FormQueryEncoder() {
    }
    FormQueryEncoder.prototype.encodeKey = function (k) {
        return encodeURIComponent(k);
    };
    FormQueryEncoder.prototype.encodeValue = function (v) {
        return encodeURIComponent(v);
    };
    FormQueryEncoder.prototype.decodeKey = function (k) {
        return encodeURIComponent(k);
    };
    FormQueryEncoder.prototype.decodeValue = function (v) {
        return encodeURIComponent(v);
    };
    return FormQueryEncoder;
}());
export { FormQueryEncoder };
var AppService = /** @class */ (function () {
    function AppService(httpClient, router, activeRoute, titleService) {
        var _this = this;
        this.httpClient = httpClient;
        this.router = router;
        this.activeRoute = activeRoute;
        this.titleService = titleService;
        this._refresh$ = new Subject();
        // _______________________________________________________________________________________
        // 									   	  UTIL
        this.redirect_to = function (url) { return _this.router.navigateByUrl(url); };
        // 									   	  UTIL
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 									   	  SESSION
        this.set_patient_id = function (patient_id) {
            localStorage.setItem(_this.patient_id, patient_id);
        };
        this.get_patient_id = function (patient_id) {
            if (patient_id === void 0) { patient_id = localStorage.getItem(_this.patient_id); }
            return (patient_id ? patient_id : null);
        };
        this.get_session = function (session) {
            if (session === void 0) { session = localStorage.getItem(_this.user_session_name); }
            return (session ? JSON.parse(session) : null);
        };
        this.set_session = function (session) {
            localStorage.setItem(_this.user_session_name, JSON.stringify(session));
        };
        this.reset_session = function () {
            localStorage.removeItem(_this.user_session_name);
        };
        this.verify_session = function () { return _this.httpClient.get(_this.endpoint + "/verify_session"); };
        this.decode_session = function () { return _this.httpClient.get(_this.endpoint + "/decode_session"); };
        // 									   	  SESSION
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 									   	  CATALOGS
        this.get_catalogs = function (payload) { return _this.httpClient.get(_this.endpoint + "/get_catalogs", { params: payload }); };
        this.get_catalogs_medicines = function (payload) { return _this.httpClient.get(_this.endpoint + "/get_catalogs_medicines", { params: payload }); };
        this.get_catalogs_doctors = function (payload) { return _this.httpClient.get(_this.endpoint + "/get_catalogs_doctors", { params: payload }); };
        this.gender_catalogue = function () { return _this.httpClient.get(_this.endpoint + "/gender_catalogue"); };
        this.new_patient_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_patient_catalogs"); };
        this.new_consult_general_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_consult_general_catalogs"); };
        this.diseases_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/diseases_catalogs"); };
        this.new_vaccine_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_vaccine_catalogs"); };
        this.icd_pathologies = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/icd_pathologies", {
                params: payload,
            });
        };
        this.new_procedure_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_procedure_catalogs"); };
        this.new_exam_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_exam_catalogs"); };
        this.new_anthropometries_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_anthropometries_catalogs"); };
        this.new_vitals_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_vitals_catalogs"); };
        this.new_treatment_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_treatment_catalogs"); };
        this.new_prescription_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_prescription_catalogs"); };
        this.new_procedures_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_procedures_catalogs"); };
        this.new_references_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_references_catalogs"); };
        this.new_exams_laboratory_catalogs = function () { return _this.httpClient.get(_this.endpoint + "/new_exams_laboratory_catalogs"); };
        // 									   	  USER
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 									   	  AUTH
        this.login = function (payload) { return _this.httpClient.post(_this.endpoint + "/login", payload); };
        this.get_user_data = function () { return _this.httpClient.get(_this.endpoint + "/get_user_data"); };
        this.steps_2 = function (payload) { return _this.httpClient.post(_this.endpoint + "/steps_2", payload); };
        this.send_steps_2 = function () { return _this.httpClient.post(_this.endpoint + "/send_steps_2", {}); };
        this.first_login = function (payload) { return _this.httpClient.post(_this.endpoint + "/first_login", payload); };
        this.recover_password = function (payload) { return _this.httpClient.post(_this.endpoint + "/recover_password", payload); };
        this.reset_password = function (payload, token) {
            return _this.httpClient.post(_this.endpoint + "/reset_password", payload, {
                headers: new HttpHeaders({ authorization: token }),
            });
        };
        this.logout = function () {
            localStorage.clear();
            return _this.httpClient.post(_this.endpoint + "/logout", {});
        };
        // 									   	  PATIENTS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 									  EXPEDIENT GENERAL
        this.expedient_general_get_general = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/general/get_general", {
                params: payload,
            });
        };
        this.expedient_general_insert_general = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/general/insert_general", payload);
        };
        this.expedient_general_update_general = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/general/update_general", payload);
        };
        // 									  EXPEDIENT GENERAL
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT INSURANCES ACCIDENT
        this.expedient_insurances_accident_get_accidents = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/insurances/accident/get_accidents", { params: payload });
        };
        this.expedient_insurances_accident_insert_accident = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/insurances/accident/insert_accident", payload);
        };
        this.expedient_insurances_accident_update_accident = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/insurances/accident/update_accident", payload);
        };
        this.expedient_insurances_accident_delete_accident = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/insurances/accident/delete_accident", { params: payload });
        };
        // 							     EXPEDIENT INSURANCES ACCIDENT
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT EXAMS LABORATORY
        this.expedient_exams_laboratory_get_laboratories = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/exams/laboratory/get_laboratories", { params: payload });
        };
        this.expedient_exams_laboratory_insert_laboratory = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/exams/laboratory/insert_laboratory", payload);
        };
        this.expedient_exams_laboratory_update_laboratory = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/exams/laboratory/update_laboratory", payload);
        };
        this.expedient_exams_laboratory_delete_laboratory = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/exams/laboratory/delete_laboratory", { params: payload });
        };
        // 							     EXPEDIENT EXAMS LABORATORY
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT BACKGROUND PATHOLOGIES
        this.expedient_background_pathologies_get_pathologies = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/background/pathologies/get_pathologies", { params: payload });
        };
        this.expedient_background_pathologies_insert_pathology = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/background/pathologies/insert_pathology", payload);
        };
        this.expedient_background_pathologies_update_pathology = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/background/pathologies/update_pathology", payload);
        };
        this.expedient_background_pathologies_delete_pathology = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/background/pathologies/delete_pathology", {
                params: payload,
            });
        };
        // 							     EXPEDIENT BACKGROUND PATHOLOGIES
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT BACKGROUND ALLERGIES
        this.expedient_background_allergies_get_allergies = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/background/allergies/get_allergies", { params: payload });
        };
        this.expedient_background_allergies_insert_allergy = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/background/allergies/insert_allergy", payload);
        };
        this.expedient_background_allergies_update_allergy = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/background/allergies/update_allergy", payload);
        };
        this.expedient_background_allergies_delete_allergy = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/background/allergies/delete_allergy", { params: payload });
        };
        // 							     EXPEDIENT BACKGROUND ALLERGIES
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONTACTS EMERGENCY
        this.expedient_contacts_emergency_get_emergency = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/contacts/emergency/get_emergency", { params: payload });
        };
        this.expedient_contacts_emergency_insert_emergency = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/contacts/emergency/insert_emergency", payload);
        };
        this.expedient_contacts_emergency_update_emergency = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/contacts/emergency/update_emergency", payload);
        };
        this.expedient_contacts_emergency_delete_emergency = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/contacts/emergency/delete_emergency", { params: payload });
        };
        // 							     EXPEDIENT CONTACTS EMERGENCY
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONTACTS DOCTOR
        this.expedient_contacts_doctor_get_doctor = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/contacts/doctor/get_doctor", { params: payload });
        };
        this.expedient_contacts_doctor_insert_doctor = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/contacts/doctor/insert_doctor", payload);
        };
        this.expedient_contacts_doctor_update_doctor = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/contacts/doctor/update_doctor", payload);
        };
        this.expedient_contacts_doctor_delete_doctor = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/contacts/doctor/delete_doctor", { params: payload });
        };
        // 							     EXPEDIENT CONTACTS DOCTOR
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT PROCEDURES DOCTOR
        this.expedient_procedures_doctor_get_doctors = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/procedures/doctor/get_doctors", { params: payload });
        };
        this.expedient_procedures_doctor_insert_doctor = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/procedures/doctor/insert_doctor", payload);
        };
        this.expedient_procedures_doctor_update_doctor = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/procedures/doctor/update_doctor", payload);
        };
        this.expedient_procedures_doctor_delete_doctor = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/procedures/doctor/delete_doctor", { params: payload });
        };
        // 							     EXPEDIENT PROCEDURES DOCTOR
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT REFERENCES DOCTOR
        this.expedient_references_doctor_get_doctors = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/references/doctor/get_doctors", { params: payload });
        };
        this.expedient_references_doctor_insert_doctor = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/references/doctor/insert_doctor", payload);
        };
        this.expedient_references_doctor_update_doctor = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/references/doctor/update_doctor", payload);
        };
        this.expedient_references_doctor_delete_doctor = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/references/doctor/delete_doctor", { params: payload });
        };
        // 							     EXPEDIENT REFERENCES DOCTOR
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT RELATIONS DEPENDANTS
        this.expedient_relations_dependants_get_dependants = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/relations/dependants/get_dependants", { params: payload });
        };
        this.expedient_relations_dependants_insert_dependants = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/relations/dependants/insert_dependants", payload);
        };
        this.expedient_relations_dependants_update_dependants = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/relations/dependants/update_dependants", payload);
        };
        this.expedient_relations_dependants_confirm_dependants = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/relations/dependants/confirm_dependants", payload);
        };
        this.expedient_relations_dependants_delete_dependants = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/relations/dependants/delete_dependants", {
                params: payload,
            });
        };
        // 							     EXPEDIENT RELATIONS DEPENDANTS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT RELATIONS TUTORS
        this.expedient_relations_tutors_get_tutors = function (payload) { return _this.httpClient.get(_this.endpoint + "/expedient/relations/tutors/get_tutors", { params: payload }); };
        this.expedient_relations_tutors_validate_phone = function (payload) { return _this.httpClient.get(_this.endpoint + "/expedient/relations/tutors/validate_phone", { params: payload }); };
        this.expedient_relations_tutors_insert_tutors = function (payload) { return _this.httpClient.post(_this.endpoint + "/expedient/relations/tutors/insert_tutors", payload); };
        this.expedient_relations_tutors_insert_tutors_bulk = function (payload) { return _this.httpClient.post(_this.endpoint + "/expedient/relations/tutors/insert_tutors_bulk", payload); };
        this.expedient_relations_tutors_verify_whatsapp = function (payload) { return _this.httpClient.post(_this.endpoint + "/expedient/relations/tutors/verify_whatsapp", payload); };
        this.expedient_relations_tutors_update_tutors = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/relations/tutors/update_tutors", payload); };
        this.expedient_relations_tutors_delete_tutors = function (payload) { return _this.httpClient.delete(_this.endpoint + "/expedient/relations/tutors/delete_tutors", { params: payload }); };
        // 							     EXPEDIENT RELATIONS TUTORS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT INSTITUTIONS
        this.expedient_institutions_get_institutions = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/institutions/get_institutions", { params: payload });
        };
        this.expedient_institutions_insert_institutions = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/institutions/insert_institutions", payload);
        };
        this.expedient_institutions_update_institutions = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/institutions/update_institutions", payload);
        };
        this.expedient_institutions_delete_institutions = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/institutions/delete_institutions", { params: payload });
        };
        // 							     EXPEDIENT INSTITUTIONS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT VACCINES
        this.expedient_vaccines_get_vaccines = function (payload) { return _this.httpClient.get(_this.endpoint + "/expedient/vaccines/get_vaccines", { params: payload }); };
        this.expedient_vaccines_insert_vaccines = function (payload) { return _this.httpClient.post(_this.endpoint + "/expedient/vaccines/insert_vaccines", payload); };
        this.expedient_vaccines_insert_vaccines_custom = function (payload) { return _this.httpClient.post(_this.endpoint + "/expedient/vaccines/insert_vaccines_custom", payload); };
        this.expedient_vaccines_insert_vaccines_custom_dose = function (payload) { return _this.httpClient.post(_this.endpoint + "/expedient/vaccines/insert_vaccines_custom_dose", payload); };
        this.expedient_vaccines_update_vaccines = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/vaccines/update_vaccines", payload); };
        this.expedient_vaccines_delete_vaccines = function (payload) { return _this.httpClient.delete(_this.endpoint + "/expedient/vaccines/delete_vaccines", { params: payload }); };
        // 							     EXPEDIENT VACCINES
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT GROWTH CURVE
        this.expedient_growth_curve_get_growth_curve = function (payload) { return _this.httpClient.get(_this.endpoint + "/expedient/growth_curve/get_growth_curve", { params: payload }); };
        this.expedient_growth_curve_insert_growth_curve = function (payload) { return _this.httpClient.post(_this.endpoint + "/expedient/growth_curve/insert_growth_curve", payload); };
        this.expedient_growth_curve_update_growth_curve = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/growth_curve/update_growth_curve", payload); };
        this.expedient_growth_curve_delete_growth_curve = function (payload) { return _this.httpClient.delete(_this.endpoint + "/expedient/growth_curve/delete_growth_curve", { params: payload }); };
        // 							     EXPEDIENT GROWTH CURVE
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL
        this.expedient_consult_general_get_consult = function (payload) { return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/get_consult", { params: payload }); };
        this.expedient_consult_general_init_consult = function (payload) { return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/init_consult", payload); };
        this.expedient_consult_general_save_consult = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/save_consult", payload); };
        this.expedient_consult_general_auto_save_consult = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/auto_save_consult", payload); };
        this.expedient_consult_general_cancel_consult = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/cancel_consult", payload); };
        this.expedient_consult_general_finish_consult = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/finish_consult", payload); };
        // 							     EXPEDIENT CONSULT GENERAL
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL GENERAL
        this.expedient_consult_general_general_get_general = function (payload) { return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/general/get_general", { params: payload }); };
        this.expedient_consult_general_general_update_general = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/general/update_general", payload); };
        // 							     EXPEDIENT CONSULT GENERAL GENERAL
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL ANTHROPOMETRIES
        this.expedient_consult_general_anthropometries_get_anthropometries = function (payload) { return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/anthropometries/get_anthropometries", {
            params: payload,
        }); };
        this.expedient_consult_general_anthropometries_update_anthropometries = function (payload) { return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/anthropometries/update_anthropometries", payload); };
        // 							     EXPEDIENT CONSULT GENERAL ANTHROPOMETRIES
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL VITALS
        this.expedient_consult_general_vitals_get_vitals = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/vitals/get_vitals", { params: payload });
        };
        this.expedient_consult_general_vitals_update_vitals = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/vitals/update_vitals", payload);
        };
        // 							     EXPEDIENT CONSULT GENERAL VITALS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL EXAMS PHYSICAL
        this.expedient_consult_general_exams_physical_get_exams_physical = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/exams_physical/get_exams_physical", {
                params: payload,
            });
        };
        this.expedient_consult_general_exams_physical_update_exams_physical = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/exams_physical/update_exams_physical", payload);
        };
        // 							     EXPEDIENT CONSULT GENERAL EXAMS PHYSICAL
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL DIAGNOSIS
        this.expedient_consult_general_diagnosis_get_diagnosis = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/diagnosis/get_diagnosis", { params: payload });
        };
        this.expedient_consult_general_diagnosis_insert_diagnosis = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/diagnosis/insert_diagnosis", payload);
        };
        this.expedient_consult_general_diagnosis_update_diagnosis = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/diagnosis/update_diagnosis", payload);
        };
        this.expedient_consult_general_diagnosis_delete_diagnosis = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/consult/general/diagnosis/delete_diagnosis", {
                params: payload,
            });
        };
        // 							     EXPEDIENT CONSULT GENERAL DIAGNOSIS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL TREATMENT
        this.expedient_consult_general_treatment_get_treatment = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/treatment/get_treatment", { params: payload });
        };
        this.expedient_consult_general_treatment_insert_treatment = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/treatment/insert_treatment", payload);
        };
        this.expedient_consult_general_treatment_delete_treatment = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/consult/general/treatment/delete_treatment", {
                params: payload,
            });
        };
        // 							     EXPEDIENT CONSULT GENERAL TREATMENT
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL PRESCRIPTION
        this.expedient_consult_general_prescription_get_prescription = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/prescription/get_prescription", {
                params: payload,
            });
        };
        this.expedient_consult_general_prescription_insert_prescription = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/prescription/insert_prescription", payload);
        };
        this.expedient_consult_general_prescription_delete_prescription = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/consult/general/prescription/delete_prescription", {
                params: payload,
            });
        };
        // 							     EXPEDIENT CONSULT GENERAL PRESCRIPTION
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL RECOMMENDATION
        this.expedient_consult_general_recommendation_get_recommendation = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/recommendation/get_recommendation", {
                params: payload,
            });
        };
        this.expedient_consult_general_recommendation_update_recommendation = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/expedient/consult/general/recommendation/update_recommendation", payload);
        };
        // 							     EXPEDIENT CONSULT GENERAL RECOMMENDATION
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL PROCEDURES_REQUESTS
        this.expedient_consult_general_procedures_requests_get_procedures_requests = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/procedures_requests/get_procedures_requests", {
                params: payload,
            });
        };
        this.expedient_consult_general_procedures_requests_insert_procedures_requests = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/procedures_requests/insert_procedures_requests", payload);
        };
        this.expedient_consult_general_procedures_requests_delete_procedures_requests = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/consult/general/procedures_requests/delete_procedures_requests", {
                params: payload,
            });
        };
        this.expedient_consult_general_procedures_requests_pdf_procedures_requests_list = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/procedures_requests/pdf_procedures_requests_list", payload, { responseType: "blob" });
        };
        this.expedient_consult_general_procedures_requests_pdf_procedures_requests = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/procedures_requests/pdf_procedures_requests", payload, {
                responseType: "blob",
            });
        };
        // 							     EXPEDIENT CONSULT GENERAL PROCEDURES_REQUESTS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL PROCEDURES_RESULTS
        this.expedient_consult_general_procedures_results_get_procedures_results = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/procedures_results/get_procedures_results", {
                params: payload,
            });
        };
        this.expedient_consult_general_procedures_results_insert_procedures_results = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/procedures_results/insert_procedures_results", payload);
        };
        this.expedient_consult_general_procedures_results_delete_procedures_results = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/consult/general/procedures_results/delete_procedures_results", {
                params: payload,
            });
        };
        // 							     EXPEDIENT CONSULT GENERAL PROCEDURES_RESULTS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL REFERENCES
        this.expedient_consult_general_references_get_references = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/references/get_references", {
                params: payload,
            });
        };
        this.expedient_consult_general_references_insert_references = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/references/insert_references", payload);
        };
        this.expedient_consult_general_references_delete_references = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/consult/general/references/delete_references", {
                params: payload,
            });
        };
        this.expedient_consult_general_references_pdf_references = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/references/pdf_references", payload, {
                responseType: "blob",
            });
        };
        // 							     EXPEDIENT CONSULT GENERAL REFERENCES
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL EXAMS_RESULTS
        this.expedient_consult_general_exams_results_get_exams_results = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/exams_results/get_exams_results", {
                params: payload,
            });
        };
        this.expedient_consult_general_exams_results_insert_exams_results = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/exams_results/insert_exams_results", payload);
        };
        this.expedient_consult_general_exams_results_delete_exams_results = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/consult/general/exams_results/delete_exams_results", {
                params: payload,
            });
        };
        // 							     EXPEDIENT CONSULT GENERAL EXAMS_RESULTS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     EXPEDIENT CONSULT GENERAL EXAMS_REQUESTS
        this.expedient_consult_general_exams_requests_get_exams_requests = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/expedient/consult/general/exams_requests/get_exams_requests", {
                params: payload,
            });
        };
        this.expedient_consult_general_exams_requests_insert_exams_requests = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/exams_requests/insert_exams_requests", payload);
        };
        this.expedient_consult_general_exams_requests_delete_exams_requests = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/consult/general/exams_requests/delete_exams_requests", {
                params: payload,
            });
        };
        this.expedient_consult_general_exams_requests_pdf_exams_requests_list = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/exams_requests/pdf_exams_requests_list", payload, {
                responseType: "blob",
            });
        };
        this.expedient_consult_general_exams_requests_pdf_exams_requests = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/consult/general/exams_requests/pdf_exams_requests", payload, {
                responseType: "blob",
            });
        };
        // 							     EXPEDIENT CONSULT GENERAL EXAMS_REQUESTS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE
        this.profile_get_profile = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/get_profile", {
                params: payload,
            });
        };
        this.profile_upload_profile_image = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/upload_profile_image", payload);
        };
        this.profile_remove_profile_image = function () {
            return _this.httpClient.put(_this.endpoint + "/profile/remove_profile_image", {});
        };
        // 							     PROFILE
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE EXPERIENCES
        this.profile_experiences_get_experiences = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/experiences/get_experiences", { params: payload });
        };
        this.profile_experiences_insert_experiences = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/profile/experiences/insert_experiences", payload);
        };
        this.profile_experiences_update_experiences = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/experiences/update_experiences", payload);
        };
        this.profile_experiences_delete_experiences = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/profile/experiences/delete_experiences", { params: payload });
        };
        // 							     PROFILE EXPERIENCES
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE EDUCATIONS
        this.profile_educations_get_educations = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/educations/get_educations", { params: payload });
        };
        this.profile_educations_insert_educations = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/profile/educations/insert_educations", payload);
        };
        this.profile_educations_update_educations = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/educations/update_educations", payload);
        };
        this.profile_educations_delete_educations = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/profile/educations/delete_educations", { params: payload });
        };
        // 							     PROFILE EDUCATIONS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE INSURANCES
        this.profile_insurances_get_insurances = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/insurances/get_insurances", { params: payload });
        };
        this.profile_insurances_insert_insurances = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/profile/insurances/insert_insurances", payload);
        };
        this.profile_insurances_update_insurances = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/insurances/update_insurances", payload);
        };
        this.profile_insurances_delete_insurances = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/profile/insurances/delete_insurances", { params: payload });
        };
        // 							     PROFILE INSURANCES
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE MEMBERSHIPS
        this.profile_memberships_get_memberships = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/memberships/get_memberships", { params: payload });
        };
        this.profile_memberships_insert_memberships = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/profile/memberships/insert_memberships", payload);
        };
        this.profile_memberships_update_memberships = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/memberships/update_memberships", payload);
        };
        this.profile_memberships_delete_memberships = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/profile/memberships/delete_memberships", { params: payload });
        };
        // 							     PROFILE MEMBERSHIPS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE SPECIALTIES AREAS
        this.profile_specialties_areas_get_specialties_areas = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/specialties_areas/get_specialties_areas", { params: payload });
        };
        this.profile_specialties_areas_insert_specialties_areas = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/profile/specialties_areas/insert_specialties_areas", payload);
        };
        this.profile_specialties_areas_update_specialties_areas = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/specialties_areas/update_specialties_areas", payload);
        };
        this.profile_specialties_areas_delete_specialties_areas = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/profile/specialties_areas/delete_specialties_areas", {
                params: payload,
            });
        };
        // 							     PROFILE SPECIALTIES AREAS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE MY FOCUS
        this.profile_my_focus_get_my_focus = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/my_focus/get_my_focus", {
                params: payload,
            });
        };
        this.profile_my_focus_update_my_focus = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/my_focus/update_my_focus", payload);
        };
        // 							     PROFILE MY FOCUS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE PEOPLE MAY KNOW
        this.profile_people_may_know_get_people_may_know = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/people_may_know/get_people_may_know", { params: payload });
        };
        this.profile_people_may_know_insert_people_may_know = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/profile/people_may_know/insert_people_may_know", payload);
        };
        this.profile_people_may_know_update_people_may_know = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/people_may_know/update_people_may_know", payload);
        };
        this.profile_people_may_know_delete_people_may_know = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/profile/people_may_know/delete_people_may_know", { params: payload });
        };
        // 							     PROFILE PEOPLE MAY KNOW
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE LOCATIONS
        this.profile_locations_get_locations = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/locations/get_locations", { params: payload });
        };
        this.profile_locations_insert_locations = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/profile/locations/insert_locations", payload);
        };
        this.profile_locations_update_locations = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/locations/update_locations", payload);
        };
        this.profile_locations_delete_locations = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/profile/locations/delete_locations", { params: payload });
        };
        // 							     PROFILE LOCATIONS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     PROFILE CONENCTIONS
        this.profile_connections_get_connections = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/connections/get_connections", { params: payload });
        };
        this.profile_connections_insert_connections = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/profile/connections/insert_connections", payload);
        };
        this.profile_connections_delete_connections = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/profile/connections/delete_connections", { params: payload });
        };
        this.profile_connections_get_connection = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/connections/get_connection", { params: payload });
        };
        this.profile_connections_get_search_connections = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/profile/connections/get_search_connections", { params: payload });
        };
        this.profile_connections_accept_connection = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/connections/accept_connection", payload);
        };
        this.profile_connections_reject_connection = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/profile/connections/reject_connection", payload);
        };
        // 							     PROFILE CONENCTIONS
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     SETTINGS PROFILE
        this.settings_profile_get_profile = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/settings/profile/get_profile", {
                params: payload,
            });
        };
        this.settings_profile_update_profile = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/settings/profile/update_profile", payload);
        };
        // 							     SETTINGS PROFILE
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 							     SETTINGS SECURITY
        this.settings_security_get_security = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/settings/security/get_security", {
                params: payload,
            });
        };
        this.settings_security_update_security = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/settings/security/update_security", payload);
        };
        this.settings_security_verify_phone = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/settings/security/verify_phone", payload);
        };
        this.settings_security_verify_email = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/settings/security/verify_email", payload);
        };
        this.settings_security_send_verify_email = function (payload) {
            return _this.httpClient.put(_this.endpoint + "/settings/security/send_verify_email", payload);
        };
        // 							     SETTINGS SECURITY
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 		          					     CHAT
        this.chat_get_messages = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/chat/get_messages", {
                params: payload,
            });
        };
        this.chat_send_message = function (payload) { return _this.httpClient.post(_this.endpoint + "/chat/send_message", payload); };
        // 		          					     CHAT
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 		          					     WHATSAPP
        this.whatsapp_send_send_verify_code = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/whatsapp/send/send_verify_code", payload);
        };
        this.whatsapp_send_send_doctor_patient_request = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/whatsapp/send/send_doctor_patient_request", payload);
        };
        this.whatsapp_send_send_user_verify_phone = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/whatsapp/send/send_user_verify_phone", payload);
        };
        this.whatsapp_send_send_new_tutor_request = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/whatsapp/send/send_new_tutor_request", payload);
        };
        // 		          					     WHATSAPP
        // _______________________________________________________________________________________
        // _______________________________________________________________________________________
        // 		          					     DOCTORS PATIENTS REQUESTS
        this.expedient_doctors_patients_requests_insert_doctors_patients_request = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/doctors_patients_requests/insert_doctors_patients_request", payload);
        };
        this.expedient_doctors_patients_requests_insert_doctors_patients = function (payload) {
            return _this.httpClient.post(_this.endpoint + "/expedient/doctors_patients_requests/insert_doctors_patients", payload);
        };
        this.expedient_doctors_patients_requests_delete_doctors_patients_request = function (payload) {
            return _this.httpClient.delete(_this.endpoint + "/expedient/doctors_patients_requests/delete_doctors_patients_request", {
                params: payload,
            });
        };
        // 									   	  VITALS
        // _______________________________________________________________________________________
        //##################################################################################
        //CURRENT TIME #####################################################################
        this.get_current_date = function () { return _this.httpClient.get(_this.endpoint + "/get_current_date"); };
        //CURRENT TIME ######################################################################
        //###################################################################################
        //##########################################################################
        //NOTIFICACIONES ###########################################################
        this.get_notifications = function (payload) {
            return _this.httpClient.get(_this.endpoint + "/get_notifications", {
                params: payload,
            });
        };
        // 											Dates
        // _______________________________________________________________________________________
        this.jwt_parser = function (payload) { return _this.httpClient.post(_this.endpoint + "/jwt_parser", payload); };
        this.host = environment.doctor_server;
        console.info(this.host);
        this.endpoint = this.host + "/api";
        this.user_session_name = "doctor-unimed-user-session";
        this.patient_id = "unimed-patient-id";
    }
    Object.defineProperty(AppService.prototype, "pageTitle", {
        set: function (value) {
            this.titleService.setTitle("UNIMED Plataforma");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppService.prototype, "refresh$", {
        get: function () {
            return this._refresh$;
        },
        enumerable: true,
        configurable: true
    });
    AppService.prototype.get_file_endpoint = function (path, name) {
        return this.host + "/files_storage" + (path ? "/" + path : "") + "/" + name;
    };
    // OBTENER DISEASES TEMPORAL
    AppService.prototype.get_diseases = function () {
        return this.httpClient.get(this.endpoint + "/diseases_catalogs");
    };
    // 									   	  CATALOGS
    // _______________________________________________________________________________________
    // _______________________________________________________________________________________
    // 									   	  USER
    AppService.prototype.get_user = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_user", {
            params: payload,
        });
    };
    AppService.prototype.get_basic_user = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_basic_user", {
            params: payload,
        });
    };
    AppService.prototype.get_user_info = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_user_info", {
            params: payload,
        });
    };
    AppService.prototype.validate_insert_user = function (payload) {
        return this.httpClient.get(this.endpoint + "/validate_insert_user", {
            params: payload,
        });
    };
    AppService.prototype.validate_edit_user = function (payload) {
        return this.httpClient.get(this.endpoint + "/validate_edit_user", {
            params: payload,
        });
    };
    AppService.prototype.insert_user = function (payload) {
        return this.httpClient.post(this.endpoint + "/insert_user", payload);
    };
    AppService.prototype.get_consults = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_consults", {
            params: payload,
        });
    };
    AppService.prototype.get_my_consults = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_my_consults", {
            params: payload,
        });
    };
    AppService.prototype.get_my_patients = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_my_patients", {
            params: payload,
        });
    };
    AppService.prototype.get_consult_details = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_consult_details", {
            params: payload,
        });
    };
    AppService.prototype.get_patient_indicators = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_patient_indicators", {
            params: payload,
        });
    };
    AppService.prototype.insert_consult = function (payload) {
        return this.httpClient.post(this.endpoint + "/insert_consult", payload);
    };
    AppService.prototype.edit_user = function (payload) {
        return this.httpClient.put(this.endpoint + "/edit_user", payload);
    };
    AppService.prototype.delete_user = function (payload) {
        return this.httpClient.delete(this.endpoint + "/delete_user", {
            params: payload,
        });
    };
    AppService.prototype.delete_user_undo = function (payload) {
        return this.httpClient.delete(this.endpoint + "/delete_user_undo", {
            params: payload,
        });
    };
    AppService.prototype.change_password = function (payload) {
        return this.httpClient.put(this.endpoint + "/change_password", payload);
    };
    // 									   	  AUTH
    // _______________________________________________________________________________________
    // _______________________________________________________________________________________
    // 									   	  PATIENTS
    AppService.prototype.get_patients = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_patients", {
            params: payload,
        });
    };
    AppService.prototype.get_patient_detail = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_patient_detail", {
            params: payload,
        });
    };
    AppService.prototype.insert_patient_by_doctor = function (payload) {
        return this.httpClient.post(this.endpoint + "/insert_patient_by_doctor", payload);
    };
    AppService.prototype.update_patient_detail = function (payload) {
        return this.httpClient.put(this.endpoint + "/update_patient_detail", payload);
    };
    AppService.prototype.update_doctor_profile = function (payload) {
        return this.httpClient.put(this.endpoint + "/update_doctor_profile", payload);
    };
    AppService.prototype.update_specific_profile = function (payload) {
        return this.httpClient.put(this.endpoint + "/update_specific_profile", payload);
    };
    AppService.prototype.update_patient_profile = function (payload) {
        return this.httpClient.put(this.endpoint + "/update_patient_profile", payload);
    };
    // 		          					     DOCTORS PATIENTS REQUESTS
    // _______________________________________________________________________________________
    // _______________________________________________________________________________________
    // 									   	  MEDICINES
    AppService.prototype.get_chemical_names = function () {
        return this.httpClient.get(this.endpoint + "/get_chemical_names");
    };
    AppService.prototype.get_medicine_type = function () {
        return this.httpClient.get(this.endpoint + "/get_medicine_type");
    };
    AppService.prototype.get_medicines_details_catalogue = function () {
        return this.httpClient.get(this.endpoint + "/get_medicines_details_catalogue");
    };
    AppService.prototype.get_medicine = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_medicine", {
            params: payload,
        });
    };
    AppService.prototype.get_medicine_details = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_medicine_details", {
            params: payload,
        });
    };
    AppService.prototype.get_medicine_instructions = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_medicine_instructions", { params: payload });
    };
    AppService.prototype.insert_medicines = function (payload) {
        return this.httpClient.post(this.endpoint + "/insert_medicines", payload);
    };
    AppService.prototype.insert_medicine_instructions = function (payload) {
        return this.httpClient.post(this.endpoint + "/insert_medicine_instructions", payload);
    };
    AppService.prototype.insert_patients_other_medicines = function (payload) {
        return this.httpClient.post(this.endpoint + "/insert_patients_other_medicines", payload);
    };
    AppService.prototype.update_medicines = function (payload) {
        return this.httpClient.put(this.endpoint + "/update_medicines", payload);
    };
    AppService.prototype.update_medicine_instructions = function (payload) {
        return this.httpClient.put(this.endpoint + "/update_medicine_instructions", payload);
    };
    // 									   	  MEDICINES
    // _______________________________________________________________________________________
    // _______________________________________________________________________________________
    // 									   	  VITALS
    AppService.prototype.get_patients_vital_detail = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_patients_vital_detail", { params: payload });
    };
    AppService.prototype.get_patients_vitals = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_patients_vitals", {
            params: payload,
        });
    };
    AppService.prototype.insert_patient_vitals = function (payload) {
        return this.httpClient.post(this.endpoint + "/insert_patient_vitals", payload);
    };
    AppService.prototype.update_patient_vitals = function (payload) {
        return this.httpClient.put(this.endpoint + "/update_patient_vitals", payload);
    };
    AppService.prototype.read_notifications = function (payload) {
        return this.httpClient.put(this.endpoint + "/read_notifications", payload);
    };
    AppService.prototype.read_all_notifications = function (payload) {
        return this.httpClient.put(this.endpoint + "/read_all_notifications", payload);
    };
    AppService.prototype.createObservable = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.observer = observer;
        });
    };
    // 											MEDICINES
    // _______________________________________________________________________________________
    AppService.prototype.get_medicines_prescription = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_medicines_prescription", { params: payload });
    };
    AppService.prototype.insert_medicines_prescription = function (payload) {
        return this.httpClient.post(this.endpoint + "/insert_medicine_prescription", payload);
    };
    AppService.prototype.update_medicines_prescription = function (payload) {
        return this.httpClient.put(this.endpoint + "/update_medicine_prescription", payload);
    };
    AppService.prototype.delete_medicines_prescription = function (payload) {
        return this.httpClient.delete(this.endpoint + "/delete_medicine_prescription", { params: payload });
    };
    // 											MEDICINES
    // _______________________________________________________________________________________
    // 											Dates
    // _______________________________________________________________________________________
    AppService.prototype.get_dates_by_doctor = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_dates_by_doctor", {
            params: payload,
        });
    };
    AppService.prototype.get_dates_of_patients = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_dates_of_patient", {
            params: payload,
        });
    };
    AppService.prototype.get_consults_by_doctor = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_consults_doctor", {
            params: payload,
        });
    };
    AppService.prototype.insert_dates = function (payload) {
        var _this = this;
        return this.httpClient.post(this.endpoint + "/insert_dates", payload).pipe(tap(function () {
            _this.refresh$.next();
        }));
    };
    AppService.prototype.update_dates = function (payload) {
        var _this = this;
        return this.httpClient.put(this.endpoint + "/update_dates", payload).pipe(tap(function () {
            _this.refresh$.next();
        }));
    };
    AppService.prototype.update_state_date = function (payload) {
        var _this = this;
        return this.httpClient.put(this.endpoint + "/update_state", payload).pipe(tap(function () {
            _this.refresh$.next();
        }));
    };
    AppService.prototype.delete_dates = function (payload) {
        var _this = this;
        return this.httpClient.delete(this.endpoint + "/delete_dates", { params: payload }).pipe(tap(function () {
            _this.refresh$.next();
        }));
    };
    AppService.prototype.get_dates_specific = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_dates_specific", {
            params: payload,
        });
    };
    AppService.prototype.get_data_doctor = function (payload) {
        return this.httpClient.get(this.endpoint + "/get_data_doctor", {
            params: payload,
        });
    };
    return AppService;
}());
export { AppService };
