import { FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(formBuilder, endpoint, activeRoute, toastr) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.endpoint = endpoint;
        this.activeRoute = activeRoute;
        this.toastr = toastr;
        this.release_year = 2020;
        this.year = new Date().getFullYear();
        this.password_regex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/g;
        this.submitted = false;
        this.error = '';
        this.loading = false;
        this.success = '';
        this.passwordMatch = function () { return _this.f.password.value != _this.f.confirm_password.value; };
        this.endpoint.pageTitle = 'Restaurar Contraseña';
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        this.reset_token = this.activeRoute.snapshot.params.token;
        this.resetForm = this.formBuilder.group({
            email: [
                '',
                [
                    Validators.required,
                    Validators.email
                ]
            ],
            password: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.password_regex),
                    Validators.minLength(8)
                ]
            ],
            confirm_password: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.password_regex),
                    Validators.minLength(8)
                ]
            ],
        });
    };
    Object.defineProperty(ResetPasswordComponent.prototype, "f", {
        get: function () { return this.resetForm.controls; },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.resetForm.invalid || this.f.password.value != this.f.confirm_password.value) {
            return;
        }
        var params = {
            email: this.f.email.value,
            password: this.f.password.value,
            confirm_password: this.f.confirm_password.value,
            token: this.reset_token
        };
        this.loading = true;
        var response;
        this.endpoint.reset_password(params, this.reset_token)
            .subscribe(function (data) { return response = data; }, function (err) {
            if (err.error.code == "recovery") {
                _this.endpoint.redirect_to('/recuperar_contrasena');
            }
            _this.toastr.error(err.error.message ? err.error.message : err.message, err.error.title ? err.error.title : err.status + '');
            _this.error = err.error.message;
            _this.loading = false;
        }, function () {
            Swal.fire({
                title: response.title,
                html: response.message,
                type: 'success',
                confirmButtonText: 'Listo',
                confirmButtonColor: '#66c8c8',
            }).then(function (result) {
            });
            _this.endpoint.redirect_to('/conectarse');
        });
    };
    ;
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
