import { OnInit, EventEmitter } from '@angular/core';
var BoldListComponent = /** @class */ (function () {
    function BoldListComponent() {
        this.update_bold_list = new EventEmitter();
        this.events_bold_list = new EventEmitter();
        this.possible_indexes = new Array(7).fill(-1);
        this.contents = [];
        this.headers = [];
        this.data = {
            skip: 0,
            count: 0,
            list: []
        };
        this.filters = {
            search_word: "",
            skip: 0,
            limit: 10
        };
        this.events = [];
    }
    Object.defineProperty(BoldListComponent.prototype, "in_headers", {
        set: function (headers) {
            this.headers = headers;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(BoldListComponent.prototype, "in_events", {
        set: function (events) {
            this.events = events; //.filter(value => value.show ? value.show(value) : true);
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(BoldListComponent.prototype, "in_contents", {
        set: function (contents) {
            this.contents = contents;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(BoldListComponent.prototype, "in_data", {
        set: function (data) {
            this.data = data;
            if (data.skip)
                this.filters.skip = data.skip;
            this.calculate_pagination();
            this.loading = false;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(BoldListComponent.prototype, "in_filters", {
        set: function (filters) {
            this.filters = filters;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(BoldListComponent.prototype, "in_configs", {
        set: function (configs) {
            this.configs = configs;
        },
        enumerable: true,
        configurable: true
    });
    ;
    BoldListComponent.prototype.ngOnInit = function () {
        this.init();
    };
    BoldListComponent.prototype.init = function () {
        this.update();
    };
    BoldListComponent.prototype.update = function () {
        this.loading = true;
        this.update_bold_list.emit(this.filters);
    };
    BoldListComponent.prototype.orderBy = function (label) {
        this.filters.order_asc = this.filters.order_by == label ? !this.filters.order_asc : true;
        this.filters.order_by = label;
        this.update();
    };
    BoldListComponent.prototype.goLast = function () {
        this.filters.last = true;
        this.update();
    };
    BoldListComponent.prototype.goFirst = function () {
        this.filters.last = false;
        this.filters.skip = 0;
        this.update();
    };
    BoldListComponent.prototype.go = function (step) {
        this.filters.last = false;
        this.filters.skip += step;
        this.update();
    };
    BoldListComponent.prototype.filter = function () {
        this.filters.skip = 0;
        this.update();
    };
    BoldListComponent.prototype.cleanFilters = function () {
        this.filters.search_word = null;
        this.update();
    };
    BoldListComponent.prototype.event = function (name, item) {
        if (this.configs.open)
            this.events_bold_list.emit({ name: name, item: item });
    };
    BoldListComponent.prototype.calculate_pagination = function () {
        var _this = this;
        this.divider = Math.floor(this.data.count / this.filters.limit);
        this.filters.skip = parseInt(this.data.skip + '');
        this.possible_indexes = [
            this.filters.skip - 1,
            this.filters.skip - 2,
            this.filters.skip - 3,
            this.filters.skip,
            this.filters.skip + 1,
            this.filters.skip + 2,
            this.filters.skip + 3,
        ].map(function (value) {
            if (value < 0)
                value += 7;
            if (value > _this.divider || (_this.data.count % _this.filters.limit == 0 && value >= _this.divider))
                value -= 7;
            return value;
        }).sort(function (a, b) { return a - b; });
    };
    BoldListComponent.prototype.calculate_cols = function () {
        return 100 / this.headers.length;
    };
    return BoldListComponent;
}());
export { BoldListComponent };
