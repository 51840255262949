import { OnInit, OnDestroy, ElementRef } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { ExpedientService } from "src/app/services/expedient.service";
import { PageService } from "src/app/services/page.service";
var NewPatientComponent = /** @class */ (function () {
    function NewPatientComponent(endpoint, pageService, expedientService) {
        this.endpoint = endpoint;
        this.pageService = pageService;
        this.expedientService = expedientService;
        localStorage.removeItem("unimed-expedient-general");
        localStorage.removeItem("unimed-expedient-contacts-emergency");
        localStorage.removeItem("unimed-expedient-contacts-emergency-list");
        localStorage.removeItem("unimed-expedient-contacts-doctor");
        localStorage.removeItem("unimed-expedient-contacts-doctor-list");
        localStorage.removeItem("unimed-expedient-background-pathologies");
        localStorage.removeItem("unimed-expedient-background-pathologies-list");
        localStorage.removeItem("unimed-expedient-background-allergies");
        localStorage.removeItem("unimed-expedient-background-allergies-list");
        localStorage.removeItem("unimed-expedient-insurances-accident");
        localStorage.removeItem("unimed-expedient-insurances-accident-list");
        localStorage.removeItem("unimed-expedient-relations-dependants");
        localStorage.removeItem("unimed-expedient-relations-dependants-list");
        localStorage.removeItem("unimed-expedient-relations-tutors");
        localStorage.removeItem("unimed-expedient-relations-tutors-list");
        localStorage.removeItem("unimed-expedient-institutions");
        localStorage.removeItem("unimed-expedient-institutions-list");
    }
    NewPatientComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.page_subscription = this.pageService.current.subscribe(function (state) {
            _this.page_state = state;
            _this.dynamic_tabs_config();
        });
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) { return (_this.expedient_state = state); });
    };
    NewPatientComponent.prototype.ngOnDestroy = function () {
        this.page_subscription.unsubscribe();
        this.expedient_subscription.unsubscribe();
    };
    NewPatientComponent.prototype.dynamic_tabs_config = function () {
        var _this = this;
        this.dynamic_tabs = {
            selected: 0,
            list: [
                {
                    class: "mx-2",
                    name: "general",
                    active_stroke: "4px",
                    font_size: "0.9rem",
                    click: function () {
                        _this.general_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        _this.dynamic_tabs.selected = 0;
                    },
                },
                {
                    class: "mx-2",
                    name: "contactos",
                    active_stroke: "4px",
                    font_size: "0.9rem",
                    click: function () {
                        _this.contacts_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        _this.dynamic_tabs.selected = 1;
                    },
                },
                {
                    class: "mx-2",
                    name: "antecedentes",
                    active_stroke: "4px",
                    font_size: "0.9rem",
                    click: function () {
                        _this.background_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        _this.dynamic_tabs.selected = 2;
                    },
                },
                // {
                //     class: "mx-2",
                //     name: "seguros",
                //     active_stroke: "4px",
                //     font_size: "0.9rem",
                //     click: () => {
                //         this.insurance_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                //         this.dynamic_tabs.selected = 3;
                //     },
                // },
                {
                    class: "mx-2",
                    name: "relaciones",
                    active_stroke: "4px",
                    font_size: "0.9rem",
                    click: function () {
                        _this.relations_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        _this.dynamic_tabs.selected = 4;
                    },
                },
            ],
        };
    };
    NewPatientComponent.prototype.isInViewport = function (element) {
        var rect = element.getBoundingClientRect();
        return (rect.top >= 0 &&
            rect.left >= 0 &&
            //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth));
    };
    NewPatientComponent.prototype.isScrolling = function () {
        if (this.isInViewport(this.general_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 0) {
                this.dynamic_tabs.selected = 0;
            }
        }
        else if (this.isInViewport(this.contacts_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 1) {
                this.dynamic_tabs.selected = 1;
            }
        }
        else if (this.isInViewport(this.background_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 2) {
                this.dynamic_tabs.selected = 2;
            }
        }
        else if (this.isInViewport(this.insurance_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 3) {
                this.dynamic_tabs.selected = 3;
            }
        }
        else if (this.isInViewport(this.insurance_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 4) {
                this.dynamic_tabs.selected = 4;
            }
        }
        else if (this.isInViewport(this.institution_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 5) {
                this.dynamic_tabs.selected = 5;
            }
        }
    };
    NewPatientComponent.prototype.cancel = function () {
        this.endpoint.redirect_to("/mis_pacientes");
    };
    NewPatientComponent.prototype.submit = function () {
        this.expedientService.submit_expedient_services();
    };
    return NewPatientComponent;
}());
export { NewPatientComponent };
