import { AccountService } from '../services/account.service';
import { AppService } from '../services/app.service';
import { PageService } from '../services/page.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/page.service";
import * as i2 from "../services/app.service";
import * as i3 from "../services/account.service";
var NoAuthGuard = /** @class */ (function () {
    function NoAuthGuard(pageService, endpoint, accountService) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.accountService = accountService;
    }
    NoAuthGuard.prototype.canActivate = function () {
        if (this.endpoint.get_session() && this.endpoint.get_session().token) {
            this.endpoint.redirect_to('');
        }
        else {
            this.accountService.reset();
            return true;
        }
    };
    NoAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoAuthGuard_Factory() { return new NoAuthGuard(i0.ɵɵinject(i1.PageService), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.AccountService)); }, token: NoAuthGuard, providedIn: "root" });
    return NoAuthGuard;
}());
export { NoAuthGuard };
