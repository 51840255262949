import { PageService } from '../services/page.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/page.service";
var MyPatientsGuard = /** @class */ (function () {
    function MyPatientsGuard(pageService) {
        this.pageService = pageService;
    }
    MyPatientsGuard.prototype.canActivate = function () {
        this.pageService.set_sidebar_2_state({
            expand: false,
            scroll: true,
            show: false
        });
        this.pageService.set_sidebar_3_state({
            expand: false,
            scroll: true,
            show: true
        });
        this.pageService.set_consult(false);
        this.pageService.set_profile(false);
        this.pageService.set_expedient(false);
        return true;
    };
    MyPatientsGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MyPatientsGuard_Factory() { return new MyPatientsGuard(i0.ɵɵinject(i1.PageService)); }, token: MyPatientsGuard, providedIn: "root" });
    return MyPatientsGuard;
}());
export { MyPatientsGuard };
