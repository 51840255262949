import { OnInit, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(pageService, patientService, accountService, endpoint) {
        this.pageService = pageService;
        this.patientService = patientService;
        this.accountService = accountService;
        this.endpoint = endpoint;
        this.avatar_configs = { size: "1.5rem" }; //1.125rem
    }
    SidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patient_subscription = this.patientService.patient_data_subscription.subscribe(function (data) {
            _this.patient_data = data;
            _this.stored_patient_id = _this.endpoint.get_patient_id();
        });
        this.page_subscription = this.pageService.current.subscribe(function (state) {
            _this.page_state = state;
        });
        this.account_subscription = this.accountService.current.subscribe(function (state) {
            _this.account_state = state;
            _this.stored_patient_id = _this.endpoint.get_patient_id();
        });
    };
    SidebarComponent.prototype.ngOnDestroy = function () {
        this.patient_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
        this.account_subscription.unsubscribe();
    };
    SidebarComponent.prototype.ngAfterViewInit = function () {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
        this._activateMenuDropdown();
    };
    SidebarComponent.prototype._activateMenuDropdown = function () {
        var links = document.getElementsByClassName('side-nav-link-ref');
        var menuItemEl = null;
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < links.length; i++) {
            // tslint:disable-next-line: no-string-literal
            if (window.location.pathname === links[i]['pathname']) {
                menuItemEl = links[i];
                break;
            }
        }
        if (menuItemEl) {
            menuItemEl.classList.add('active');
            var parentEl = menuItemEl.parentElement;
            if (parentEl) {
                parentEl.classList.add('active');
                var parent2El = parentEl.parentElement;
                if (parent2El) {
                    parent2El.classList.add('in');
                }
                var parent3El = parent2El.parentElement;
                if (parent3El) {
                    parent3El.classList.add('active');
                    parent3El.firstChild.classList.add('active');
                }
            }
        }
    };
    SidebarComponent.prototype.set_logout = function () {
        var _this = this;
        this.endpoint.logout().subscribe(function () {
            _this.endpoint.reset_session();
            _this.endpoint.redirect_to('/conectarse');
        });
    };
    SidebarComponent.prototype.validate = function () {
        if (localStorage.getItem("detail")) {
            localStorage.removeItem("detail");
        }
    };
    return SidebarComponent;
}());
export { SidebarComponent };
