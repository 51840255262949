import * as tslib_1 from "tslib";
import { CustomClass } from "../custom-class";
var EducationsClass = /** @class */ (function (_super) {
    tslib_1.__extends(EducationsClass, _super);
    function EducationsClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    EducationsClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.id_type = data.id_type;
        this.id_study_type = data.id_study_type;
        this.id_mode = data.id_mode;
        this.id_honor = data.id_honor;
        this.id_country = data.id_country;
        this.name = data.name;
        this.place = data.place;
        this.year = data.year;
        this.description = data.description;
    };
    EducationsClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_user = null;
        this.id_type = 1;
        this.id_study_type = 1;
        this.id_mode = 1;
        this.id_honor = null;
        this.id_country = 97;
        this.name = "";
        this.place = "";
        this.year = new Date();
        this.description = "";
    };
    EducationsClass.prototype.format = function () {
        return this;
    };
    EducationsClass.prototype.valid = function () {
        return true;
    };
    EducationsClass.prototype.to_string = function () {
        var _this = this;
        var type = this.catalogsService.catalogs.profiles_educations_types.find(function (v) { return v.id == _this.id; });
        var study_type = this.catalogsService.catalogs.profiles_educations_study_types.find(function (v) { return v.id == _this.id; });
        var mode = this.catalogsService.catalogs.profiles_educations_modes.find(function (v) { return v.id == _this.id; });
        var honor = this.catalogsService.catalogs.profiles_educations_honors.find(function (v) { return v.id == _this.id; });
        var data = {
            type: type ? type.name : "",
            study_type: study_type ? study_type.name : "",
            mode: mode ? mode.name : "",
            honor: honor ? honor.name : "",
            name: this.name,
            place: this.place,
            year: this.year,
            description: this.description,
        };
        return data;
    };
    EducationsClass.prototype.raw = function () {
        return {
            id: this.id,
            id_user: this.id_user,
            id_type: this.id_type,
            id_study_type: this.id_study_type,
            id_mode: this.id_mode,
            id_honor: this.id_honor,
            id_country: this.id_country,
            name: this.name,
            place: this.place,
            year: this.year,
            description: this.description,
        };
    };
    return EducationsClass;
}(CustomClass));
export { EducationsClass };
