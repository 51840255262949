import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { AllergiesService } from '../allergies.service';
import { ExpedientService } from 'src/app/services/expedient.service';
var FormComponent = /** @class */ (function () {
    function FormComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            // this.moduleService.reload();
            _this.form_configs();
        });
        this.allergies_subscription = this.moduleService.current.subscribe(function (state) { return _this.allergies_state = state; });
        this.form_configs();
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        if (this.allergies_state) {
            this.moduleService.forms.allergies = this.form_ref;
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.allergies_subscription.unsubscribe();
    };
    FormComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return _this.expedient_state.method == "show"; },
            disabled: function () { return _this.expedient_state.method == "show"; },
            submit: function (callback) { return _this.moduleService.submit(callback); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "allergen",
                            type: "text",
                            label: "Alérgeno",
                            placeholder: function () { return "Ej: La piña, polvo, huevo, maní"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 45; }
                                }
                            },
                            disabled: function () { return false; },
                            tooltip: {
                                text: "Es una sustancia que puede provocar una reacción alérgica.\n(Ej: La piña, polvo, huevo, maní)",
                                icon: "fa-duotone fa-question pointer text-info ml-1",
                                class: ""
                            }
                        },
                        {
                            name: "id_allergies_reactions_type",
                            type: "select",
                            label: "Tipo de Reacción",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.moduleService.catalogs.allergies_reactions_types; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                            tooltip: {
                                text: "Rash = Sarpullido/Erupción.\n" +
                                    "Dermatitis atópica = Enrojecimiento de piel y picazón.\n" +
                                    "Anafilaxis: molestia u opresión en el pecho, dificultad para respirar, tos, dificultad para tragar.\n" +
                                    "Conjuntivitis alérgica: ojo rojo.\n" +
                                    "Rinitis alérgica: estornudos",
                                icon: "fa-duotone fa-question pointer text-info ml-1",
                                class: "pre"
                            }
                        },
                        {
                            name: "id_allergies_reactions_level",
                            type: "select",
                            label: "Nivel de Reacción",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.moduleService.catalogs.allergies_reactions_levels; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                            tooltip: {
                                text: "Gravedad del tipo de reacción",
                                icon: "fa-duotone fa-question pointer text-info ml-1",
                                class: ""
                            }
                        },
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    click: function () {
                                        _this.moduleService.cancel();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cerrar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "show";
                                    },
                                    click: function () {
                                        _this.moduleService.close();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Guardar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    disabled: function () { return (_this.form_ref ? !_this.form_ref.valid() : false) || _this.allergies_state.loading; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    return FormComponent;
}());
export { FormComponent };
