import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./general/general.module.ngfactory").then(function (m) { return m.GeneralModuleNgFactory; }); };
var routes = [
    { path: '', redirectTo: '/expediente/nueva-consulta/general/general', pathMatch: 'full' },
    { path: 'general', loadChildren: ɵ0 },
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0 };
