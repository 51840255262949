import { PageService } from 'src/app/services/page.service';
var ShowRelationsTutorsComponent = /** @class */ (function () {
    function ShowRelationsTutorsComponent(pageService) {
        this.pageService = pageService;
    }
    ShowRelationsTutorsComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowRelationsTutorsComponent;
}());
export { ShowRelationsTutorsComponent };
