import { PageService } from 'src/app/services/page.service';
var ShowContactsEmergencyComponent = /** @class */ (function () {
    function ShowContactsEmergencyComponent(pageService) {
        this.pageService = pageService;
    }
    ShowContactsEmergencyComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowContactsEmergencyComponent;
}());
export { ShowContactsEmergencyComponent };
