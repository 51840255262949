import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { PageService } from "src/app/services/page.service";
import { PatientService, } from "src/app/services/patient-service.service";
import { BoldListComponent, } from "src/app/shared/ui/bold_list/bold_list.component";
import { SearchButtonComponent } from "src/app/shared/ui/search_button/search_button.component";
import { ConsultGeneralService } from "../new_consult/general/consult.service";
var ConsultsComponent = /** @class */ (function () {
    function ConsultsComponent(endpoint, patientService, pageService, consultService) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.pageService = pageService;
        this.consultService = consultService;
        this.data = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false,
        };
        this.bold_list_config();
        this.only_mine = false;
    }
    ConsultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patient_subscription =
            this.patientService.patient_data_subscription.subscribe(function (data) {
                _this.patient_state = data;
                if (_this.bold_list_ref)
                    _this.bold_list_ref.update();
            });
        this.page_subscription = this.pageService.current.subscribe(function (state) { return (_this.page_state = state); });
    };
    ConsultsComponent.prototype.ngOnDestroy = function () {
        this.patient_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
    };
    ConsultsComponent.prototype.bold_list_config = function () {
        var _this = this;
        this.bold_list = {
            configs: {
                title: "Consultas",
                //icon: "fa-duotone fa- bi bi-calendar-check",
                paginate: true,
                search: true,
                open: true,
                selected: function (item) {
                    return item.isMine == 1;
                },
                big_icon: function (item) { return ({
                    icon: (item.id_consults_type == "1"
                        ? "fa-duotone fa-stethoscope text-primary"
                        : item.id_consults_type == "2"
                            ? "fa-duotone fa-syringe text-primary"
                            : item.id_consults_type == "3"
                                ? "fa-duotone fa-file-medical text-primary"
                                : item.id_consults_type == "4"
                                    ? "fa-duotone fa-light-emergency-on text-primary"
                                    : "fa-duotone fa-stethoscope text-secondary") +
                        " fa-lg pr-3",
                    title: item.id == _this.consultService.get_storage()
                        ? "Actual"
                        : item.consults_status_name,
                }); },
            },
            filters: {
                limit: 50,
                skip: 0,
                order_asc: false,
                order_by: "created_at",
            },
            headers: [
                {
                    label: "Diagnóstico",
                    name: "diagnosis",
                    order_by: "diagnosis",
                },
                {
                    label: "Médico",
                    name: "name_doctor_educations",
                    order_by: "doctor_name",
                },
                {
                    label: "Fecha",
                    name: "created_at",
                    order_by: "created_at",
                    type: "date",
                    class: "secondary",
                },
                {
                    label: "Estado",
                    name: "icon_status",
                    order_by: "id_consults_status",
                    type: "icon",
                },
            ],
            contents: [
            // (item: any) =>
            //     "Dr. " +
            //     item.doctor_name +
            //     (item.doctor_job_place ? "," + item.doctor_job_place : ""),
            ],
            events: [
                // {
                //     name: "Detalles",
                //     icon: "fa-duotone fa-file-circle-info text-info mr-1",
                // },
                {
                    name: "Editar",
                    icon: "fa-duotone fa-edit text-warning",
                    disabled: function (item) { return !(item.isMine == 1 &&
                        new Date() < new Date(item.expiresAt) &&
                        (item.id_consults_status == 1 ||
                            item.id_consults_status == 2 ||
                            item.id_consults_status == 3)); },
                },
                {
                    name: "Cancelar",
                    icon: "fa-duotone fa-ban text-danger",
                    disabled: function (item) { return !(item.isMine == 1 &&
                        new Date() < new Date(item.expiresAt) &&
                        (item.id_consults_status == 1 ||
                            item.id_consults_status == 2 ||
                            item.id_consults_status == 3)); },
                }
            ],
        };
    };
    ConsultsComponent.prototype.update = function (data) {
        var _this = this;
        if (this.patient_state && this.patient_state.general.id_user) {
            if (this.search_button_ref)
                data.search_word = this.search_button_ref.data;
            var load = tslib_1.__assign({ id: this.patient_state.general.id_user }, data, { only_mine: this.only_mine });
            this.endpoint.get_consults(load).subscribe(function (data) {
                data.icon = "fa-duotone fa-times";
                _this.data = data;
                _this.data.list.forEach(function (item) {
                    item.icon_status = (item.id == _this.consultService.get_storage()
                        ? "fa-duotone fa-play text-primary"
                        : item.id_consults_status == "1"
                            ? "fa-duotone fa-clock text-warning"
                            : item.id_consults_status == "2"
                                ? "fa-duotone fa-clock text-warning"
                                : item.id_consults_status == "3"
                                    ? "fa-duotone fa-check text-success"
                                    : item.id_consults_status == "4"
                                        ? "fa-duotone fa-ban text-danger"
                                        : "fa-duotone fa-times text-secondary") +
                        " fa-lg pl-4";
                });
            }, function (err) { return console.error(err); });
        }
    };
    ConsultsComponent.prototype.events = function (data) {
        var _this = this;
        this.selected_item = data.item;
        if (data.name == "Detalles" || data.name == "row_click") {
            // this.show_consult = true;            
            this.endpoint.redirect_to("/expediente/consulta/" + this.selected_item.id);
        }
        else if (data.name == "Editar") {
            this.bold_list_ref.loading = true;
            this.consultService.save_storage(this.selected_item.id);
            this.endpoint.redirect_to("/expediente/nueva-consulta");
        }
        else if (data.name == "Cancelar") {
            this.consultService.cancel(this.selected_item.id, function () {
                _this.on_update();
            });
        }
    };
    ConsultsComponent.prototype.on_update = function () {
        if (this.bold_list_ref)
            this.bold_list_ref.update();
    };
    ConsultsComponent.prototype.reset_consult = function () {
        this.consultService.remove_storage();
        this.consultService.init_consult();
        this.endpoint.redirect_to("/expediente/nueva-consulta/general/general");
    };
    ConsultsComponent.prototype.checkbox_check = function () {
        this.only_mine = !this.only_mine;
        this.on_update();
    };
    return ConsultsComponent;
}());
export { ConsultsComponent };
