import { OnDestroy, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(endpoint, pageService) {
        this.endpoint = endpoint;
        this.pageService = pageService;
        this.pageService.set_sidebar_2_state({
            show: false,
            scroll: true, expand: false
        });
    }
    HomeComponent.prototype.ngOnInit = function () {
    };
    HomeComponent.prototype.ngOnDestroy = function () {
    };
    return HomeComponent;
}());
export { HomeComponent };
