import { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { FormRenderComponent } from "src/app/shared/ui/form_render/form_render.component";
import { FileUploadControl, FileUploadValidators } from "@iplab/ngx-file-upload";
import { GeneralService } from "../general.service";
import { AppService } from "src/app/services/app.service";
import { ExpedientService } from "src/app/services/expedient.service";
import { CatalogsService } from "src/app/services/catalogs.service";
var FormComponent = /** @class */ (function () {
    function FormComponent(moduleService, endpoint, expedientService, catalogsService) {
        this.moduleService = moduleService;
        this.endpoint = endpoint;
        this.expedientService = expedientService;
        this.catalogsService = catalogsService;
        this.avatar_configs = { size: "8rem" };
        this.fileUploadControl = new FileUploadControl(FileUploadValidators.filesLimit(1));
        this.fileUploadProgress = null;
        this.uploadedFilePath = null;
        this.current_date = new Date();
        this.cat_organ_donor = [{ id: 1, name: "Donador de Órganos" }];
        this.cat_glasses = [{ id: 1, name: "Uso de lentes" }];
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            _this.moduleService.reload();
            _this.form_configs();
        });
        this.general_subscription = this.moduleService.current.subscribe(function (state) { return (_this.general_state = state); });
        this.form_configs();
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        if (this.general_state) {
            this.moduleService.forms.general = this.form_ref;
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.general_subscription.unsubscribe();
    };
    FormComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return _this.expedient_state.version == "expedient" && _this.expedient_state.method == "show"; },
            disabled: function () { return _this.expedient_state.version == "expedient" && _this.expedient_state.method == "show"; },
            submit: function (callback) { return _this.moduleService.submit(callback); },
            version: 2,
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            type: "divider",
                            label: "Demográficos",
                            col_class: "col-12 mb-2",
                        },
                        {
                            name: "firstname",
                            type: "name",
                            label: "Nombres",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Nombre"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                                maxlength: {
                                    value: function () { return 100; },
                                },
                            },
                            disabled: function () { return false; },
                            change: function () {
                                _this.general_state.firstname = _this.general_state.firstname.trim();
                            },
                            input: function () { return (_this.general_state.firstname = _this.general_state.firstname.trim()); },
                        },
                        {
                            name: "lastname",
                            type: "name",
                            label: "Apellidos",
                            small: "(primero, segundo, ...)",
                            placeholder: function () { return "Primer, Segundo... Apellido"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                                maxlength: {
                                    value: function () { return 100; },
                                },
                            },
                            disabled: function () { return false; },
                            change: function () {
                                _this.general_state.lastname = _this.general_state.lastname.trim();
                            },
                            input: function () { return (_this.general_state.lastname = _this.general_state.lastname.trim()); },
                        },
                        {
                            name: "birthdate",
                            type: "calendar",
                            label: "Fecha de Nacimiento",
                            placeholder: function () { return "##/##/####"; },
                            col_class: "col-md-4",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                                max: {
                                    value: function () { return _this.current_date; },
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "identification",
                            type: "identification",
                            label: "Identificación",
                            hn: function () { return _this.general_state.id_country == 97; },
                            placeholder: function () { return "####-####-#####"; },
                            col_class: "col-md-4",
                            class: this.expedient_state.version != "new_patient" ? "unstyle no-border" : "",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                            },
                            disabled: function () { return _this.expedient_state.version != "new_patient"; },
                        },
                        {
                            name: "id_country",
                            type: "select",
                            label: "Nacionalidad",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-4",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.countries; },
                                value: "id",
                                text: "name",
                            },
                            disabled: function () { return false; },
                        }, {
                            name: "id_gender",
                            type: "radio",
                            label: "Género",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.genders; },
                                value: "id",
                                text: "name",
                            },
                            disabled: function () { return false; },
                            change: function () {
                                _this.moduleService.save();
                            },
                        },
                        {
                            type: "divider",
                            label: "",
                            bar: true,
                            col_class: "col-12 mb-3",
                        },
                        {
                            name: "phone",
                            type: "phone",
                            hn: function () { return _this.general_state.id_country == "97"; },
                            label: "Teléfono",
                            small: "",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-5",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                                maxlength: {
                                    value: function () { return 30; },
                                },
                            },
                            input: function () {
                                _this.general_state.verified_phone =
                                    !_this.general_state.phone || _this.general_state.v_phone != _this.general_state.phone
                                        ? 2
                                        : 1;
                            },
                            change: function () {
                                _this.general_state.verified_phone =
                                    !_this.general_state.phone || _this.general_state.v_phone != _this.general_state.phone
                                        ? 2
                                        : 1;
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "verified_phone",
                            type: "select",
                            label: "Verificado",
                            small: "",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-5",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.tf; },
                                text: "name",
                                value: "id",
                            },
                            disabled: function () { return true; },
                        },
                        {
                            type: "buttons",
                            col_class: "col-md-2",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-primary mx-auto",
                                    label: "Verificar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: "right",
                                    },
                                    show: function () { return _this.expedient_state.method != "show"; },
                                    click: function () {
                                        _this.moduleService.verify_phone();
                                    },
                                    disabled: function () {
                                        return !_this.general_state.phone ||
                                            _this.general_state.verified_phone == 1 ||
                                            _this.general_state.loading;
                                    },
                                },
                            ],
                        },
                        {
                            name: "email",
                            type: "email",
                            label: "Correo Electrónico",
                            small: "",
                            placeholder: function () { return "alguien@email.com"; },
                            col_class: "col-md-5",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                            },
                            input: function () {
                                _this.general_state.verified_email =
                                    !_this.general_state.email || _this.general_state.v_email != _this.general_state.email
                                        ? 2
                                        : 1;
                            },
                            change: function () {
                                _this.general_state.verified_email =
                                    !_this.general_state.email || _this.general_state.v_email != _this.general_state.email
                                        ? 2
                                        : 1;
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "verified_email",
                            type: "select",
                            label: "Verificado",
                            small: "",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-5",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.tf; },
                                text: "name",
                                value: "id",
                            },
                            disabled: function () { return true; },
                        },
                        {
                            type: "buttons",
                            col_class: "col-md-2",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-primary mx-auto",
                                    label: "Verificar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: "right",
                                    },
                                    show: function () { return _this.expedient_state.method != "show"; },
                                    click: function () {
                                        _this.moduleService.verify_email();
                                    },
                                    disabled: function () {
                                        return !_this.general_state.email ||
                                            _this.general_state.verified_email == 1 ||
                                            _this.general_state.loading;
                                    },
                                },
                            ],
                        },
                        {
                            type: "divider",
                            label: "",
                            bar: true,
                            col_class: "col-12 mb-3",
                        },
                        {
                            name: "address",
                            type: "textarea",
                            label: "Dirección",
                            small: "",
                            rows: 2.5,
                            placeholder: function () { return "..."; },
                            col_class: "col-12",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                                maxlength: {
                                    value: function () { return 500; },
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_department",
                            type: "select",
                            label: "Departamento",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.departments; },
                                value: "id",
                                text: "name",
                            },
                            disabled: function () { return false; },
                            change: function () {
                                _this.general_state.id_town = "";
                            },
                        },
                        {
                            name: "id_town",
                            type: "select",
                            label: "Municipio",
                            placeholder: function () {
                                return _this.general_state.id_department ? "- Seleccione -" : "- Seleccione un Departamento -";
                            },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                            },
                            catalog: {
                                list: function () {
                                    return _this.catalogsService.catalogs.towns.filter(function (value) { return value.id_department == _this.general_state.id_department; });
                                },
                                value: "id",
                                text: "name",
                            },
                            disabled: function () { return !_this.general_state.id_department; },
                        },
                        {
                            type: "divider",
                            label: "",
                            bar: true,
                            col_class: "col-12 mb-3",
                        },
                        {
                            name: "id_blood",
                            type: "select",
                            label: "Tipo de Sangre",
                            col_class: "col-md-2",
                            placeholder: function () { return "--"; },
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.bloods; },
                                value: "id",
                                text: "name",
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "organ_donor",
                            type: "checkbox",
                            label: "",
                            col_class: "col-md-5",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.cat_organ_donor; },
                                value: "id",
                                text: "name",
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "glasses",
                            type: "checkbox",
                            label: "",
                            col_class: "col-md-5",
                            validators: {
                                required: {
                                    value: function () { return false; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.cat_glasses; },
                                value: "id",
                                text: "name",
                            },
                            disabled: function () { return false; },
                        },
                    ],
                },
                {
                    class: "row mt-2",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-warning ml-auto",
                                    label: "Editar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-edit",
                                        position: "right",
                                    },
                                    show: function () {
                                        return (_this.expedient_state.method == "show" &&
                                            _this.expedient_state.version != "new_patient");
                                    },
                                    click: function () {
                                        _this.expedientService.change_method("update");
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: "left",
                                    },
                                    show: function () {
                                        return (_this.expedient_state.method == "update" &&
                                            _this.expedient_state.version != "new_patient");
                                    },
                                    click: function () {
                                        _this.expedientService.change_method("show");
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Guardar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: "right",
                                    },
                                    show: function () {
                                        return ((_this.expedient_state.method == "update" ||
                                            _this.expedient_state.method == "insert") &&
                                            _this.expedient_state.version != "new_patient");
                                    },
                                    disabled: function () {
                                        return (_this.form_ref ? !_this.form_ref.valid() : false) || _this.general_state.loading;
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    };
    FormComponent.prototype.open_file_chooser = function () {
        if (this.expedient_state.method != "show") {
            this.fileUploadControl.clear();
            this.fileUploadControl.click();
        }
    };
    FormComponent.prototype.fileProgress = function (event) {
        this.general_state.image_upload = this.uploadedFiles[0];
        this.preview();
    };
    FormComponent.prototype.preview = function () {
        var _this = this;
        var mimeType = this.general_state.image_upload.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(this.general_state.image_upload);
        reader.onload = function (_event) {
            _this.general_state.image = reader.result;
        };
    };
    return FormComponent;
}());
export { FormComponent };
