import { PipeTransform } from '@angular/core';
import { PipesService } from 'src/app/services/pipes.service';
var NumberPipe = /** @class */ (function () {
    function NumberPipe(pipesService) {
        this.pipesService = pipesService;
    }
    NumberPipe.prototype.transform = function (num, min, max) {
        if (min === void 0) { min = 2; }
        if (max === void 0) { max = 2; }
        return this.pipesService.number(+num, min, max);
    };
    return NumberPipe;
}());
export { NumberPipe };
