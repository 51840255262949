import { ExpedientService } from 'src/app/services/expedient.service';
import { AllergiesService } from '../allergies.service';
var CreateBackgroundAllergiesComponent = /** @class */ (function () {
    function CreateBackgroundAllergiesComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateBackgroundAllergiesComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateBackgroundAllergiesComponent;
}());
export { CreateBackgroundAllergiesComponent };
