import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { DoctorService } from '../doctor.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
var FormComponent = /** @class */ (function () {
    function FormComponent(moduleService, expedientService, catalogsService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.catalogsService = catalogsService;
        this.doctors = [];
        this.doctors_loading = true;
        this.doctors_timeout = null;
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            // this.moduleService.reload();
            _this.form_configs();
        });
        this.doctor_subscription = this.moduleService.current.subscribe(function (state) { return _this.doctor_state = state; });
        this.form_configs();
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        if (this.doctor_state) {
            this.moduleService.forms.doctor = this.form_ref;
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.doctor_subscription.unsubscribe();
    };
    FormComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return _this.expedient_state.method == "show"; },
            disabled: function () { return _this.expedient_state.method == "show"; },
            submit: function (callback) { return _this.moduleService.submit(callback); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "doctor_search",
                            type: "select-search",
                            label: "Doctor",
                            col_class: "col-md-12",
                            version: "v1",
                            placeholder: function () { return "Buscar doctor.."; },
                            validators: {
                                required: {
                                    value: function () { return true; },
                                },
                            },
                            catalog: {
                                list: function () { return _this.doctors; },
                                value: "name",
                                text: "name",
                            },
                            loading: function () {
                                return _this.doctors_loading;
                            },
                            change: function (event) {
                                // if (!input_ref.s_value) {
                                //     this.doctor_state.doctor_search = "";
                                // }
                            },
                            input: function (event) {
                                _this.doctor_state.id_doctor = null;
                                _this.doctor_state.doctor_search = "";
                                _this.doctors_loading = true;
                                clearTimeout(_this.doctors_timeout);
                                _this.doctors_timeout = setTimeout(function () {
                                    _this.moduleService.get_doctors((function (doctors) {
                                        _this.doctors = _this.catalogsService.selectWhereLike(doctors, { name: (event.target.value + "").trim() });
                                        _this.doctors_loading = false;
                                    }));
                                }, 1000);
                            },
                            select: function (item, input_ref) {
                                input_ref.s_value = item.name;
                                _this.doctor_state.firstname = item.firstname;
                                _this.doctor_state.lastname = item.lastname;
                                _this.doctor_state.id_doctors_specialty = item.id_doctors_specialty;
                                _this.doctor_state.id_doctor = item.id_user;
                            },
                            disabled: function () { return false; },
                            start_value: function (input_ref) {
                                setTimeout(function (v) {
                                    input_ref.value_started = true;
                                    input_ref.s_value = _this.doctor_state.doctor_search || "";
                                }, 1);
                            },
                        },
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    click: function () {
                                        _this.moduleService.cancel();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cerrar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "show";
                                    },
                                    click: function () {
                                        _this.moduleService.close();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Guardar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    disabled: function () { return !_this.doctor_state.id_doctor || _this.doctor_state.loading; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    return FormComponent;
}());
export { FormComponent };
