import { OnInit } from '@angular/core';
import * as moment from 'moment';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/services/account.service';
registerLocaleData(localeEs, 'es');
var ScheduleComponent = /** @class */ (function () {
    function ScheduleComponent(account, endpoint, pageService) {
        this.account = account;
        this.endpoint = endpoint;
        this.pageService = pageService;
        this.show_consults = false;
        this.year = new Date().getFullYear();
        this.month = (new Date().getMonth() + 1);
        this.dia = new Date().getDate();
        this.week = [
            "Lunes",
            "Martes",
            "Miercoles",
            "Jueves",
            "Viernes",
            "Sabado",
            "Domingo"
        ];
        this.get_days_from_date((this.month), this.year);
        this.show_consults = false;
    }
    ScheduleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.doctor_data = this.account.get_current_state();
        this.doctor_subscription = this.endpoint.refresh$.subscribe(function (res) {
            _this.get_patient_dates(_this.doctor_data.id);
            _this.get_consults_data(_this.doctor_data.id);
        });
        this.get_patient_dates(this.doctor_data.id);
        this.get_consults_data(this.doctor_data.id);
        this.get_data_doctor();
    };
    ScheduleComponent.prototype.get_days_from_date = function (month, year) {
        var startDate = moment.utc(year + "/" + month + "/01");
        var endDate = startDate.clone().endOf('month');
        this.date_select = startDate;
        this.year_select = this.date_select._i;
        this.year_select2 = this.year_select.substr(0, 4);
        var diffDays = endDate.diff(startDate, 'days', true);
        var numberDays = Math.round(diffDays);
        var array_days = Object.keys(Array(numberDays).slice()).map(function (a) {
            a = parseInt(a) + 1;
            var dayObject = moment(year + "-" + month + "-" + a);
            return {
                name: dayObject.format("dddd"),
                value: a,
                indexWeek: dayObject.isoWeekday()
            };
        });
        this.month_select_principal = array_days;
        this.month_select = this.date_select._i;
        this.month_select_month = this.month_select.substr(5, 2);
        this.month_present = this.month_select.substr(5, 2);
    };
    ScheduleComponent.prototype.change_month = function (flag) {
        if (flag < 0) {
            var prevDate = this.date_select.clone().subtract(1, "month");
            this.get_days_from_date(prevDate.format("MM"), prevDate.format("YYYY"));
        }
        else {
            var nextDate = this.date_select.clone().add(1, "month");
            this.get_days_from_date(nextDate.format("MM"), nextDate.format("YYYY"));
        }
    };
    ScheduleComponent.prototype.get_patient_dates = function (id) {
        var _this = this;
        var params = {
            id_doctor: id
        };
        this.doctor_subscription = this.endpoint.get_dates_specific(params).subscribe(function (data) {
            if (data) {
                _this.patient_data = data;
                _this.patient_data1 = _this.patient_data.list;
            }
        });
    };
    ScheduleComponent.prototype.get_consults_data = function (id) {
        var _this = this;
        var params = {
            id_doctor: id
        };
        this.doctor_subscription = this.endpoint.get_consults_by_doctor(params).subscribe(function (data) {
            if (data) {
                _this.consults_data = data;
                _this.consults_data1 = _this.consults_data.list;
            }
        });
    };
    ScheduleComponent.prototype.get_data_doctor = function () {
        var _this = this;
        this.doctor_subscription = this.endpoint.get_data_doctor().subscribe(function (data) {
            if (data) {
                _this.doctor_data_specific = data;
            }
        });
    };
    ScheduleComponent.prototype.click_day = function (day) {
        var month_year = this.date_select.format('YYYY-MM');
        var parse = month_year + "-" + day.value;
        var object_date = moment(parse);
        this.date_value = object_date;
        this.fecha_date = this.date_value._d;
        this.data = {
            id_patient: "",
            id_doctor: "",
            description: "",
            fecha_date: this.fecha_date,
            hour_date: "",
            duration: ""
        };
        this.pageService.openModal(this.createModal, "lg");
    };
    ScheduleComponent.prototype.show_consult = function (data) {
        this.data_consult = {
            id_patient: data.id_patient,
            id_doctor: data.id_doctor,
            description: data.description,
            reason: data.reason,
            doctor_gender: this.doctor_data_specific.list[0].doctor_gender,
            doctor_specialty: this.doctor_data_specific.list[0].doctor_specialty,
            doctor_name: this.doctor_data_specific.list[0].doctor_name,
            recomendations: data.recomendations,
            title: data.reason,
            doctor_image: this.doctor_data_specific.list[0].doctor_image,
            created_at: data.created_at
        };
        this.show_consults = true;
    };
    ScheduleComponent.prototype.show_modal_date = function (data) {
        this.data_date = {
            id_patient: data.id_patient,
            description: data.description,
            fecha_date: data.date_complete,
            hour_date: data.hora,
            duration: data.duration,
            id_date: data.id_date,
            paciente: data.paciente
        };
        this.pageService.openModal(this.updateModal, "lg");
    };
    return ScheduleComponent;
}());
export { ScheduleComponent };
[];
