import * as tslib_1 from "tslib";
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as i0 from "@angular/core";
import * as i1 from "ngx-indexed-db";
;
var WebDBService = /** @class */ (function () {
    function WebDBService(dbService) {
        var _this = this;
        this.dbService = dbService;
        this.add = function (table, payload) {
            return _this.dbService.add(table, payload);
        };
        this.bulkAdd = function (table, payload) {
            return _this.dbService.bulkAdd(table, payload);
        };
        this.update = function (table, payload) {
            return _this.dbService.update(table, payload);
        };
        this.getAll = function (table, ids) {
            return !ids ? _this.dbService.getAll(table) : _this.dbService.bulkGet(table, ids);
        };
        this.get = function (table, id) {
            return _this.dbService.getByKey(table, id);
        };
        this.getByIndex = function (table, indexName, key) { return _this.dbService.getByIndex(table, indexName, key); };
        this.delete = function (table, id) { return _this.dbService.delete(table, id); };
        this.bulkDelete = function (table, ids) {
            return _this.dbService.bulkDelete(table, ids);
        };
    }
    WebDBService.prototype.overwrite = function (json, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _loop_1, _a, _b, _i, table, _loop_2, _c, _d, _e, table;
            var _this = this;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _loop_1 = function (table) {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, new Promise(function (resolve) {
                                            _this.dbService.clear(table).subscribe(function () { return resolve(true); });
                                        })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _a = [];
                        for (_b in json)
                            _a.push(_b);
                        _i = 0;
                        _f.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        table = _a[_i];
                        return [5 /*yield**/, _loop_1(table)];
                    case 2:
                        _f.sent();
                        _f.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        _loop_2 = function (table) {
                            var _loop_3, index;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _loop_3 = function (index) {
                                            var obj;
                                            return tslib_1.__generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        obj = json[table][index];
                                                        return [4 /*yield*/, new Promise(function (resolve) {
                                                                _this.add(table, obj).subscribe(function () { return resolve(true); });
                                                            })];
                                                    case 1:
                                                        _a.sent();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        };
                                        index = 0;
                                        _a.label = 1;
                                    case 1:
                                        if (!(index < json[table].length)) return [3 /*break*/, 4];
                                        return [5 /*yield**/, _loop_3(index)];
                                    case 2:
                                        _a.sent();
                                        _a.label = 3;
                                    case 3:
                                        index++;
                                        return [3 /*break*/, 1];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        };
                        _c = [];
                        for (_d in json)
                            _c.push(_d);
                        _e = 0;
                        _f.label = 5;
                    case 5:
                        if (!(_e < _c.length)) return [3 /*break*/, 8];
                        table = _c[_e];
                        return [5 /*yield**/, _loop_2(table)];
                    case 6:
                        _f.sent();
                        _f.label = 7;
                    case 7:
                        _e++;
                        return [3 /*break*/, 5];
                    case 8:
                        callback();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Selects items from a list that match certain criteria
     * @param {Array<any>} list - List to filter
     * @param {Object} columns - Object containing the search criteria
     * @param {Function} [callback] - Optional callback function to be called with the filtered result
     * @returns {Array<any>} - Filtered list
     *
     * @example
     *
     * const list = [
     *   {name: 'John', age: 20, date: '2022-01-01'},
     *   {name: 'Mike', age: 25, date: '2022-02-01'},
     *   {name: 'Jane', age: 30, date: '2022-03-01'},
     * ]
     *
     * const searchCriteria = {
     *   name: 'John',
     *   age: {
     *     logic: 'or',
     *     values: [20, 25]
     *   },
     *   date: {
     *     key: 'date',
     *     logic: 'or',
     *     from: '2022-02-01',
     *     to: '2022-03-01'
     *   }
     * }
     *
     * const result = selectWhereLike(list, searchCriteria)
     * // result will be [{name: 'John', age: 20, date: '2022-01-01'}, {name: 'Mike', age: 25, date: '2022-02-01'}]
     *
     */
    WebDBService.prototype.selectWhereLike = function (list, columns, callback) {
        var result = list.filter(function (item) {
            var match = true;
            for (var key in columns) {
                if (key === 'date') {
                    var dateColumns = Array.isArray(columns[key]) ? columns[key] : [columns[key]];
                    for (var _i = 0, dateColumns_1 = dateColumns; _i < dateColumns_1.length; _i++) {
                        var dateColumn = dateColumns_1[_i];
                        var date = new Date(item[dateColumn.key]);
                        if (dateColumn.logic === 'or') {
                            if ((dateColumn.from && date < dateColumn.from) || (dateColumn.to && date > dateColumn.to)) {
                                match = false;
                                break;
                            }
                        }
                        else {
                            if ((dateColumn.from && date < dateColumn.from) && (dateColumn.to && date > dateColumn.to)) {
                                match = false;
                                break;
                            }
                        }
                    }
                }
                else {
                    var columnMatch = false;
                    var columnValues = Array.isArray(columns[key]) ? columns[key] : [columns[key]];
                    for (var _a = 0, columnValues_1 = columnValues; _a < columnValues_1.length; _a++) {
                        var columnValue = columnValues_1[_a];
                        var regex = new RegExp(columnValue
                            .replace(/á|ä/g, "a")
                            .replace(/é|ë/g, "e")
                            .replace(/í|ï/g, "i")
                            .replace(/ó|ö/g, "o")
                            .replace(/ú|ü/g, "u"), 'i');
                        if (regex.test(item[key]
                            .replace(/á|ä/g, "a")
                            .replace(/é|ë/g, "e")
                            .replace(/í|ï/g, "i")
                            .replace(/ó|ö/g, "o")
                            .replace(/ú|ü/g, "u"))) {
                            columnMatch = true;
                            break;
                        }
                    }
                    if (!columnMatch) {
                        match = false;
                        break;
                    }
                }
            }
            return match;
        });
        if (callback)
            callback(result);
        return result;
    };
    /**
   * sort
   * @param list any[] - Lista de elementos a ordenar
   * @param filter {sort_by?: string, sort_asc?: boolean} - Filtro de ordenamiento
   * @return any[] - Lista de elementos ordenada
   *
   * Esta función permite ordenar una lista de elementos a partir de un criterio específico.
   * El parametro "sort_by" especifica el nombre de la columna por la cual se desea ordenar,
   * y el parametro "sort_asc" especifica si el orden es ascendente (true) o descendente (false).
   */
    WebDBService.prototype.sort = function (list, filter) {
        if (filter.sort_by) {
            list.sort(function (a, b) {
                var valueA = a[filter.sort_by];
                var valueB = b[filter.sort_by];
                if (valueA < valueB) {
                    return filter.sort_asc ? -1 : 1;
                }
                if (valueA > valueB) {
                    return filter.sort_asc ? 1 : -1;
                }
                return 0;
            });
        }
        return list;
    };
    WebDBService.prototype.sumTotalByDay = function (arr, sumAttribute, dateAttribute) {
        var totalsByDay = new Map();
        arr.forEach(function (obj) {
            var date = new Date(obj[dateAttribute]);
            date.setHours(0, 0, 0, 0);
            var dateKey = +date + "";
            if (!totalsByDay.has(dateKey)) {
                totalsByDay.set(dateKey, obj[sumAttribute]);
            }
            else {
                totalsByDay.set(dateKey, totalsByDay.get(dateKey) + obj[sumAttribute]);
            }
        });
        return Array.from(totalsByDay).map(function (_a) {
            var date = _a[0], value = _a[1];
            return ({
                date: date,
                value: value
            });
        });
    };
    WebDBService.prototype.sumTotalByMonth = function (arr, sumAttribute, dateAttribute) {
        var totalsByMonth = new Map();
        arr.forEach(function (obj) {
            var date = new Date(obj[dateAttribute]);
            date.setDate(1);
            date.setHours(0, 0, 0, 0);
            var dateKey = +date + "";
            if (!totalsByMonth.has(dateKey)) {
                totalsByMonth.set(dateKey, obj[sumAttribute]);
            }
            else {
                totalsByMonth.set(dateKey, totalsByMonth.get(dateKey) + obj[sumAttribute]);
            }
        });
        return Array.from(totalsByMonth).map(function (_a) {
            var date = _a[0], value = _a[1];
            return ({
                date: date,
                value: value
            });
        });
    };
    WebDBService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebDBService_Factory() { return new WebDBService(i0.ɵɵinject(i1.NgxIndexedDBService)); }, token: WebDBService, providedIn: "root" });
    return WebDBService;
}());
export { WebDBService };
