var CustomListClass = /** @class */ (function () {
    function CustomListClass(data) {
        if (data)
            this.overwrite(data);
        else
            this.reset();
    }
    CustomListClass.prototype.overwrite = function (data) {
        this.list = data;
        this.loaded = true;
        this.loading = false;
        this.submitted = false;
    };
    CustomListClass.prototype.get = function (i) {
        return this.list[i];
    };
    CustomListClass.prototype.add = function (item) {
        this.list.push(item);
    };
    CustomListClass.prototype.remove = function (i) {
        this.list.splice(i, 1);
    };
    CustomListClass.prototype.reset = function () {
        this.list = [];
        this.loaded = false;
        this.loading = true;
        this.submitted = false;
    };
    CustomListClass.prototype.valid = function () {
        return true;
    };
    CustomListClass.prototype.empty = function () {
        return this.list.length == 0;
    };
    CustomListClass.prototype.to_string = function () {
        var data = { list: [] };
        data.list = this.list.map(function (v) { return v.to_string(); });
        return data;
    };
    CustomListClass.prototype.raw = function () {
        return this.list.map(function (t) { return t.raw(); });
    };
    return CustomListClass;
}());
export { CustomListClass };
