import { PageService } from 'src/app/services/page.service';
var ShowInsurancesAccidentComponent = /** @class */ (function () {
    function ShowInsurancesAccidentComponent(pageService) {
        this.pageService = pageService;
    }
    ShowInsurancesAccidentComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowInsurancesAccidentComponent;
}());
export { ShowInsurancesAccidentComponent };
