import * as tslib_1 from "tslib";
import { CustomClass } from "./custom-class";
;
;
var ProceduresResultsClass = /** @class */ (function (_super) {
    tslib_1.__extends(ProceduresResultsClass, _super);
    function ProceduresResultsClass(catalogsService, pipesService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        _this.pipesService = pipesService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    ProceduresResultsClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_patient = data.id_patient;
        this.id_consult = data.id_consult;
        this.id_procedures_type = data.id_procedures_type;
        this.observations = data.observations;
        this.date = data.date;
        this.doctor_name = data.doctor_name;
        this.place = data.place;
        this.file = data.file;
        this.file_upload = data.file_upload;
    };
    ProceduresResultsClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_patient = null;
        this.id_consult = null;
        this.id_procedures_type = null;
        this.observations = "";
        this.date = new Date();
        this.doctor_name = "";
        this.place = "";
        this.file = "";
        this.file_upload = null;
    };
    ProceduresResultsClass.prototype.format = function () {
        return this;
    };
    ProceduresResultsClass.prototype.valid = function () {
        return true;
    };
    ProceduresResultsClass.prototype.to_string = function () {
        var _this = this;
        var procedures_type = this.catalogsService.catalogs.procedures_types.find(function (v) { return v.id == _this.id_procedures_type; });
        var data = {
            procedures_type: procedures_type ? procedures_type.name : "",
            observations: this.observations,
            place: this.place,
            date: this.pipesService.micro_date(this.date.toString()),
            doctor_name: this.doctor_name,
            file: this.file,
        };
        return data;
    };
    ProceduresResultsClass.prototype.raw = function () {
        return {
            id: this.id,
            id_patient: this.id_patient,
            id_consult: this.id_consult,
            id_procedures_type: this.id_procedures_type,
            observations: this.observations,
            date: this.date,
            doctor_name: this.doctor_name,
            place: this.place,
            file: this.file,
            file_upload: this.file_upload,
        };
    };
    return ProceduresResultsClass;
}(CustomClass));
export { ProceduresResultsClass };
