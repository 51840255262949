import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { PageService } from '../../../../services/page.service';
var PathologiesBriefComponent = /** @class */ (function () {
    function PathologiesBriefComponent(endpoint, patientService, pageService) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.pageService = pageService;
        this.show = false;
    }
    PathologiesBriefComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patient_subscription = this.patientService.patient_data_subscription.subscribe(function (data) { return _this.patient_state = data; });
    };
    PathologiesBriefComponent.prototype.ngOnDestroy = function () {
        this.patient_subscription.unsubscribe();
    };
    return PathologiesBriefComponent;
}());
export { PathologiesBriefComponent };
