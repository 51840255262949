import { AccountService } from 'src/app/services/account.service';
import { PageService } from "src/app/services/page.service";
import { BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ProfileClass } from "src/app/classes/profile/profile-class";
import { AppService } from "src/app/services/app.service";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PipesService } from "src/app/services/pipes.service";
import * as i0 from "@angular/core";
import * as i1 from "../../services/app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../../services/pipes.service";
import * as i4 from "../../services/catalogs.service";
import * as i5 from "../../services/page.service";
import * as i6 from "../../services/account.service";
var ProfileService = /** @class */ (function () {
    function ProfileService(endpoint, toastr, pipesService, catalogsService, pageService, accountService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pipesService = pipesService;
        this.catalogsService = catalogsService;
        this.pageService = pageService;
        this.accountService = accountService;
        this.current_state = new ProfileClass(this.catalogsService, this.pipesService);
        this.next();
    }
    ProfileService.prototype.next = function () {
        if (this.subject) {
            this.subject.next(this.current_state);
        }
        else {
            this.subject = new BehaviorSubject(this.current_state);
            this.observer = this.subject.asObservable();
        }
    };
    ProfileService.prototype.clean = function () {
        this.current_state.reset();
    };
    ProfileService.prototype.init = function (id_user) {
        this.load_profile(id_user);
    };
    ProfileService.prototype.load_profile = function (username) {
        var _this = this;
        if (username === void 0) { username = ""; }
        this.endpoint.profile_get_profile({ username: username }).subscribe(function (data) {
            _this.current_state.overwrite(data);
            _this.current_state.to_string();
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
        });
    };
    ProfileService.prototype.openModal = function (content, size, callback) {
        if (size === void 0) { size = "lg"; }
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.pageService.openModal(content, size, callback);
    };
    ProfileService.prototype.closeModal = function (data) {
        if (data === void 0) { data = ""; }
        this.pageService.closeModal(data);
    };
    ProfileService.prototype.remove_profile_image = function () {
        var _this = this;
        this.endpoint.profile_remove_profile_image().subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.load_profile(_this.current_state.username);
            _this.accountService.reload();
            location.reload();
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
        });
    };
    ProfileService.prototype.upload_profile_image = function (uploadedFiles) {
        var _this = this;
        var formData = new FormData();
        if (uploadedFiles && uploadedFiles[0]) {
            formData.append("profile_image", uploadedFiles[0], uploadedFiles[0]["name"]);
        }
        this.endpoint.profile_upload_profile_image(formData).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            uploadedFiles.splice(0);
            _this.load_profile(_this.current_state.username);
            _this.accountService.reload();
            location.reload();
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
        });
    };
    ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.PipesService), i0.ɵɵinject(i4.CatalogsService), i0.ɵɵinject(i5.PageService), i0.ɵɵinject(i6.AccountService)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
