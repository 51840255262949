import { throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { AppService } from './services/app.service';
import { AccountService } from './services/account.service';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(endpoint, toastr, accountService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.accountService = accountService;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var cloned = req.clone({
            responseType: req.responseType == "blob" ? "blob" : "json"
        });
        var session;
        var auth_token = "";
        if (session = localStorage.getItem("doctor-unimed-user-session")) {
            auth_token = JSON.parse(session).token;
        }
        if (auth_token) {
            cloned = cloned.clone({
                headers: cloned.headers.set("Authorization", "Bearer " + auth_token)
            });
        }
        return next.handle(cloned).pipe(catchError(function (error) {
            if (error.status == 403) {
                if (error.error.code == "auth") {
                    _this.endpoint.reset_session();
                    _this.accountService.reset();
                    _this.endpoint.redirect_to("/conectarse");
                }
                if (error.error.code == "disabled") {
                    _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
                    _this.endpoint.reset_session();
                    _this.accountService.reset();
                    _this.endpoint.redirect_to("/conectarse");
                }
                return throwError(error);
            }
            else if (error.status == 0) {
                error.error.title = "Problemas de conexión";
                error.error.message = "Revise su conexión de internet o inténtelo más tarde.";
                return throwError(error);
            }
            else {
                return throwError(error);
            }
        }));
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
