import { OnInit, OnDestroy } from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "src/app/services/app.service";
import { PageService } from "src/app/services/page.service";
import { PatientService } from "src/app/services/patient-service.service";
var ExpedientComponent = /** @class */ (function () {
    function ExpedientComponent(endpoint, patientService, pageService) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.pageService = pageService;
    }
    ExpedientComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patient_subscription = this.patientService.patient_data_subscription.subscribe(function (state) { return (_this.patient_state = state); });
        this.page_subscription = this.pageService.current.subscribe(function (state) {
            _this.page_state = state;
            _this.hovered_sidebar_config();
        });
    };
    ExpedientComponent.prototype.ngOnDestroy = function () {
        if (this.page_subscription)
            this.page_subscription.unsubscribe();
        if (this.patient_subscription)
            this.patient_subscription.unsubscribe();
    };
    ExpedientComponent.prototype.hovered_sidebar_config = function () {
        this.hovered_sidebar = {
            style: "shadowbox",
            list: (this.page_state.expedient
                ? [
                    {
                        name: function () { return "Perfil"; },
                        url: function () { return ["./perfil"]; },
                        icon: function () { return "fa-duotone fa-user"; },
                    },
                    {
                        name: function () { return "Consultas"; },
                        url: function () { return ["./consultas"]; },
                        icon: function () { return "fa-duotone fa-calendar-check"; },
                    },
                ]
                : []).concat([
                {
                    name: function () { return "Contactos"; },
                    url: function () { return ["./contactos"]; },
                    icon: function () { return "fa-duotone fa-address-book"; },
                },
                {
                    name: function () { return "Antecedentes"; },
                    url: function () { return ["./antecedentes"]; },
                    icon: function () { return "fa-duotone fa-book-medical"; },
                },
                // {
                //   name: () => "Procedimientos",
                //   url: () => ["./procedimientos"],
                //   icon: () => "fa-duotone fa-procedures"
                // },
                // {
                //   name: () => "Referencias",
                //   url: () => ["./referencias"],
                //   icon: () => "fa-duotone fa-asterisk"
                // },
                // {
                //   name: () => "Exámenes",
                //   url: () => ["./examenes"],
                //   icon: () => "fa-duotone fa-vial"
                // },
                // {
                //   name: () => "Medicinas",
                //   url: () => ["./medicinas"],
                //   icon: () => "fa-duotone fa-capsules"
                // },
                // {
                //     name: () => "Seguros",
                //     url: () => ["./seguros"],
                //     icon: () => "fa-duotone fa-shield-alt",
                // },
                {
                    name: function () { return "Vacunas"; },
                    url: function () { return ["./vacunas"]; },
                    icon: function () { return "fa-duotone fa-syringe"; },
                },
                {
                    name: function () { return "Curvas de crecimiento"; },
                    url: function () { return ["./curvas_crecimiento"]; },
                    icon: function () { return "fa-duotone fa-chart-line-up"; },
                },
                {
                    name: function () { return "Relaciones"; },
                    url: function () { return ["./relaciones"]; },
                    icon: function () { return "fa-duotone fa-users"; },
                },
            ]),
        };
    };
    ExpedientComponent.prototype.open_chat = function () {
        this.pageService.openModal(this.chatModal);
    };
    return ExpedientComponent;
}());
export { ExpedientComponent };
