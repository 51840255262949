import { PageService } from 'src/app/services/page.service';
var ShowContactsDoctorComponent = /** @class */ (function () {
    function ShowContactsDoctorComponent(pageService) {
        this.pageService = pageService;
    }
    ShowContactsDoctorComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowContactsDoctorComponent;
}());
export { ShowContactsDoctorComponent };
