import { PipeTransform } from '@angular/core';
import { PipesService } from 'src/app/services/pipes.service';
var microDate = /** @class */ (function () {
    function microDate(pipesService) {
        this.pipesService = pipesService;
    }
    microDate.prototype.transform = function (str) {
        return this.pipesService.micro_date(str);
    };
    return microDate;
}());
export { microDate };
