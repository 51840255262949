import { ExpedientService } from 'src/app/services/expedient.service';
import { DoctorService } from '../doctor.service';
var CreateContactsDoctorComponent = /** @class */ (function () {
    function CreateContactsDoctorComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateContactsDoctorComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateContactsDoctorComponent;
}());
export { CreateContactsDoctorComponent };
