import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service';
import { PageService } from './page.service';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "./page.service";
var PatientService = /** @class */ (function () {
    function PatientService(endpoint, pageService) {
        this.endpoint = endpoint;
        this.pageService = pageService;
        this.patient_data_observable = new BehaviorSubject(null);
        this.patient_data_subscription = this.patient_data_observable.asObservable();
    }
    PatientService.prototype.init = function (callback) {
        if (callback === void 0) { callback = function (data) { }; }
        var stored = this.endpoint.get_patient_id();
        if (stored) {
            this.change_patient_id(stored);
        }
        else {
            this.clean_patient();
        }
        this.load_patient_data(callback);
    };
    PatientService.prototype.clean_patient = function () {
        this.patient_data = {
            general: {
                id: "",
                id_user: "",
                firstname: "",
                lastname: "",
                id_gender: "",
                birthdate: "",
                age: "",
                age_month: "",
                age_day: "",
                birthplace: "",
                id_department: "",
                id_town: "",
                address: "",
                phone: "",
                id_education: "",
                identification: "",
                image: "",
                email: ""
            },
            background: {
                allergy_total: 0,
                allergy: [],
                pathology_total: 0,
                pathology: []
            },
            contacts: {
                doctor_total: 0,
                doctor: [],
                emergency_total: 0,
                emergency: []
            },
            loaded: false
        };
        this.patient_data_observable.next(this.patient_data);
    };
    PatientService.prototype.reset_patient = function () {
        this.patient_id = null;
        this.patient_data = null;
        this.endpoint.set_patient_id(this.patient_id);
        this.patient_data_observable.next(this.patient_data);
    };
    PatientService.prototype.reset_superficial_patient = function () {
        this.patient_id = null;
        this.patient_data = null;
        this.patient_data_observable.next(this.patient_data);
    };
    PatientService.prototype.change_patient_id = function (id) {
        this.reset_patient();
        this.patient_id = id;
        this.endpoint.set_patient_id(this.patient_id);
        this.load_patient_data();
    };
    PatientService.prototype.change_superficial_patient_id = function (id) {
        this.reset_superficial_patient();
        this.patient_id = id;
        this.load_patient_data();
    };
    PatientService.prototype.load_patient_data = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (data) { }; }
        var load = {
            id: this.patient_id
        };
        var response;
        this.endpoint.get_patient_detail(load).subscribe(function (data) { return response = data; }, function (err) { return err; }, function () {
            response.loaded = true;
            _this.patient_data = response;
            callback(response);
            _this.patient_data_observable.next(_this.patient_data);
            _this.pageService.reload();
        });
    };
    PatientService.prototype.get_patient_id = function () {
        return this.patient_id;
    };
    PatientService.prototype.get_patient_data = function () {
        return this.patient_data;
    };
    PatientService.prototype.update_patient_detail = function () {
        var _this = this;
        this.endpoint.update_patient_detail(this.patient_data).subscribe(function (data) { return _this.load_patient_data(); }, function (err) { return err; });
    };
    PatientService.prototype.set_growth_curve = function (growth_curve) {
        if (this.patient_data) {
            this.patient_data.growth_curve = growth_curve;
            this.patient_data_observable.next(this.patient_data);
        }
    };
    PatientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatientService_Factory() { return new PatientService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.PageService)); }, token: PatientService, providedIn: "root" });
    return PatientService;
}());
export { PatientService };
