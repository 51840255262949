import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { AppService } from './app.service';
import { PatientService } from './patient-service.service';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "./patient-service.service";
import * as i3 from "ngx-toastr";
;
;
;
var ExpedientService = /** @class */ (function () {
    function ExpedientService(endpoint, patientService, toastr) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.toastr = toastr;
        this.expedient_session = "ExpedientMetadata";
        if (!this.current_state) {
            this.clean();
            this.next();
        }
    }
    ExpedientService.prototype.init = function (expedient_metadata) {
        this.file_list = {
            insurances: {
                accident: []
            }
        };
        this.init_expedient_services();
        this.get_stored(expedient_metadata);
        this.save();
        this.next();
    };
    Object.defineProperty(ExpedientService.prototype, "state", {
        get: function () {
            return this.current_state;
        },
        enumerable: true,
        configurable: true
    });
    ExpedientService.prototype.get_stored = function (expedient_metadata) {
        this.current_state = expedient_metadata;
    };
    ExpedientService.prototype.next = function () {
        if (this.expedient_state) {
            this.expedient_state.next(this.current_state);
        }
        else {
            this.expedient_state = new BehaviorSubject(this.current_state);
            this.current = this.expedient_state.asObservable();
        }
    };
    ExpedientService.prototype.clean = function () {
        this.current_state = {
            id_user: null,
            method: "show",
            version: null
        };
    };
    ExpedientService.prototype.save = function () {
        localStorage.setItem(this.expedient_session, JSON.stringify(this.current_state));
    };
    ExpedientService.prototype.change_version = function (version) {
        this.current_state.version = version;
        this.save();
        this.next();
    };
    ExpedientService.prototype.change_method = function (method) {
        this.current_state.method = method;
        this.save();
        this.next();
    };
    ExpedientService.prototype.change_id_user = function (id_user) {
        this.current_state.id_user = id_user;
        this.save();
        this.next();
    };
    ExpedientService.prototype.cancel = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        Swal.fire({
            title: "Cancelar Actualización de Paciente",
            html: "¿Está seguro de cancelar la actualización del paciente actual?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDB915',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cancelar',
            cancelButtonText: 'No'
        }).then(function (result) {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                callback();
            }
        });
    };
    // MODULES
    ExpedientService.prototype.init_expedient_services = function () {
        this.expedient_services = {
            general: null,
            contacts: {
                doctor: null,
                emergency: null
            },
            procedures: {
                doctor: null
            },
            references: {
                doctor: null
            },
            background: {
                allergies: null,
                pathologies: null
            },
            insurances: {
                accident: null
            },
            exams: {
                laboratory: null
            },
            relations: {
                dependants: null,
                tutors: null
            },
            institutions: null,
        };
    };
    ExpedientService.prototype.set_expedient_service = function (ref, att1, att2) {
        if (this.expedient_services) {
            if (att2) {
                this.expedient_services[att1][att2] = ref;
            }
            else {
                this.expedient_services[att1] = ref;
            }
        }
    };
    ExpedientService.prototype.submit_expedient_services = function () {
        this.current_state.loading = true;
        this.next();
        this._submit_expedient_services();
    };
    ExpedientService.prototype.end_submit_expedient_services = function () {
        this.current_state.loading = false;
        localStorage.removeItem("inNewPatient");
        this.patientService.change_patient_id(this.current_state.id_user);
        this.endpoint.redirect_to("/expediente/consultas");
        this.next();
    };
    ExpedientService.prototype._submit_expedient_services = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var minor_1, general_async, relations_tutors_async, general_async, relations_tutors_async, contacts_doctor_async, contacts_emergency_async, background_pathologies_async, background_allergies_async, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 18, , 19]);
                        // GENERAL
                        this.current_state.method = "insert";
                        minor_1 = false;
                        if (!this.expedient_services.general) return [3 /*break*/, 2];
                        general_async = new Promise(function (resolve, reject) {
                            _this.expedient_services.general.validate(function (data) {
                                if (data && data.minor) {
                                    minor_1 = data.minor;
                                }
                                resolve(true);
                            });
                        });
                        return [4 /*yield*/, general_async];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.expedient_services.relations.tutors && minor_1)) return [3 /*break*/, 4];
                        relations_tutors_async = new Promise(function (resolve, reject) {
                            _this.expedient_services.relations.tutors.validate(function (data) {
                                if (data.list.list.length > 0) {
                                    resolve(true);
                                }
                                else {
                                    _this.toastr.error("El paciente es menor de edad, por lo que es obligatorio que se asigne un tutor.", "Paciente Menor de Edad");
                                    reject(false);
                                }
                            });
                        });
                        return [4 /*yield*/, relations_tutors_async];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!this.expedient_services.general) return [3 /*break*/, 6];
                        general_async = new Promise(function (resolve, reject) {
                            _this.expedient_services.general.submit(function (data) {
                                if (data && data.id_user) {
                                    _this.current_state.id_user = data.id_user;
                                    resolve(data.id_user);
                                }
                                else {
                                    reject(data);
                                }
                            });
                        });
                        return [4 /*yield*/, general_async];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        if (!this.current_state.id_user) return [3 /*break*/, 17];
                        if (!this.expedient_services.relations.tutors) return [3 /*break*/, 8];
                        relations_tutors_async = new Promise(function (resolve, reject) {
                            _this.expedient_services.relations.tutors.submit(resolve);
                        });
                        return [4 /*yield*/, relations_tutors_async];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8:
                        if (!this.expedient_services.contacts.doctor) return [3 /*break*/, 10];
                        contacts_doctor_async = new Promise(function (resolve, reject) {
                            _this.expedient_services.contacts.doctor.submit(resolve);
                        });
                        return [4 /*yield*/, contacts_doctor_async];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        if (!this.expedient_services.contacts.emergency) return [3 /*break*/, 12];
                        contacts_emergency_async = new Promise(function (resolve, reject) {
                            _this.expedient_services.contacts.emergency.submit(resolve);
                        });
                        return [4 /*yield*/, contacts_emergency_async];
                    case 11:
                        _a.sent();
                        _a.label = 12;
                    case 12:
                        if (!this.expedient_services.background.pathologies) return [3 /*break*/, 14];
                        background_pathologies_async = new Promise(function (resolve, reject) {
                            _this.expedient_services.background.pathologies.submit(resolve);
                        });
                        return [4 /*yield*/, background_pathologies_async];
                    case 13:
                        _a.sent();
                        _a.label = 14;
                    case 14:
                        if (!this.expedient_services.background.allergies) return [3 /*break*/, 16];
                        background_allergies_async = new Promise(function (resolve, reject) {
                            _this.expedient_services.background.allergies.submit(resolve);
                        });
                        return [4 /*yield*/, background_allergies_async];
                    case 15:
                        _a.sent();
                        _a.label = 16;
                    case 16:
                        // // INSURANCES
                        // if (this.expedient_services.insurances.accident) {
                        //   let insurances_accident_async = new Promise((resolve, reject) => {
                        //     this.expedient_services.insurances.accident.submit(resolve);
                        //   });
                        //   await insurances_accident_async;
                        // }
                        // // RELATIONS
                        // if (this.expedient_services.institutions) {
                        //   let institutions_async = new Promise((resolve, reject) => {
                        //     this.expedient_services.institutions.submit(resolve);
                        //   });
                        //   await institutions_async;
                        // }
                        this.end_submit_expedient_services();
                        _a.label = 17;
                    case 17: return [3 /*break*/, 19];
                    case 18:
                        error_1 = _a.sent();
                        this.current_state.loading = false;
                        console.error(error_1);
                        return [3 /*break*/, 19];
                    case 19: return [2 /*return*/];
                }
            });
        });
    };
    ExpedientService.prototype.update_global = function () {
        this.patientService.load_patient_data();
    };
    ExpedientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExpedientService_Factory() { return new ExpedientService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.PatientService), i0.ɵɵinject(i3.ToastrService)); }, token: ExpedientService, providedIn: "root" });
    return ExpedientService;
}());
export { ExpedientService };
