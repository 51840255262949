import { SocketIoConfig } from "ngx-socket-io";
import { environment } from "src/environments/environment";
var config = {
    url: environment.doctor_server,
    options: {
        transports: environment.socket.transport,
    },
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
