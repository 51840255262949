import { Routes, ExtraOptions } from "@angular/router";
import { LayoutComponent } from "./layouts/layout.component";
import { MyPatientsComponent } from "./pages/my_patients/my_patients.component";
import { MyPatientsGuard } from "./guards/my-patients.guard";
import { HomeComponent } from "./pages/home/home.component";
import { ConsultsMedicalComponent } from "./pages/medical_file/consults-medical/consults-medical.component";
import { AuthGuard } from "./guards/auth.guard";
import { NoAuthGuard } from "./guards/no-auth.guard";
import { LoginComponent } from "./pages/login/login.component";
import { Steps2Component } from "./pages/steps_2/steps_2.component";
import { FirstLoginComponent } from "./pages/first_login/first_login.component";
import { PasswordresetComponent } from "./pages/passwordreset/passwordreset.component";
import { ResetPasswordComponent } from "./pages/reset_password/reset_password.component";
import { FirstLoginGuard } from "./guards/first-login.guard";
import { SearchBarComponent } from "./layouts/search_bar/search_bar.component";
import { ScheduleComponent } from "./pages/expedient/schedule/schedule.component";
var ɵ0 = function () {
    return import("./pages/generic-expedient/generic-expedient.module.ngfactory").then(function (m) { return m.GenericExpedientModuleNgFactory; });
}, ɵ1 = function () {
    return import("./pages/profile/profile.module.ngfactory").then(function (m) { return m.ProfileModuleNgFactory; });
}, ɵ2 = function () {
    return import("./pages/settings/settings.module.ngfactory").then(function (m) { return m.SettingsModuleNgFactory; });
};
var routes = [
    // {
    //   path: '',
    //   redirectTo: '/conectarse',
    //   pathMatch: 'full'
    // },
    {
        path: "conectarse",
        component: LoginComponent,
        canActivate: [NoAuthGuard],
    },
    {
        path: "primer_inicio",
        component: FirstLoginComponent,
        canActivate: [FirstLoginGuard],
    },
    {
        path: "dos_pasos",
        component: Steps2Component,
        canActivate: [AuthGuard],
    },
    {
        path: "recuperar-contraseña",
        component: PasswordresetComponent,
        canActivate: [NoAuthGuard],
    },
    {
        path: "restaurar_contrasena/:token",
        component: ResetPasswordComponent,
        canActivate: [NoAuthGuard],
    },
    {
        path: "buscar",
        component: SearchBarComponent,
        canActivate: [AuthGuard],
    },
    // {
    //     path: "tratamiento",
    //     outlet: "overlay",
    //     loadChildren: () => import("./pages/expedient/new_consult/general/pages/treatment/treatment.module").then((m) => m.ConsultTreatmentModule),
    // },
    {
        path: "",
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "",
                redirectTo: "/perfil/general",
                pathMatch: "full",
            },
            {
                path: "inicio",
                component: HomeComponent,
            },
            {
                path: "",
                loadChildren: ɵ0,
            },
            {
                path: "",
                loadChildren: ɵ1,
            },
            {
                path: "mis_pacientes",
                component: MyPatientsComponent,
                canActivate: [MyPatientsGuard],
            },
            {
                path: "mis_consultas",
                component: ConsultsMedicalComponent,
                canActivate: [MyPatientsGuard],
            },
            {
                path: "agenda",
                component: ScheduleComponent,
                canActivate: [MyPatientsGuard],
            },
            {
                path: "configuraciones",
                loadChildren: ɵ2,
                canActivate: [MyPatientsGuard],
            },
        ],
    },
    {
        path: "**",
        redirectTo: "/conectarse",
        pathMatch: "full",
    },
];
var routerOptions = {
    scrollPositionRestoration: "top",
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
