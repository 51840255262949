import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
var ShowMedicineComponent = /** @class */ (function () {
    function ShowMedicineComponent(endpoint, toastr, patientServiceService, pageService, catalogsService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.patientServiceService = patientServiceService;
        this.pageService = pageService;
        this.catalogsService = catalogsService;
        this.data = {
            id_patient: "",
            name: "",
            amount: "",
            id_presentation: "",
            id_concentration: "",
            concentration: "",
            id_way: "",
            id_frecuency: "",
            frecuency: "",
            id_days: "",
            days: ""
        };
    }
    ShowMedicineComponent.prototype.ngOnInit = function () {
        this.form_configs();
    };
    ShowMedicineComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return true; },
            disabled: function () { return true; },
            submit: function () { },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "name",
                            type: "name",
                            label: "Nombre",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 45; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "amount",
                            type: "number",
                            label: "Cantidad",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_presentation",
                            type: "select",
                            label: "Presentación",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.medicines_presentations; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "concentration",
                            type: "text",
                            label: "Concentración",
                            small: "",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 45; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_way",
                            type: "select",
                            label: "Vía",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.medicines_ways; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "frecuency_parsed",
                            type: "text",
                            label: "Frecuencia",
                            col_class: "col-md-6",
                            disabled: function () { return false; },
                        },
                        {
                            name: "days_parsed",
                            type: "text",
                            label: "Duración",
                            placeholder: function () { return ".."; },
                            col_class: "col-md-6",
                            disabled: function () { return false; },
                        },
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-danger ml-auto",
                                    label: "Cerrar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'right',
                                    },
                                    disabled: function () { return false; },
                                    click: function () { return _this.close(); }
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    ShowMedicineComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowMedicineComponent;
}());
export { ShowMedicineComponent };
