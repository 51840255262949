import { ConsultGeneralService } from 'src/app/pages/expedient/new_consult/general/consult.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import { PageService } from '../../services/page.service';
import { PatientService } from '../../services/patient-service.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/page.service";
import * as i2 from "../../services/patient-service.service";
import * as i3 from "../../services/expedient.service";
import * as i4 from "../../pages/expedient/new_consult/general/consult.service";
var PatientInternalGuard = /** @class */ (function () {
    function PatientInternalGuard(pageService, patientService, expedientService, consultService) {
        this.pageService = pageService;
        this.patientService = patientService;
        this.expedientService = expedientService;
        this.consultService = consultService;
    }
    PatientInternalGuard.prototype.canActivate = function () {
        var _this = this;
        this.pageService.set_consult(false);
        this.pageService.set_profile(false);
        this.pageService.set_expedient(true);
        this.patientService.init(function (data) {
            _this.expedientService.init({
                version: "expedient",
                method: "show",
                id_user: data.general.id_user
            });
            // this.consultService.new_consult(null, false);
        });
        return true;
    };
    PatientInternalGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatientInternalGuard_Factory() { return new PatientInternalGuard(i0.ɵɵinject(i1.PageService), i0.ɵɵinject(i2.PatientService), i0.ɵɵinject(i3.ExpedientService), i0.ɵɵinject(i4.ConsultGeneralService)); }, token: PatientInternalGuard, providedIn: "root" });
    return PatientInternalGuard;
}());
export { PatientInternalGuard };
