import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PageService } from 'src/app/services/page.service';
import { AppService } from 'src/app/services/app.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../../../../services/page.service";
import * as i4 from "../../../../services/expedient.service";
;
;
;
var AccidentService = /** @class */ (function () {
    function AccidentService(endpoint, toastr, pageService, expedientService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.expedientService = expedientService;
        this.store_list_name = "unimed-expedient-insurances-accident-list";
        this.store_name = "unimed-expedient-insurances-accident";
    }
    // LIST FUNCTIONS
    AccidentService.prototype.init_list = function () {
        var _this = this;
        this.expedientService.set_expedient_service({ store_url: this.store_list_name, submit: function (callback) { return _this.submit_stored(callback); } }, "insurances", "accident");
        this.clean_list();
        this.list_next();
    };
    AccidentService.prototype.reload_list = function (data) {
        if (this.expedientService.state.id_user) {
            this.get_list(data);
        }
        else {
            this.get_stored_list();
        }
    };
    AccidentService.prototype.get_list = function (data) {
        var _this = this;
        this.current_filters = data;
        var load = tslib_1.__assign({ id_user: this.expedientService.state.id_user }, data);
        this.endpoint.expedient_insurances_accident_get_accidents(load).subscribe(function (data) { return _this.list = data; }, function (error) { return console.error(error); }, function () {
            _this.list_next();
        });
    };
    AccidentService.prototype.get_stored_list = function () {
        var _this = this;
        setTimeout(function () {
            var stored = localStorage.getItem(_this.store_list_name);
            if (stored) {
                _this.list = JSON.parse(stored);
            }
            else {
                _this.clean_list();
                _this.list.loaded = true;
                _this.save_list();
            }
            _this.list_next();
        }, 1);
    };
    AccidentService.prototype.list_next = function () {
        if (this.list_state)
            this.list_state.next(this.list);
        else {
            this.list_state = new BehaviorSubject(this.list);
            this.obv_list = this.list_state.asObservable();
        }
    };
    AccidentService.prototype.clean_list = function () {
        return this.list = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
    };
    AccidentService.prototype.save_list = function () {
        localStorage.setItem(this.store_list_name, JSON.stringify(this.list));
    };
    // EVENT FUNCTIONS
    AccidentService.prototype.init = function (accident) {
        if (accident === void 0) { accident = null; }
        if (accident) {
            if (this.expedientService.state.method == "update") {
                accident.img_back = [{ relativePath: accident.img_back[0], insecure: true }];
                accident.img_front = [{ relativePath: accident.img_front[0], insecure: true }];
            }
            this.current_state = accident;
            this.current_state.loading = false;
            this.current_state.submitted = false;
            this.current_state.timestamp = new Date;
        }
        else
            this.internal_clean();
        this.next();
        this.forms = {
            accident: null
        };
        this.forms_state = new BehaviorSubject(this.forms);
        this.obv_forms = this.forms_state.asObservable();
    };
    AccidentService.prototype.reload = function () {
        if (this.expedientService.state.id_user) {
            this.get_patient_data();
        }
    };
    AccidentService.prototype.get_current_state = function () {
        return this.current_state;
    };
    AccidentService.prototype.get_patient_data = function () {
        var _this = this;
        var load = {
            id_user: this.expedientService.state.id_user
        };
        this.endpoint.expedient_insurances_accident_get_accidents(load).subscribe(function (data) { return _this.current_state = data; }, function (error) { return console.error(error); }, function () {
            _this.save();
            _this.next();
        });
    };
    AccidentService.prototype.refresh_forms = function () {
        this.forms_state.next(this.forms);
    };
    AccidentService.prototype.next = function () {
        if (this.module_state)
            this.module_state.next(this.current_state);
        else {
            this.module_state = new BehaviorSubject(this.current_state);
            this.current = this.module_state.asObservable();
        }
    };
    AccidentService.prototype.valid = function () {
        return !this.current_state.loading &&
            this.forms && this.forms.accident && this.forms.accident.valid ? this.forms.accident.valid() : false;
    };
    AccidentService.prototype.set_form = function (form) {
        this.forms[form.name] = form.module;
    };
    AccidentService.prototype.save = function () {
        localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
    };
    AccidentService.prototype.submit = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        if (!(this.forms && this.forms.accident && this.forms.accident.valid()) || this.current_state.loading) {
            callback();
            return false;
        }
        this.current_state.id_user = this.expedientService.state.id_user;
        this.current_state.submitted = true;
        this.current_state.loading = true;
        this.module_state.next(this.current_state);
        var formData = new FormData();
        for (var item in this.current_state) {
            if (item != "img_back_file" && item != "img_front_file" && item != "img_back" && item != "img_front" && this.current_state[item]) {
                formData.append(item, this.current_state[item]);
            }
        }
        if (this.expedientService.state.id_user) {
            if (this.expedientService.state.method == "insert") {
                this.insert(formData, callback);
            }
            else {
                this.update(formData, callback);
            }
        }
        else {
            if (this.expedientService.state.method == "insert") {
                this.insert_store(callback);
            }
            else {
                this.update_store(callback);
            }
        }
    };
    AccidentService.prototype.insert = function (formData, callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        if (this.current_state.img_front) {
            for (var _i = 0, _a = this.current_state.img_front; _i < _a.length; _i++) {
                var droppedFile = _a[_i];
                if (!droppedFile.insecure && droppedFile.fileEntry.isFile) {
                    var fileEntry = droppedFile.fileEntry;
                    fileEntry.file(function (file) {
                        formData.append("img_front", file, file.name);
                    });
                }
            }
        }
        if (this.current_state.img_back) {
            for (var _b = 0, _c = this.current_state.img_back; _b < _c.length; _b++) {
                var droppedFile = _c[_b];
                if (!droppedFile.insecure && droppedFile.fileEntry.isFile) {
                    var fileEntry = droppedFile.fileEntry;
                    fileEntry.file(function (file) {
                        formData.append("img_back", file, file.name);
                    });
                }
            }
        }
        this.endpoint.expedient_insurances_accident_insert_accident(formData).subscribe(function (data) {
            if (_this.expedientService.state.version == "expedient") {
                localStorage.removeItem("inExpedientInsurancesAccident");
                _this.toastr.success(data["message"], data["title"]);
                _this.current_state.loading = false;
                _this.module_state.next(_this.current_state);
                if (_this.forms.accident)
                    _this.forms.accident.unsubmit();
                _this.expedientService.update_global();
                _this.reload_list(_this.current_filters);
                _this.close();
                _this.clean();
                callback(data);
            }
            else if (_this.expedientService.state.version == "new_patient") {
                callback(data);
            }
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
        });
    };
    AccidentService.prototype.insert_store = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.current_state.id = new Date().getTime();
        this.expedientService.file_list.insurances.accident.push({ id: this.current_state.img_front_file = new Date().getTime(), name: "front", file: this.current_state.img_front });
        this.expedientService.file_list.insurances.accident.push({ id: this.current_state.img_back_file = new Date().getTime() + 1, name: "back", file: this.current_state.img_back });
        // this.current_state.created_at = new Date().toISOString();
        // this.current_state.doctor_name = "Yo";
        this.list.list.push(this.current_state);
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    };
    AccidentService.prototype.update = function (formData, callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        if (this.current_state.img_front) {
            for (var _i = 0, _a = this.current_state.img_front; _i < _a.length; _i++) {
                var droppedFile = _a[_i];
                if (!droppedFile.insecure && droppedFile.fileEntry.isFile) {
                    var fileEntry = droppedFile.fileEntry;
                    fileEntry.file(function (file) {
                        formData.append("img_front_file", file, file.name);
                    });
                }
                else {
                    formData.append("img_front", droppedFile.relativePath);
                }
            }
        }
        if (this.current_state.img_back) {
            for (var _b = 0, _c = this.current_state.img_back; _b < _c.length; _b++) {
                var droppedFile = _c[_b];
                if (!droppedFile.insecure && droppedFile.fileEntry.isFile) {
                    var fileEntry = droppedFile.fileEntry;
                    fileEntry.file(function (file) {
                        formData.append("img_back_file", file, file.name);
                    });
                }
                else {
                    formData.append("img_back", droppedFile.relativePath);
                }
            }
        }
        this.endpoint.expedient_insurances_accident_update_accident(formData).subscribe(function (data) {
            localStorage.removeItem("inExpedientInsurancesAccident");
            _this.toastr.success(data["message"], data["title"]);
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
            if (_this.forms.accident)
                _this.forms.accident.unsubmit();
            _this.expedientService.update_global();
            _this.reload_list(_this.current_filters);
            _this.close();
            _this.clean();
            callback();
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
        });
    };
    AccidentService.prototype.update_store = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        var index = this.list.list.findIndex(function (value) { return value.id == _this.current_state.id; });
        this.list.list[index] = this.current_state;
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    };
    AccidentService.prototype.delete = function () {
        if (this.expedientService.state.id_user) {
            this._delete();
        }
        else {
            this._delete_store();
        }
    };
    AccidentService.prototype._delete = function () {
        var _this = this;
        var load = {
            id: this.current_state.id
        };
        this.endpoint.expedient_insurances_accident_delete_accident(load).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.expedientService.update_global();
            _this.reload_list(_this.current_filters);
            _this.close();
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    AccidentService.prototype._delete_store = function () {
        var _this = this;
        var index = this.list.list.findIndex(function (value) { return value.id == _this.current_state.id; });
        this.list.list.splice(index, 1);
        this.save_list();
        this.list_next();
    };
    AccidentService.prototype.cancel = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        Swal.fire({
            title: "Cancelar Formulario",
            html: "¿Está seguro de cancelar el llenado del formulario actual?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDB915',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cancelar',
            cancelButtonText: 'No'
        }).then(function (result) {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                _this.close();
                callback();
            }
        });
    };
    AccidentService.prototype.close = function () {
        this.pageService.closeModal(true);
    };
    AccidentService.prototype.clean = function () {
        this.internal_clean();
        this.module_state.next(this.current_state);
        this.save();
    };
    AccidentService.prototype.internal_clean = function () {
        this.current_state = {
            id_user: null,
            id: null,
            carrier: "",
            number: "",
            description: "",
            roof: "",
            date_init: "",
            date_finish: "",
            phone: "",
            user_name: "",
            runner_name: "",
            runner_phone: "",
            runner_email: "",
            ambulance: 1,
            hospital: "",
            img_front: null,
            img_back: null,
            img_front_file: null,
            img_back_file: null,
            loading: false,
            submitted: false,
            timestamp: new Date(),
        };
    };
    AccidentService.prototype.submit_stored = function (callback, step) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        if (step === void 0) { step = 0; }
        if (this.list.list.length > 0 && step < this.list.list.length) {
            var formData = new FormData();
            this.current_state = this.list.list[step];
            this.current_state.id_user = this.expedientService.state.id_user;
            for (var item in this.current_state) {
                if (item != "img_back_file" && item != "img_front_file" && item != "img_back" && item != "img_front" && this.current_state[item]) {
                    formData.append(item, this.current_state[item]);
                }
            }
            this.current_state.img_front = this.expedientService.file_list.insurances.accident.find(function (value) { return value.id == _this.current_state.img_front_file; }).file;
            this.current_state.img_back = this.expedientService.file_list.insurances.accident.find(function (value) { return value.id == _this.current_state.img_back_file; }).file;
            this.insert(formData, function () {
                _this.submit_stored(callback, step + 1);
            });
        }
        else {
            callback();
        }
    };
    AccidentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccidentService_Factory() { return new AccidentService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.PageService), i0.ɵɵinject(i4.ExpedientService)); }, token: AccidentService, providedIn: "root" });
    return AccidentService;
}());
export { AccidentService };
