import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PageService } from 'src/app/services/page.service';
import { AppService } from 'src/app/services/app.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import Swal from 'sweetalert2';
import { SocketService } from 'src/app/services/socket.service';
import { AlertService } from 'src/app/shared/ui/alert_service/alert.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { NewPatientService } from 'src/app/services/new_patient.service';
import { PatientService } from 'src/app/services/patient-service.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../../../../services/page.service";
import * as i4 from "../../../../services/socket.service";
import * as i5 from "../../../../services/expedient.service";
import * as i6 from "../../../../services/patient-service.service";
import * as i7 from "../../../../shared/ui/alert_service/alert.service";
import * as i8 from "../../../../services/catalogs.service";
import * as i9 from "../../../../services/new_patient.service";
;
;
;
;
var TutorsService = /** @class */ (function () {
    function TutorsService(endpoint, toastr, pageService, socketService, expedientService, patientService, alertService, catalogsService, newPatientService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.socketService = socketService;
        this.expedientService = expedientService;
        this.patientService = patientService;
        this.alertService = alertService;
        this.catalogsService = catalogsService;
        this.newPatientService = newPatientService;
        this.store_list_name = "unimed-expedient-relations-tutors-list";
        this.store_name = "unimed-expedient-relations-tutors";
        this.init_socket();
    }
    // LIST FUNCTIONS
    TutorsService.prototype.init_list = function () {
        var _this = this;
        this.expedientService.set_expedient_service({ store_url: this.store_list_name, submit: function (callback) { return _this.submit_stored(callback); }, validate: function (callback) { return _this.validate_stored(callback); } }, "relations", "tutors");
        this.clean_list();
        this.list_next();
    };
    TutorsService.prototype.reload_list = function (data) {
        if (this.expedientService.state.id_user) {
            this.get_list(data);
        }
        else {
            this.get_stored_list();
        }
    };
    TutorsService.prototype.get_list = function (data) {
        var _this = this;
        this.current_filters = data;
        var load = tslib_1.__assign({ id_user: this.expedientService.state.id_user }, data);
        this.endpoint.expedient_relations_tutors_get_tutors(load).subscribe(function (data) { return _this.list = data; }, function (error) { return console.error(error); }, function () {
            _this.list_next();
        });
    };
    TutorsService.prototype.get_stored_list = function () {
        var _this = this;
        setTimeout(function () {
            var stored = localStorage.getItem(_this.store_list_name);
            if (stored) {
                _this.list = JSON.parse(stored);
            }
            else {
                _this.clean_list();
                _this.list.loaded = true;
                _this.save_list();
            }
            _this.list_next();
        }, 1);
    };
    TutorsService.prototype.list_next = function () {
        if (this.list_state)
            this.list_state.next(this.list);
        else {
            this.list_state = new BehaviorSubject(this.list);
            this.obv_list = this.list_state.asObservable();
        }
    };
    TutorsService.prototype.clean_list = function () {
        return this.list = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
    };
    TutorsService.prototype.save_list = function () {
        localStorage.setItem(this.store_list_name, JSON.stringify(this.list));
    };
    // EVENT FUNCTIONS
    TutorsService.prototype.init = function (tutors) {
        if (tutors === void 0) { tutors = null; }
        if (tutors) {
            this.current_state = tutors;
            this.current_state.loading = false;
            this.current_state.submitted = false;
            this.current_state.timestamp = new Date;
        }
        else
            this.internal_clean();
        this.next();
        this.forms = {
            tutors: null
        };
        this.forms_state = new BehaviorSubject(this.forms);
        this.obv_forms = this.forms_state.asObservable();
        this.catalogs = {
            countries: []
        };
        this.reload_catalogs();
    };
    TutorsService.prototype.init_socket = function () {
        var _this = this;
        this.socketService.getTutorPhoneVerify().subscribe(function (data) {
            if (_this.current_state.phoneMessageSid === data.sid) {
                var relationship = _this.catalogsService.catalogs.relationships.find(function (v) { return v.id == _this.current_state.id_relationship; });
                if (data.message === "Sí, confirmo") {
                    _this.current_state.verified_phone = 1;
                    _this.current_state.v_phone = _this.current_state.phone;
                    if (!_this.expedientService.state.id_user) {
                        _this.alertService.alert_success("¡Listo!", "Has a\u00F1adido a " + _this.current_state.firstname + " " + _this.current_state.lastname + " como " + (relationship ? relationship.name : "") + " de " + _this.newPatientService.current_state.general.firstname + ".");
                    }
                    else {
                        _this.alertService.alert_success("¡Listo!", "Has a\u00F1adido a " + _this.current_state.firstname + " " + _this.current_state.lastname + " como " + (relationship ? relationship.name : "") + " de " + _this.patientService.patient_data.general.firstname + ".");
                    }
                    _this.submit();
                }
                else if (data.message === "No confirmo") {
                    _this.current_state.verified_phone = 2;
                    if (!_this.expedientService.state.id_user) {
                        _this.alertService.alert_error("¡Oh no!", "Has rechadado a " + _this.current_state.firstname + " " + _this.current_state.lastname + " como " + (relationship ? relationship.name : "") + " de " + _this.newPatientService.current_state.general.firstname + ".");
                    }
                    else {
                        _this.alertService.alert_error("¡Oh no!", "Has rechadado a " + _this.current_state.firstname + " " + _this.current_state.lastname + " como " + (relationship ? relationship.name : "") + " de " + _this.patientService.patient_data.general.firstname + ".");
                    }
                }
            }
        }, function (error) {
            console.error(error);
        });
        // this.socketService.getUserEmailVerify().subscribe(
        //     (data: any) => {
        //         if (this.current_state.emailToken === data.token) {
        //             this.current_state.verified_email = 1;
        //             this.current_state.v_email = this.current_state.email;
        //             this.alertService.alert_success("¡Listo!", "Has verificado tu correo electrónico.");
        //         }
        //     },
        //     error => {
        //         console.error(error)
        //     }
        // );
    };
    TutorsService.prototype.reload_catalogs = function () {
        var _this = this;
        this.endpoint.new_patient_catalogs().subscribe(function (data) { return _this.catalogs = data; }, function (error) { return console.error(error); });
    };
    TutorsService.prototype.get_current_state = function () {
        return this.current_state;
    };
    TutorsService.prototype.refresh_forms = function () {
        this.forms_state.next(this.forms);
    };
    TutorsService.prototype.next = function () {
        if (this.module_state)
            this.module_state.next(this.current_state);
        else {
            this.module_state = new BehaviorSubject(this.current_state);
            this.current = this.module_state.asObservable();
        }
    };
    TutorsService.prototype.valid = function () {
        return !this.current_state.loading &&
            this.forms && this.forms.tutors && this.forms.tutors.valid ? this.forms.tutors.valid() : false;
    };
    TutorsService.prototype.set_form = function (form) {
        this.forms[form.name] = form.module;
    };
    TutorsService.prototype.save = function () {
        localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
    };
    TutorsService.prototype.submit = function (callback) {
        // if (!(this.forms && this.forms.tutors && this.forms.tutors.valid()) || this.current_state.loading) { callback(); return false; }
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.current_state.id_dependant = this.expedientService.state.id_user;
        this.current_state.submitted = true;
        this.current_state.loading = true;
        this.module_state.next(this.current_state);
        if (this.expedientService.state.id_user) {
            if (this.expedientService.state.method == "insert") {
                this.insert(callback);
            }
            else {
                this.update(callback);
            }
        }
        else {
            if (this.expedientService.state.method == "insert") {
                this.insert_store(callback);
            }
            else {
                this.update_store(callback);
            }
        }
    };
    TutorsService.prototype.insert = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.endpoint.expedient_relations_tutors_insert_tutors(this.current_state).subscribe(function (data) {
            if (_this.expedientService.state.version == "expedient") {
                localStorage.removeItem("inExpedientRelationsTutors");
                _this.toastr.success(data["message"], data["title"]);
                _this.current_state.loading = false;
                _this.module_state.next(_this.current_state);
                if (_this.forms.tutors)
                    _this.forms.tutors.unsubmit();
                _this.expedientService.update_global();
                _this.reload_list(_this.current_filters);
                _this.close();
                _this.clean();
                callback(data);
            }
            else if (_this.expedientService.state.version == "new_patient") {
                callback(data);
            }
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
        });
    };
    TutorsService.prototype.insert_bulk = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        var load = {
            tutors: this.list.list,
            id_patient: this.expedientService.state.id_user
        };
        this.endpoint.expedient_relations_tutors_insert_tutors_bulk(load).subscribe(function (data) {
            if (_this.expedientService.state.version == "expedient") {
                localStorage.removeItem("inExpedientRelationsTutors");
                _this.toastr.success(data["message"], data["title"]);
                _this.current_state.loading = false;
                _this.module_state.next(_this.current_state);
                if (_this.forms.tutors)
                    _this.forms.tutors.unsubmit();
                _this.expedientService.update_global();
                _this.reload_list(_this.current_filters);
                _this.close();
                _this.clean();
                callback(data);
            }
            else if (_this.expedientService.state.version == "new_patient") {
                callback({ list: _this.list });
            }
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
        });
    };
    TutorsService.prototype.insert_store = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.current_state.id = new Date().getTime();
        this.current_state.status = "Verificado";
        this.list.list.push(this.current_state);
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    };
    TutorsService.prototype.update = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.endpoint.expedient_relations_tutors_update_tutors(this.current_state).subscribe(function (data) {
            localStorage.removeItem("inExpedientRelationsTutors");
            _this.toastr.success(data["message"], data["title"]);
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
            if (_this.forms.tutors)
                _this.forms.tutors.unsubmit();
            _this.expedientService.update_global();
            _this.reload_list(_this.current_filters);
            _this.close();
            _this.clean();
            callback();
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
        });
    };
    TutorsService.prototype.update_store = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        var index = this.list.list.findIndex(function (value) { return value.id == _this.current_state.id; });
        this.list.list[index] = this.current_state;
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    };
    TutorsService.prototype.delete = function () {
        if (this.expedientService.state.id_user) {
            this._delete();
        }
        else {
            this._delete_store();
        }
    };
    TutorsService.prototype._delete = function () {
        var _this = this;
        var load = {
            id: this.current_state.id,
            id_user: this.current_state.id_user,
            id_dependant: this.current_state.id_dependant,
            accepted: this.current_state.accepted,
        };
        this.endpoint.expedient_relations_tutors_delete_tutors(load).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.expedientService.update_global();
            _this.reload_list(_this.current_filters);
            _this.close();
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    TutorsService.prototype._delete_store = function () {
        var _this = this;
        var index = this.list.list.findIndex(function (value) { return value.id == _this.current_state.id; });
        this.list.list.splice(index, 1);
        this.save_list();
        this.list_next();
    };
    TutorsService.prototype.confirm = function () {
        var _this = this;
        var load = {
            id: this.current_state.id
        };
        this.endpoint.expedient_relations_dependants_confirm_dependants(load).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.expedientService.update_global();
            _this.reload_list(_this.current_filters);
            _this.close();
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    TutorsService.prototype.cancel = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        Swal.fire({
            title: "Cancelar Formulario",
            html: "¿Está seguro de cancelar el llenado del formulario actual?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDB915',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cancelar',
            cancelButtonText: 'No'
        }).then(function (result) {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                _this.close();
                callback();
            }
        });
    };
    TutorsService.prototype.close = function () {
        this.pageService.closeModal(true);
    };
    TutorsService.prototype.clean = function () {
        this.internal_clean();
        this.module_state.next(this.current_state);
        this.save();
    };
    TutorsService.prototype.internal_clean = function () {
        this.current_state = {
            id: null,
            id_user: null,
            id_dependant: null,
            id_country: "97",
            email: "",
            identification: "",
            accepted: 0,
            firstname: "",
            lastname: "",
            id_relationship: null,
            id_gender: 1,
            phone: "",
            v_phone: "",
            phoneMessageSid: "",
            verified_phone: 2,
            loading: false,
            submitted: false,
            timestamp: new Date(),
        };
    };
    TutorsService.prototype.submit_stored = function (callback, step) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        if (step === void 0) { step = 0; }
        if (this.list.list.length > 0) {
            this.insert_bulk(callback);
        }
        else {
            callback({ list: this.list });
        }
    };
    TutorsService.prototype.validate_stored = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        callback({ list: this.list });
    };
    TutorsService.prototype.validate_tutor_phone = function (phone, callback) {
        var _this = this;
        var load = {
            phone: phone
        };
        this.endpoint.expedient_relations_tutors_validate_phone(load).subscribe(function (data) {
            callback(data);
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    TutorsService.prototype.verify_whatsapp = function (load, callback) {
        var _this = this;
        Swal.fire({
            title: "Verificación vía Whatsapp",
            html: "La verificaci\u00F3n del tutor fue enviada por Whatsapp al <strong>" + this.current_state.phone + "</strong>",
            showCloseButton: true,
            onBeforeOpen: function () {
                Swal.showLoading();
                _this.endpoint.whatsapp_send_send_new_tutor_request(load).subscribe(function (data) {
                    _this.current_state.phoneMessageSid = data;
                }, function (error) {
                    console.error(error);
                    _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
                });
            },
            allowOutsideClick: function () { return !Swal.isLoading(); },
        });
        return;
    };
    TutorsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TutorsService_Factory() { return new TutorsService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.PageService), i0.ɵɵinject(i4.SocketService), i0.ɵɵinject(i5.ExpedientService), i0.ɵɵinject(i6.PatientService), i0.ɵɵinject(i7.AlertService), i0.ɵɵinject(i8.CatalogsService), i0.ɵɵinject(i9.NewPatientService)); }, token: TutorsService, providedIn: "root" });
    return TutorsService;
}());
export { TutorsService };
