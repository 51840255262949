import { Routes } from '@angular/router';
import { BackgroundPathologiesComponent } from './pathologies.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: 'patologicos',
        component: BackgroundPathologiesComponent
    }
];
var BackgroundPathologiesModule = /** @class */ (function () {
    function BackgroundPathologiesModule() {
    }
    return BackgroundPathologiesModule;
}());
export { BackgroundPathologiesModule };
