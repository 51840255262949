import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { AccidentService } from '../accident.service';
import { ExpedientService } from 'src/app/services/expedient.service';
var FormComponent = /** @class */ (function () {
    function FormComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) {
            _this.expedient_state = state;
            // this.moduleService.reload();
            _this.form_configs();
        });
        this.accident_subscription = this.moduleService.current.subscribe(function (state) { return _this.accident_state = state; });
        this.form_configs();
    };
    FormComponent.prototype.ngAfterViewInit = function () {
        if (this.accident_state) {
            this.moduleService.forms.accident = this.form_ref;
        }
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.expedient_subscription.unsubscribe();
        this.accident_subscription.unsubscribe();
    };
    FormComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            pretty_view: function () { return _this.expedient_state.method == "show"; },
            disabled: function () { return _this.expedient_state.method == "show"; },
            submit: function (callback) { return _this.moduleService.submit(callback); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "carrier",
                            type: "name",
                            label: "Aseguradora",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "number",
                            type: "text",
                            label: "Número de Póliza",
                            placeholder: function () { return "#######"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "description",
                            type: "textarea",
                            label: "Descripción de Póliza",
                            rows: 2.5,
                            col_class: "col-12",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "roof",
                            type: "decimal",
                            label: "Techo de Monto Asegurado (L)",
                            placeholder: function () { return "#,###,###.##"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "hospital",
                            type: "text",
                            label: "Hospital de Preferencia",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "date_init",
                            type: "calendar",
                            label: "Fecha de Inicio",
                            placeholder: function () { return "##/##/####"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "date_finish",
                            type: "calendar",
                            label: "Fecha de Vencimiento",
                            placeholder: function () { return "##/##/####"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "phone",
                            type: "number",
                            label: "Teléfono de Emergencia",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 30; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "ambulance",
                            type: "select",
                            label: "¿Incluye Traslado en Ambulancia?",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                            },
                            disabled: function () { return false; },
                            catalog: {
                                list: function () { return [{ id: 2, name: "Sí" }, { id: 1, name: "No" }]; },
                                value: "id",
                                text: "name"
                            }
                        },
                        {
                            name: "img_front",
                            type: "upload",
                            path: "insurances",
                            label: "Carnet Frontal",
                            col_class: "col-md-6",
                            amount: 1,
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "img_back",
                            type: "upload",
                            path: "insurances",
                            label: "Carnet Trasero",
                            col_class: "col-md-6",
                            amount: 1,
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                    ],
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "divider",
                            label: "Datos del Corredor",
                            col_class: "col-12",
                            bar: true,
                        },
                        {
                            name: "runner_name",
                            type: "name",
                            label: "Nombre",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "runner_phone",
                            type: "number",
                            label: "Teléfono",
                            placeholder: function () { return "#########"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                                maxlength: {
                                    value: function () { return 30; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "runner_email",
                            type: "email",
                            label: "Correo Electrónico",
                            placeholder: function () { return "alguien@email.com"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return false; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                    ],
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    click: function () {
                                        _this.moduleService.cancel();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cerrar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'left',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "show";
                                    },
                                    click: function () {
                                        _this.moduleService.close();
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Guardar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    show: function () {
                                        return _this.expedient_state.method == "update" || _this.expedient_state.method == "insert";
                                    },
                                    disabled: function () { return (_this.form_ref ? !_this.form_ref.valid() : false) || _this.accident_state.loading; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    return FormComponent;
}());
export { FormComponent };
