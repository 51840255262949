import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PageService } from 'src/app/services/page.service';
import { AppService } from 'src/app/services/app.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../../../../services/page.service";
import * as i4 from "../../../../services/expedient.service";
;
;
;
;
var EmergencyService = /** @class */ (function () {
    function EmergencyService(endpoint, toastr, pageService, expedientService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.expedientService = expedientService;
        this.store_list_name = "unimed-expedient-contacts-emergency-list";
        this.store_name = "unimed-expedient-contacts-emergency";
    }
    // LIST FUNCTIONS
    EmergencyService.prototype.init_list = function () {
        var _this = this;
        this.expedientService.set_expedient_service({ store_url: this.store_list_name, submit: function (callback) { return _this.submit_stored(callback); } }, "contacts", "emergency");
        this.clean_list();
        this.list_next();
    };
    EmergencyService.prototype.reload_list = function (data) {
        if (this.expedientService.state.id_user) {
            this.get_list(data);
        }
        else {
            this.get_stored_list();
        }
    };
    EmergencyService.prototype.get_list = function (data) {
        var _this = this;
        this.current_filters = data;
        var load = tslib_1.__assign({ id_user: this.expedientService.state.id_user }, data);
        this.endpoint.expedient_contacts_emergency_get_emergency(load).subscribe(function (data) { return _this.list = data; }, function (error) { return console.error(error); }, function () {
            _this.list_next();
        });
    };
    EmergencyService.prototype.get_stored_list = function () {
        var _this = this;
        setTimeout(function () {
            var stored = localStorage.getItem(_this.store_list_name);
            if (stored) {
                _this.list = JSON.parse(stored);
            }
            else {
                _this.clean_list();
                _this.list.loaded = true;
                _this.save_list();
            }
            _this.list_next();
        }, 1);
    };
    EmergencyService.prototype.list_next = function () {
        if (this.list_state)
            this.list_state.next(this.list);
        else {
            this.list_state = new BehaviorSubject(this.list);
            this.obv_list = this.list_state.asObservable();
        }
    };
    EmergencyService.prototype.clean_list = function () {
        return this.list = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
    };
    EmergencyService.prototype.save_list = function () {
        localStorage.setItem(this.store_list_name, JSON.stringify(this.list));
    };
    // EVENT FUNCTIONS
    EmergencyService.prototype.init = function (emergency) {
        if (emergency === void 0) { emergency = null; }
        if (emergency) {
            this.current_state = emergency;
            this.current_state.loading = false;
            this.current_state.submitted = false;
            this.current_state.timestamp = new Date;
        }
        else
            this.internal_clean();
        this.next();
        this.forms = {
            emergency: null
        };
        this.forms_state = new BehaviorSubject(this.forms);
        this.obv_forms = this.forms_state.asObservable();
        this.catalogs = {
            relationships: []
        };
        this.reload_catalogs();
    };
    EmergencyService.prototype.reload_catalogs = function () {
        var _this = this;
        this.endpoint.new_patient_catalogs().subscribe(function (data) { return _this.catalogs = data; }, function (error) { return console.error(error); });
    };
    EmergencyService.prototype.get_current_state = function () {
        return this.current_state;
    };
    EmergencyService.prototype.refresh_forms = function () {
        this.forms_state.next(this.forms);
    };
    EmergencyService.prototype.next = function () {
        if (this.module_state)
            this.module_state.next(this.current_state);
        else {
            this.module_state = new BehaviorSubject(this.current_state);
            this.current = this.module_state.asObservable();
        }
    };
    EmergencyService.prototype.valid = function () {
        return !this.current_state.loading &&
            this.forms && this.forms.emergency && this.forms.emergency.valid ? this.forms.emergency.valid() : false;
    };
    EmergencyService.prototype.set_form = function (form) {
        this.forms[form.name] = form.module;
    };
    EmergencyService.prototype.save = function () {
        localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
    };
    EmergencyService.prototype.submit = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        if (!(this.forms && this.forms.emergency && this.forms.emergency.valid()) || this.current_state.loading) {
            callback();
            return false;
        }
        this.current_state.id_user = this.expedientService.state.id_user;
        this.current_state.submitted = true;
        this.current_state.loading = true;
        this.module_state.next(this.current_state);
        if (this.expedientService.state.id_user) {
            if (this.expedientService.state.method == "insert") {
                this.insert(callback);
            }
            else {
                this.update(callback);
            }
        }
        else {
            if (this.expedientService.state.method == "insert") {
                this.insert_store(callback);
            }
            else {
                this.update_store(callback);
            }
        }
    };
    EmergencyService.prototype.insert = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.endpoint.expedient_contacts_emergency_insert_emergency(this.current_state).subscribe(function (data) {
            if (_this.expedientService.state.version == "expedient") {
                localStorage.removeItem("inExpedientContactsEmergency");
                _this.toastr.success(data["message"], data["title"]);
                _this.current_state.loading = false;
                _this.module_state.next(_this.current_state);
                if (_this.forms.emergency)
                    _this.forms.emergency.unsubmit();
                _this.expedientService.update_global();
                _this.reload_list(_this.current_filters);
                _this.close();
                _this.clean();
                callback(data);
            }
            else if (_this.expedientService.state.version == "new_patient") {
                callback(data);
            }
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
        });
    };
    EmergencyService.prototype.insert_store = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.current_state.id = new Date().getTime();
        this.current_state.created_at = new Date().toISOString();
        this.current_state.relationships_name = this.catalogs.relationships.find(function (value) { return value.id == _this.current_state.id_relationship; }).name;
        this.list.list.push(this.current_state);
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    };
    EmergencyService.prototype.update = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.endpoint.expedient_contacts_emergency_update_emergency(this.current_state).subscribe(function (data) {
            if (_this.expedientService.state.version == "expedient") {
                localStorage.removeItem("inExpedientContactsEmergency");
                _this.toastr.success(data["message"], data["title"]);
                _this.current_state.loading = false;
                _this.module_state.next(_this.current_state);
                if (_this.forms.emergency)
                    _this.forms.emergency.unsubmit();
                _this.expedientService.update_global();
                _this.reload_list(_this.current_filters);
                _this.close();
                _this.clean();
                callback(data);
            }
            else if (_this.expedientService.state.version == "new_patient") {
                callback(data);
            }
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
        });
    };
    EmergencyService.prototype.update_store = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        var index = this.list.list.findIndex(function (value) { return value.id == _this.current_state.id; });
        this.current_state.relationships_name = this.catalogs.relationships.find(function (value) { return value.id == _this.current_state.id_relationship; }).name;
        this.list.list[index] = this.current_state;
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    };
    EmergencyService.prototype.delete = function () {
        if (this.expedientService.state.id_user) {
            this._delete();
        }
        else {
            this._delete_store();
        }
    };
    EmergencyService.prototype._delete = function () {
        var _this = this;
        var load = {
            id: this.current_state.id
        };
        this.endpoint.expedient_contacts_emergency_delete_emergency(load).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.expedientService.update_global();
            _this.reload_list(_this.current_filters);
            _this.close();
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    EmergencyService.prototype._delete_store = function () {
        var _this = this;
        var index = this.list.list.findIndex(function (value) { return value.id == _this.current_state.id; });
        this.list.list.splice(index, 1);
        this.save_list();
        this.list_next();
    };
    EmergencyService.prototype.cancel = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        Swal.fire({
            title: "Cancelar Formulario",
            html: "¿Está seguro de cancelar el llenado del formulario actual?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDB915',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cancelar',
            cancelButtonText: 'No'
        }).then(function (result) {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                _this.close();
                callback();
            }
        });
    };
    EmergencyService.prototype.close = function () {
        this.pageService.closeModal(true);
    };
    EmergencyService.prototype.clean = function () {
        this.internal_clean();
        this.module_state.next(this.current_state);
        this.save();
    };
    EmergencyService.prototype.internal_clean = function () {
        this.current_state = {
            id: null,
            id_user: null,
            id_relationship: null,
            firstname: "",
            lastname: "",
            phone: "",
            cellphone: "",
            email: "",
            created_at: "",
            created_by: null,
            loading: false,
            submitted: false,
            timestamp: new Date(),
        };
    };
    EmergencyService.prototype.submit_stored = function (callback, step) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        if (step === void 0) { step = 0; }
        if (this.list.list.length > 0 && step < this.list.list.length) {
            this.current_state = this.list.list[step];
            this.current_state.id_user = this.expedientService.state.id_user;
            this.insert(function () {
                _this.submit_stored(callback, step + 1);
            });
        }
        else {
            callback();
        }
    };
    EmergencyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmergencyService_Factory() { return new EmergencyService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.PageService), i0.ɵɵinject(i4.ExpedientService)); }, token: EmergencyService, providedIn: "root" });
    return EmergencyService;
}());
export { EmergencyService };
