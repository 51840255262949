import { Routes } from '@angular/router';
import { RelationsTutorsComponent } from './tutors.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: 'tutores',
        component: RelationsTutorsComponent
    }
];
var RelationsTutorsModule = /** @class */ (function () {
    function RelationsTutorsModule() {
    }
    return RelationsTutorsModule;
}());
export { RelationsTutorsModule };
