import { Routes } from '@angular/router';
import { ExamsLaboratoryComponent } from './laboratory.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: 'laboratorios',
        component: ExamsLaboratoryComponent
    }
];
var ExamsLaboratoryModule = /** @class */ (function () {
    function ExamsLaboratoryModule() {
    }
    return ExamsLaboratoryModule;
}());
export { ExamsLaboratoryModule };
