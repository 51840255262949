import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { PageService } from '../../../../services/page.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
var AllergiesBriefComponent = /** @class */ (function () {
    function AllergiesBriefComponent(endpoint, patientService, pageService, catalogsService) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.pageService = pageService;
        this.catalogsService = catalogsService;
        this.show = false;
    }
    AllergiesBriefComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patient_subscription = this.patientService.patient_data_subscription.subscribe(function (data) { return _this.patient_state = data; });
    };
    AllergiesBriefComponent.prototype.ngOnDestroy = function () {
        this.patient_subscription.unsubscribe();
    };
    AllergiesBriefComponent.prototype.catalog = function () {
        var _this = this;
        return {
            list: function () { return _this.catalogsService.catalogs.allergies_reactions_levels; },
            text: "name",
            value: "id"
        };
    };
    return AllergiesBriefComponent;
}());
export { AllergiesBriefComponent };
