import { OnDestroy, OnInit } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
import { PageService } from 'src/app/services/page.service';
var GeneralComponent = /** @class */ (function () {
    function GeneralComponent(pageService, expedientService) {
        this.pageService = pageService;
        this.expedientService = expedientService;
    }
    GeneralComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.page_subscription = this.pageService.current.subscribe(function (state) { return _this.page_state = state; });
        this.expedient_subscription = this.expedientService.current.subscribe(function (state) { return _this.expedient_state = state; });
    };
    GeneralComponent.prototype.ngOnDestroy = function () {
        this.page_subscription.unsubscribe();
        this.expedient_subscription.unsubscribe();
    };
    return GeneralComponent;
}());
export { GeneralComponent };
