import { OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
var ThirdSidebarComponent = /** @class */ (function () {
    function ThirdSidebarComponent(endpoint, pageService) {
        this.endpoint = endpoint;
        this.pageService = pageService;
    }
    ThirdSidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.page_subscription = this.pageService.current.subscribe(function (state) { return _this.page_state = state; });
    };
    ThirdSidebarComponent.prototype.ngAfterViewInit = function () {
    };
    ThirdSidebarComponent.prototype.ngOnDestroy = function () {
        this.page_subscription.unsubscribe();
    };
    return ThirdSidebarComponent;
}());
export { ThirdSidebarComponent };
