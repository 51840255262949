import { ExpedientService } from 'src/app/services/expedient.service';
import { PathologiesService } from '../pathologies.service';
var CreateBackgroundPathologiesComponent = /** @class */ (function () {
    function CreateBackgroundPathologiesComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateBackgroundPathologiesComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateBackgroundPathologiesComponent;
}());
export { CreateBackgroundPathologiesComponent };
