import * as tslib_1 from "tslib";
import { CustomClass } from "../custom-class";
var ConnectionsClass = /** @class */ (function (_super) {
    tslib_1.__extends(ConnectionsClass, _super);
    function ConnectionsClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    ConnectionsClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.id_join = data.id_join;
        this.username = data.username;
        this.id_gender = data.id_gender;
        this.id_town = data.id_town;
        this.specialties = data.specialties;
        this.common = data.common;
        this.request = data.request;
        this.created_by = data.created_by;
        this.created_at = data.created_at;
        this.is_sender = data.is_sender;
        this.is_me = data.is_me;
        this.is_connection = data.is_connection;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.image = data.image;
    };
    ConnectionsClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_user = null;
        this.id_join = null;
        this.username = "";
        this.id_gender = 1;
        this.id_town = null;
        this.specialties = null;
        this.common = 0;
        this.request = true;
        this.created_by = null;
        this.created_at = new Date();
        this.is_sender = false;
        this.is_me = false;
        this.is_connection = false;
        this.firstname = "";
        this.lastname = "";
        this.image = null;
    };
    ConnectionsClass.prototype.format = function () {
        return this;
    };
    ConnectionsClass.prototype.valid = function () {
        return true;
    };
    ConnectionsClass.prototype.to_string = function () {
        var data = {
            id: this.id,
            id_user: this.id_user,
            id_join: this.id_join,
            username: this.username,
            id_gender: this.id_gender,
            id_town: this.id_town,
            specialties: this.specialties,
            common: this.common,
            request: this.request,
            created_by: this.created_by,
            created_at: this.created_at,
            is_sender: this.is_sender,
            is_me: this.is_me,
            is_connection: this.is_connection,
            firstname: this.firstname,
            lastname: this.lastname,
            image: this.image,
        };
        return data;
    };
    ConnectionsClass.prototype.raw = function () {
        return {
            id: this.id,
            id_user: this.id_user,
            id_join: this.id_join,
            username: this.username,
            id_gender: this.id_gender,
            id_town: this.id_town,
            specialties: this.specialties,
            common: this.common,
            request: this.request,
            created_by: this.created_by,
            created_at: this.created_at,
            is_sender: this.is_sender,
            is_me: this.is_me,
            is_connection: this.is_connection,
            firstname: this.firstname,
            lastname: this.lastname,
            image: this.image,
        };
    };
    return ConnectionsClass;
}(CustomClass));
export { ConnectionsClass };
