import { Routes } from '@angular/router';
import { ContactsDoctorComponent } from './doctor.component';
var routes = [
    {
        pathMatch: 'prefix',
        path: 'medico',
        component: ContactsDoctorComponent
    }
];
var ContactsDoctorModule = /** @class */ (function () {
    function ContactsDoctorModule() {
    }
    return ContactsDoctorModule;
}());
export { ContactsDoctorModule };
