import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { PageService } from "src/app/services/page.service";
import { PatientService } from "src/app/services/patient-service.service";
import { AppService } from "src/app/services/app.service";
import { PdfService } from "src/app/services/pdf.service";
import { AccountService } from "src/app/services/account.service";
import { ConsultClass, } from "../../../../classes/consult-class";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PipesService } from "src/app/services/pipes.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../../../../services/page.service";
import * as i4 from "../../../../services/patient-service.service";
import * as i5 from "../../../../services/pdf.service";
import * as i6 from "../../../../services/account.service";
import * as i7 from "../../../../services/catalogs.service";
import * as i8 from "../../../../services/pipes.service";
var ConsultGeneralService = /** @class */ (function () {
    function ConsultGeneralService(endpoint, toastr, pageService, patientServiceService, pdfService, accountService, catalogsService, pipesService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.patientServiceService = patientServiceService;
        this.pdfService = pdfService;
        this.accountService = accountService;
        this.catalogsService = catalogsService;
        this.pipesService = pipesService;
        this.store_name = "unimed-expedient-consult-id";
        this.loading_timeout = null;
        this.clean();
        this.next();
    }
    ConsultGeneralService.prototype.load_storage = function () {
        this.set_loading(true, 0);
        var id = this.get_storage();
        if (id) {
            this.load(id);
        }
    };
    ConsultGeneralService.prototype.new_consult = function () {
        this.init_consult();
    };
    ConsultGeneralService.prototype.load = function (id) {
        this.get_consult(id);
    };
    ConsultGeneralService.prototype.next = function () {
        if (this.consult_state) {
            this.consult_state.next(this.current_state);
        }
        else {
            this.consult_state = new BehaviorSubject(this.current_state);
            this.current = this.consult_state.asObservable();
        }
    };
    ConsultGeneralService.prototype.valid = function () {
        return this.current_state.consult.valid();
    };
    ConsultGeneralService.prototype.get_current_state = function () {
        return this.current_state;
    };
    ConsultGeneralService.prototype.init_consult = function () {
        var _this = this;
        this.set_loading(true, 0);
        var load = {
            id_patient: this.patientServiceService.patient_data.general.id_user,
        };
        this.endpoint.expedient_consult_general_init_consult(load).subscribe(function (data) {
            _this.current_state.method = "update";
            _this.current_state.consult.init(data);
            _this.save_storage();
            _this.set_loading(false, 1000);
        }, function (error) {
            console.error(error);
            _this.set_loading(false, 1000);
        });
    };
    ConsultGeneralService.prototype.get_consult = function (id) {
        var _this = this;
        var load = {
            id: id,
        };
        this.endpoint.expedient_consult_general_get_consult(load).subscribe(function (data) {
            console.log(data);
            console.log(_this.current_state.consult);
            _this.current_state.method = "update";
            _this.current_state.consult.init(data);
            _this.set_loading(false, 1000);
            _this.next();
        }, function (error) {
            console.error(error);
            _this.set_loading(false, 1000);
        });
    };
    ConsultGeneralService.prototype.save_consult = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function () { }; }
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_save_consult(this.current_state.consult.raw())
            .subscribe(function (data) {
            _this.get_consult(_this.current_state.consult.id);
            callback();
        }, function (error) {
            console.error(error);
            _this.set_loading(false, 1000);
        });
    };
    ConsultGeneralService.prototype.finish_consult = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function () { }; }
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_finish_consult(this.current_state.consult.raw())
            .subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            callback();
            _this.remove_storage();
            localStorage.removeItem("inConsult");
            // this.endpoint.redirect_to("/expediente");
            _this.endpoint.redirect_to("/expediente/consulta/" + _this.current_state.consult.id);
        }, function (error) {
            console.error(error);
            _this.set_loading(false, 1000);
        });
    };
    ConsultGeneralService.prototype.cancel_consult = function (callback, id) {
        var _this = this;
        if (callback === void 0) { callback = function () { }; }
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_cancel_consult(!id ? this.current_state.consult.raw() : { id: id })
            .subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            callback();
            _this.remove_storage();
            localStorage.removeItem("inConsult");
            _this.endpoint.redirect_to("/expediente");
        }, function (error) {
            console.error(error);
            _this.set_loading(false, 1000);
        });
    };
    ConsultGeneralService.prototype.set_loading = function (option, time) {
        var _this = this;
        if (time === void 0) { time = 1000; }
        clearTimeout(this.loading_timeout);
        this.loading_timeout = setTimeout(function () {
            _this.current_state.loading = option;
        }, time);
    };
    ConsultGeneralService.prototype.save_storage = function (id) {
        if (id === void 0) { id = this.current_state.consult.id; }
        localStorage.setItem(this.store_name, id);
    };
    ConsultGeneralService.prototype.get_storage = function () {
        return localStorage.getItem(this.store_name);
    };
    ConsultGeneralService.prototype.remove_storage = function () {
        localStorage.removeItem(this.store_name);
    };
    ConsultGeneralService.prototype.cancel = function (id, callback) {
        var _this = this;
        this.pageService.cancel_consult(function () {
            _this.cancel_consult(function () {
                _this.remove_storage();
                if (id) {
                    callback();
                }
                else {
                    _this.endpoint.redirect_to("/expediente/consultas");
                }
            }, id);
        });
    };
    ConsultGeneralService.prototype.close = function (callback) {
        if (callback === void 0) { callback = function () { }; }
        this.pageService.close_consult(function () {
            callback();
        });
    };
    ConsultGeneralService.prototype.save_close_consult = function () {
        var _this = this;
        this.close(function () {
            _this.set_loading(true, 0);
            _this.save_consult(function () {
                _this.remove_storage();
                _this.endpoint.redirect_to("/expediente/consultas");
            });
        });
    };
    ConsultGeneralService.prototype.save_minimize_consult = function () {
        var _this = this;
        this.set_loading(true, 0);
        this.save_consult(function () {
            localStorage.removeItem("inConsult");
            _this.endpoint.redirect_to("/expediente");
        });
    };
    ConsultGeneralService.prototype.clean = function (callback) {
        if (callback === void 0) { callback = function () { }; }
        this.current_state = {
            consult: new ConsultClass(this.catalogsService, this.pipesService),
            extras: [],
            method: "update",
            loading: true,
            overlay: false,
            submitted: false,
            timestamp: new Date(),
        };
        callback();
    };
    ConsultGeneralService.prototype.pdf_fragments_push = function (fragment) {
        var _a;
        (_a = this.pdf_fragments).push.apply(_a, fragment);
    };
    ConsultGeneralService.prototype.create_pdf = function () {
        // let account = this.accountService.get_current_state();
        // let patient = this.patientServiceService.patient_data.general;
        // this.pdf_fragments = [];
        // this.pdfService.init({
        //   title: "Consulta - " + patient.firstname + " " + patient.lastname,
        //   author: (account.id_gender == 1 ? "Dra. " : "Dr. ") + account.firstname + " " + account.lastname,
        //   subject: "Consulta General",
        //   keywords: "UNIMED Plataforma",
        //   creator: "UNIMED Plataforma",
        //   producer: "UNIMED Plataforma",
        // });
        // this.pdfService.definiton.content = this.pdf_fragments;
        // this.pdfService.create();
        // this.pdfService.open();
    };
    ConsultGeneralService.prototype.full_clean = function (callback) {
        if (callback === void 0) { callback = function () { }; }
        this.current_state.consult.reset();
        callback();
    };
    ConsultGeneralService.prototype.set_overlay = function (option) {
        // this.current_state.
    };
    ConsultGeneralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsultGeneralService_Factory() { return new ConsultGeneralService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.PageService), i0.ɵɵinject(i4.PatientService), i0.ɵɵinject(i5.PdfService), i0.ɵɵinject(i6.AccountService), i0.ɵɵinject(i7.CatalogsService), i0.ɵɵinject(i8.PipesService)); }, token: ConsultGeneralService, providedIn: "root" });
    return ConsultGeneralService;
}());
export { ConsultGeneralService };
