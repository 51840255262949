import * as tslib_1 from "tslib";
import { CustomClass } from "../custom-class";
var ExperiencesClass = /** @class */ (function (_super) {
    tslib_1.__extends(ExperiencesClass, _super);
    function ExperiencesClass(catalogsService, data) {
        var _this = _super.call(this) || this;
        _this.catalogsService = catalogsService;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    ExperiencesClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.id_type = data.id_type;
        this.name = data.name;
        this.company = data.company;
        this.place = data.place;
        this.current = data.current;
        this.date_init = data.date_init;
        this.date_finish = data.date_finish;
        this.description = data.description;
    };
    ExperiencesClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.id_user = null;
        this.id_type = null;
        this.place = "";
        this.company = "";
        this.name = "";
        this.current = [];
        this.date_init = new Date();
        this.date_finish = new Date();
        this.description = "";
    };
    ExperiencesClass.prototype.format = function () {
        return this;
    };
    ExperiencesClass.prototype.valid = function () {
        return true;
    };
    ExperiencesClass.prototype.to_string = function () {
        var _this = this;
        var type = this.catalogsService.catalogs.profiles_experiences_types.find(function (v) { return v.id == _this.id; });
        var data = {
            type: type ? type.name : "",
            name: this.name,
            company: this.company,
            place: this.place,
            current: this.current,
            date_init: this.date_init,
            date_finish: this.date_finish,
            description: this.description,
        };
        return data;
    };
    ExperiencesClass.prototype.raw = function () {
        return {
            id: this.id,
            id_user: this.id_user,
            id_type: this.id_type,
            company: this.company,
            name: this.name,
            place: this.place,
            current: this.current,
            date_init: this.date_init,
            date_finish: this.date_finish,
            description: this.description,
        };
    };
    return ExperiencesClass;
}(CustomClass));
export { ExperiencesClass };
