import { DoctorService } from '../doctor.service';
var UpdateContactsDoctorComponent = /** @class */ (function () {
    function UpdateContactsDoctorComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateContactsDoctorComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateContactsDoctorComponent;
}());
export { UpdateContactsDoctorComponent };
