import { OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
var SecondSidebarComponent = /** @class */ (function () {
    function SecondSidebarComponent(endpoint, pageService, location, router) {
        var _this = this;
        this.endpoint = endpoint;
        this.pageService = pageService;
        this.location = location;
        this.router = router;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            _this.showGrowthCurves = _this.location.path() === '/expediente/curvas_crecimiento';
        });
    }
    SecondSidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.page_subscription = this.pageService.current.subscribe(function (state) { return _this.page_state = state; });
    };
    SecondSidebarComponent.prototype.ngAfterViewInit = function () {
    };
    SecondSidebarComponent.prototype.ngOnDestroy = function () {
        this.page_subscription.unsubscribe();
    };
    return SecondSidebarComponent;
}());
export { SecondSidebarComponent };
