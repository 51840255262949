import { PipeTransform } from '@angular/core';
import { PipesService } from 'src/app/services/pipes.service';
var Names = /** @class */ (function () {
    function Names(pipesService) {
        this.pipesService = pipesService;
    }
    Names.prototype.transform = function (str) {
        return this.pipesService.names(str);
    };
    return Names;
}());
export { Names };
