import { ExpedientService } from 'src/app/services/expedient.service';
import { TutorsService } from '../tutors.service';
var CreateRelationsTutorsComponent = /** @class */ (function () {
    function CreateRelationsTutorsComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateRelationsTutorsComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateRelationsTutorsComponent;
}());
export { CreateRelationsTutorsComponent };
