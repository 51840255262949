import { OnInit, EventEmitter } from "@angular/core";
var DatatableComponent = /** @class */ (function () {
    function DatatableComponent() {
        this.update_datatable = new EventEmitter();
        this.events_datatable = new EventEmitter();
        this.possible_indexes = new Array(7).fill(-1);
        this.headers = [];
        this.data = {
            skip: 0,
            count: 0,
            list: [],
        };
        this.filters = {
            search_word: "",
            skip: 0,
            limit: 10,
        };
        this.events = [];
    }
    Object.defineProperty(DatatableComponent.prototype, "in_content", {
        set: function (content) {
            this.content = content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatatableComponent.prototype, "in_headers", {
        set: function (headers) {
            this.headers = headers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatatableComponent.prototype, "in_events", {
        set: function (events) {
            this.events = events;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatatableComponent.prototype, "in_data", {
        set: function (data) {
            this.data = data;
            if (data.skip)
                this.filters.skip = data.skip;
            this.calculate_pagination();
            this.loading = false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatatableComponent.prototype, "in_filters", {
        set: function (filters) {
            this.filters = filters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatatableComponent.prototype, "in_configs", {
        set: function (configs) {
            this.configs = configs;
        },
        enumerable: true,
        configurable: true
    });
    DatatableComponent.prototype.ngOnInit = function () {
        this.init();
    };
    DatatableComponent.prototype.init = function () {
        this.update();
    };
    DatatableComponent.prototype.update = function () {
        this.loading = true;
        this.update_datatable.emit(this.filters);
    };
    DatatableComponent.prototype.event = function (name, item) {
        this.events_datatable.emit({ name: name, item: item });
    };
    DatatableComponent.prototype.orderBy = function (label) {
        this.filters.order_asc = this.filters.order_by == label ? !this.filters.order_asc : true;
        this.filters.order_by = label;
        this.update();
    };
    DatatableComponent.prototype.goLast = function () {
        this.filters.last = true;
        this.update();
    };
    DatatableComponent.prototype.goFirst = function () {
        this.filters.last = false;
        this.filters.skip = 0;
        this.update();
    };
    DatatableComponent.prototype.go = function (step) {
        this.filters.last = false;
        this.filters.skip += step;
        this.update();
    };
    DatatableComponent.prototype.filter = function () {
        this.filters.skip = 0;
        this.update();
    };
    DatatableComponent.prototype.cleanFilters = function () {
        this.filters.search_word = null;
        this.update();
    };
    DatatableComponent.prototype.calculate_pagination = function () {
        var _this = this;
        this.divider = Math.floor(this.data.count / this.filters.limit);
        this.filters.skip = parseInt(this.data.skip + "");
        this.possible_indexes = [this.filters.skip - 1, this.filters.skip - 2, this.filters.skip - 3, this.filters.skip, this.filters.skip + 1, this.filters.skip + 2, this.filters.skip + 3]
            .map(function (value) {
            if (value < 0)
                value += 7;
            if (value > _this.divider || (_this.data.count % _this.filters.limit == 0 && value >= _this.divider))
                value -= 7;
            return value;
        })
            .sort(function (a, b) { return a - b; });
    };
    DatatableComponent.prototype.row_click = function (item) {
        item.show_content = !item.show_content;
        this.event("row_click", item);
    };
    return DatatableComponent;
}());
export { DatatableComponent };
