import { ExpedientService } from 'src/app/services/expedient.service';
import { LaboratoryService } from '../laboratory.service';
var CreateExamsLaboratoryComponent = /** @class */ (function () {
    function CreateExamsLaboratoryComponent(moduleService, expedientService) {
        this.moduleService = moduleService;
        this.expedientService = expedientService;
        this.expedientService.change_method("insert");
        this.moduleService.init();
    }
    CreateExamsLaboratoryComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return CreateExamsLaboratoryComponent;
}());
export { CreateExamsLaboratoryComponent };
