import { AllergiesService } from '../allergies.service';
var UpdateBackgroundAllergiesComponent = /** @class */ (function () {
    function UpdateBackgroundAllergiesComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateBackgroundAllergiesComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateBackgroundAllergiesComponent;
}());
export { UpdateBackgroundAllergiesComponent };
