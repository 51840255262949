import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./accident/accident.module.ngfactory").then(function (m) { return m.InsurancesAccidentModuleNgFactory; }); };
var routes = [
    {
        path: '',
        redirectTo: 'accidentes',
        pathMatch: 'prefix'
    },
    {
        path: 'accidentes',
        loadChildren: ɵ0
    }
];
var InsurancesModule = /** @class */ (function () {
    function InsurancesModule() {
    }
    return InsurancesModule;
}());
export { InsurancesModule };
export { ɵ0 };
