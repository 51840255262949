import { AppService } from './app.service';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
var PdfService = /** @class */ (function () {
    function PdfService(endpoint) {
        this.endpoint = endpoint;
    }
    PdfService.prototype.create = function (load, endpoint) {
        this.fetch(load, endpoint, function (data) {
            window.open(URL.createObjectURL(data), '_blank');
        });
    };
    PdfService.prototype.fetch = function (load, endpoint, success, error) {
        if (success === void 0) { success = function (data) { return console.info(data); }; }
        if (error === void 0) { error = function (err) { return console.error(err); }; }
        this.endpoint[endpoint](load).subscribe(function (data) { return success(data); }, function (err) { return error(err); });
    };
    PdfService.prototype.jwt = function (load, success, error) {
        if (success === void 0) { success = function (data) { return console.info(data); }; }
        if (error === void 0) { error = function (err) { return console.error(err); }; }
        this.endpoint.jwt_parser(load).subscribe(function (data) { return success(data); }, function (err) { return error(err); });
    };
    PdfService.prototype.print = function (pdf) {
        if (pdf) {
            pdf.print();
        }
    };
    PdfService.prototype.download = function (pdf, name) {
        if (pdf) {
            pdf.download(name);
        }
    };
    PdfService.prototype.open = function (pdf) {
        if (pdf) {
            pdf.open();
        }
    };
    PdfService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PdfService_Factory() { return new PdfService(i0.ɵɵinject(i1.AppService)); }, token: PdfService, providedIn: "root" });
    return PdfService;
}());
export { PdfService };
