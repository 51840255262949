import { OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import Swal from 'sweetalert2';
var PasswordresetComponent = /** @class */ (function () {
    function PasswordresetComponent(formBuilder, endpoint) {
        this.formBuilder = formBuilder;
        this.endpoint = endpoint;
        this.release_year = 2020;
        this.year = new Date().getFullYear();
        this.submitted = false;
        this.error = '';
        this.success = '';
        this.loading = false;
        this.endpoint.pageTitle = 'Recuperar Contraseña';
    }
    PasswordresetComponent.prototype.ngOnInit = function () {
        this.recoverForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    };
    PasswordresetComponent.prototype.ngAfterViewInit = function () {
        document.body.classList.add('authentication-bg');
    };
    Object.defineProperty(PasswordresetComponent.prototype, "f", {
        get: function () { return this.recoverForm.controls; },
        enumerable: true,
        configurable: true
    });
    PasswordresetComponent.prototype.onSubmit = function () {
        var _this = this;
        this.success = '';
        this.submitted = true;
        if (this.recoverForm.invalid) {
            return;
        }
        var params = {
            email: this.f.email.value,
            host: "restaurar_contrasena/"
        };
        this.loading = true;
        var response;
        this.endpoint.recover_password(params)
            .subscribe(function (data) { return response = data; }, function (err) {
            _this.error = err.error.error;
            _this.loading = false;
            Swal.fire({
                title: err.error.title,
                html: err.error.message,
                type: 'error',
                confirmButtonText: 'Listo',
                confirmButtonColor: '#66c8c8',
            }).then(function (result) {
            });
        }, function () {
            _this.loading = false;
            Swal.fire({
                title: response.title,
                html: response.message,
                type: 'info',
                confirmButtonText: 'Listo',
                confirmButtonColor: '#66c8c8',
            }).then(function (result) {
            });
            _this.endpoint.redirect_to('/conectarse');
        });
    };
    ;
    return PasswordresetComponent;
}());
export { PasswordresetComponent };
