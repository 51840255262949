import { AccidentService } from '../accident.service';
var UpdateInsurancesAccidentComponent = /** @class */ (function () {
    function UpdateInsurancesAccidentComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateInsurancesAccidentComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateInsurancesAccidentComponent;
}());
export { UpdateInsurancesAccidentComponent };
