import { BehaviorSubject, Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { PageService } from "src/app/services/page.service";
import { AppService } from "src/app/services/app.service";
import { ExpedientService } from "src/app/services/expedient.service";
import Swal from "sweetalert2";
import { SocketService } from "src/app/services/socket.service";
import { AlertService } from "src/app/shared/ui/alert_service/alert.service";
import { NewPatientService } from "src/app/services/new_patient.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/ui/alert_service/alert.service";
import * as i2 from "../../../services/app.service";
import * as i3 from "ngx-toastr";
import * as i4 from "../../../services/page.service";
import * as i5 from "../../../services/socket.service";
import * as i6 from "../../../services/new_patient.service";
import * as i7 from "../../../services/expedient.service";
var GeneralService = /** @class */ (function () {
    function GeneralService(alertService, endpoint, toastr, pageService, socketService, patientService, expedientService) {
        this.alertService = alertService;
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.socketService = socketService;
        this.patientService = patientService;
        this.expedientService = expedientService;
        this.store_name = "unimed-expedient-general";
        this.init();
        this.init_socket();
    }
    GeneralService.prototype.init = function () {
        var _this = this;
        this.expedientService.set_expedient_service({ submit: function (callback) { return _this.submit(callback); }, validate: function (callback) { return _this.validate(callback); } }, "general");
        var storage = null; //localStorage.getItem(this.store_name);
        if (storage) {
            this.current_state = JSON.parse(storage);
            this.current_state.loading = false;
            this.current_state.submitted = false;
            this.current_state.timestamp = new Date();
        }
        else
            this.internal_clean();
        this.next();
        this.forms = {
            general: null,
        };
        this.forms_state = new BehaviorSubject(this.forms);
        this.obv_forms = this.forms_state.asObservable();
        if (this.expedientService.state.version == "expedient") {
            this.expedientService.change_method("show");
        }
        if (this.expedientService.state.method != "insert") {
            this.reload();
        }
    };
    GeneralService.prototype.init_socket = function () {
        var _this = this;
        this.socketService.getUserPhoneVerify().subscribe(function (data) {
            if (_this.current_state.phoneMessageSid === data.sid) {
                if (data.message === "Sí, confirmo") {
                    _this.current_state.verified_phone = 1;
                    _this.current_state.v_phone = _this.current_state.phone;
                    _this.alertService.alert_success("¡Listo!", "Has verificado tu teléfono.");
                }
                else if (data.message === "No confirmo") {
                    _this.current_state.verified_phone = 2;
                    _this.alertService.alert_error("¡Oh no!", "Has rechazado la verificación de tu teléfono.");
                }
            }
        }, function (error) {
            console.error(error);
        });
        this.socketService.getUserEmailVerify().subscribe(function (data) {
            if (_this.current_state.emailToken === data.token) {
                _this.current_state.verified_email = 1;
                _this.current_state.v_email = _this.current_state.email;
                _this.alertService.alert_success("¡Listo!", "Has verificado tu correo electrónico.");
            }
        }, function (error) {
            console.error(error);
        });
    };
    GeneralService.prototype.reload = function () {
        if (this.expedientService.state.id_user) {
            this.get_patient_data();
        }
    };
    GeneralService.prototype.get_current_state = function () {
        return this.current_state;
    };
    GeneralService.prototype.get_patient_data = function () {
        var _this = this;
        var load = {
            id_user: this.expedientService.state.id_user,
        };
        this.endpoint.expedient_general_get_general(load).subscribe(function (data) {
            // data.image = this.endpoint.get_file_endpoint(
            //     "profile_images",
            //     data.image
            // );
            _this.current_state = data;
        }, function (error) { return console.error(error); }, function () {
            _this.save();
            _this.next();
        });
    };
    GeneralService.prototype.refresh_forms = function () {
        this.forms_state.next(this.forms);
    };
    GeneralService.prototype.next = function () {
        if (this.module_state)
            this.module_state.next(this.current_state);
        else {
            this.module_state = new BehaviorSubject(this.current_state);
            this.current = this.module_state.asObservable();
        }
    };
    GeneralService.prototype.valid = function () {
        return !this.current_state.loading && this.forms && this.forms.general && this.forms.general.valid
            ? this.forms.general.valid()
            : false;
    };
    GeneralService.prototype.set_form = function (form) {
        this.forms[form.name] = form.module;
    };
    GeneralService.prototype.save = function () {
        localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
    };
    GeneralService.prototype.submit = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        if (!(this.forms && this.forms.general && this.forms.general.valid()) || this.current_state.loading) {
            callback();
            return false;
        }
        this.current_state.submitted = true;
        this.current_state.loading = true;
        this.module_state.next(this.current_state);
        var formData = new FormData();
        if (this.current_state.image_upload) {
            formData.append("image_upload", this.current_state.image_upload, this.current_state.image_upload.name);
        }
        for (var item in this.current_state) {
            if (item != "image_upload" && this.current_state[item]) {
                formData.append(item, this.current_state[item]);
            }
        }
        if (this.expedientService.state.method == "insert") {
            this.insert(formData, callback);
        }
        else {
            this.update(formData, callback);
        }
    };
    GeneralService.prototype.insert = function (formData, callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.endpoint.expedient_general_insert_general(formData).subscribe(function (data) {
            if (_this.expedientService.state.version == "expedient") {
                localStorage.removeItem("inExpedientGeneral");
                _this.toastr.success(data["message"], data["title"]);
                _this.current_state.loading = false;
                _this.expedientService.update_global();
                _this.module_state.next(_this.current_state);
                if (_this.forms.general)
                    _this.forms.general.unsubmit();
                _this.clean();
                callback(data);
            }
            else if (_this.expedientService.state.version == "new_patient") {
                callback(data);
            }
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
            callback(error);
        });
    };
    GeneralService.prototype.update = function (formData, callback) {
        var _this = this;
        if (callback === void 0) { callback = function (callback_data) { }; }
        this.endpoint.expedient_general_update_general(formData).subscribe(function (data) {
            localStorage.removeItem("inExpedientGeneral");
            _this.toastr.success(data["message"], data["title"]);
            _this.current_state.loading = false;
            _this.expedientService.update_global();
            _this.module_state.next(_this.current_state);
            if (_this.forms.general)
                _this.forms.general.unsubmit();
            _this.expedientService.change_method("show");
            _this.reload();
            callback();
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
            _this.current_state.loading = false;
            _this.module_state.next(_this.current_state);
        });
    };
    GeneralService.prototype.cancel = function () {
        var _this = this;
        this.pageService.cancel_new_patient(function () {
            _this.clean();
            _this.endpoint.redirect_to("/mis_pacientes");
        });
    };
    GeneralService.prototype.clean = function () {
        this.internal_clean();
        this.module_state.next(this.current_state);
        this.save();
    };
    GeneralService.prototype.validate = function (callback) {
        if (callback === void 0) { callback = function (callback_data) { }; }
        var birthdate = this.current_state.birthdate;
        birthdate.setHours(0, 0, 0, 1);
        var date = new Date();
        date.setHours(23, 59, 59, 999);
        this.current_state.minor = this.diferenciaDeAniosMenorQue(birthdate, date, 18);
        callback(this.current_state);
    };
    GeneralService.prototype.internal_clean = function () {
        this.current_state = {
            id_user: "",
            id_country: "97",
            id_department: "",
            id_town: "",
            id_education: "",
            id_gender: "1",
            minor: false,
            firstname: "",
            lastname: "",
            birthdate: "",
            birthplace: "",
            address: "",
            image: null,
            image_upload: "",
            tutor_email: "",
            identification: "",
            id_blood: "2",
            organ_donor: [],
            glasses: [],
            phone: "",
            v_phone: "",
            phoneMessageSid: "",
            verified_phone: 2,
            email: "",
            v_email: "",
            emailToken: "",
            verified_email: 2,
            loading: false,
            submitted: false,
            timestamp: new Date(),
        };
        this.patientService.set_attribute(this.current_state, "general");
    };
    GeneralService.prototype.verify_phone = function () {
        var _this = this;
        Swal.fire({
            title: "Verificar Teléfono",
            html: "La verificaci\u00F3n del tel\u00E9fono fue enviada por Whatsapp al <strong>" + this.current_state.phone + "</strong>",
            showCloseButton: true,
            onBeforeOpen: function () {
                Swal.showLoading();
                var load = {
                    phone: _this.current_state.phone,
                    name: _this.current_state.firstname + " " + _this.current_state.lastname
                };
                _this.endpoint.whatsapp_send_send_user_verify_phone(load).subscribe(function (data) {
                    _this.current_state.phoneMessageSid = data;
                }, function (error) {
                    console.error(error);
                    _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
                });
            },
            allowOutsideClick: function () { return !Swal.isLoading(); },
        });
        return;
    };
    GeneralService.prototype.verify_email = function () {
        var _this = this;
        Swal.fire({
            title: "Verificar Correo Electrónico",
            html: "La verificaci\u00F3n del correo electr\u00F3nico fue enviada al <strong>" + this.current_state.email + "</strong>",
            showCloseButton: true,
            onBeforeOpen: function () {
                Swal.showLoading();
                var load = {
                    email: _this.current_state.email,
                };
                _this.endpoint.settings_security_send_verify_email(load).subscribe(function (data) {
                    _this.current_state.emailToken = data;
                }, function (error) {
                    console.error(error);
                    _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
                });
            },
            allowOutsideClick: function () { return !Swal.isLoading(); },
        });
        return;
    };
    GeneralService.prototype.diferenciaDeAniosMenorQue = function (fecha1, fecha2, numero) {
        var fechaInicio = +new Date(fecha1);
        var fechaFin = +new Date(fecha2);
        var diferenciaEnMilisegundos = fechaFin - fechaInicio;
        var milisegundosEnUnAnio = 1000 * 60 * 60 * 24 * 365.25;
        var diferenciaEnAnios = diferenciaEnMilisegundos / milisegundosEnUnAnio;
        return diferenciaEnAnios < numero;
    };
    GeneralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeneralService_Factory() { return new GeneralService(i0.ɵɵinject(i1.AlertService), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i4.PageService), i0.ɵɵinject(i5.SocketService), i0.ɵɵinject(i6.NewPatientService), i0.ɵɵinject(i7.ExpedientService)); }, token: GeneralService, providedIn: "root" });
    return GeneralService;
}());
export { GeneralService };
