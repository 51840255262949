import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { AES } from 'crypto-ts';
import { environment } from 'src/environments/environment';
var SheduleModalDateComponent = /** @class */ (function () {
    function SheduleModalDateComponent(endpoint, toastr, pageService, patientService, sweel) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.patientService = patientService;
        this.sweel = sweel;
        this.data = {
            id_patient: "",
            description: "",
            fecha_date: "",
            hour_date: "",
            duration: "",
            id_date: "",
            paciente: "",
        };
    }
    SheduleModalDateComponent.prototype.ngOnInit = function () {
        this.form_configs();
    };
    SheduleModalDateComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            submit: function () { return _this.submit(); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "description",
                            type: "textarea",
                            label: "Descripción",
                            placeholder: function () { return ""; },
                            col_class: "col-md-12",
                            rows: 2.5,
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "fecha_date",
                            type: "calendar",
                            label: "Fecha",
                            placeholder: function () { return "#######"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "hour_date",
                            type: "text",
                            label: "Hora",
                            placeholder: function () { return ""; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "duration",
                            type: "text",
                            label: "Duración",
                            placeholder: function () { return ""; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                    ],
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'right',
                                    },
                                    disabled: function () { return false; },
                                    click: function () { return _this.close(); }
                                },
                                {
                                    class: "btn btn-sm btn-primary ml-auto",
                                    label: "Actualizar Cita",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    disabled: function () { return _this.form_ref ? !_this.form_ref.valid() : false; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    SheduleModalDateComponent.prototype.submit = function () {
        var _this = this;
        this.endpoint.update_dates(this.data).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.close();
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    SheduleModalDateComponent.prototype.delete_date = function () {
        var _this = this;
        this.sweel.confirm("Eliminar Cita", "Por favor confirme que desea eliminar la cita de  <code>" + this.data.paciente + "</code>", "warning").then(function (res) {
            if (res) {
                _this.delete(_this.data.id_date);
            }
        });
    };
    SheduleModalDateComponent.prototype.change_page = function () {
        this.patientService.change_patient_id(this.data.id_patient);
        localStorage.setItem("id_date", this.data.id_date);
        localStorage.setItem("detail", AES.encrypt(this.data.description, environment.secure_key).toString());
        this.pageService.closeModal("");
        this.endpoint.redirect_to("/expediente/nueva-consulta/general/general");
    };
    SheduleModalDateComponent.prototype.delete = function (id) {
        var _this = this;
        var params = {
            id: id
        };
        this.endpoint.delete_dates(params).subscribe(function (res) {
            if (res) {
                _this.toastr.info("Operación Exitosa", "Cita eliminada correctamente");
                _this.pageService.closeModal("");
            }
        });
    };
    SheduleModalDateComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return SheduleModalDateComponent;
}());
export { SheduleModalDateComponent };
