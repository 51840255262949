import { PageService } from 'src/app/services/page.service';
var ShowExamsLaboratoryComponent = /** @class */ (function () {
    function ShowExamsLaboratoryComponent(pageService) {
        this.pageService = pageService;
    }
    ShowExamsLaboratoryComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return ShowExamsLaboratoryComponent;
}());
export { ShowExamsLaboratoryComponent };
