import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./emergency/emergency.module.ngfactory").then(function (m) { return m.ContactsEmergencyModuleNgFactory; }); }, ɵ1 = function () { return import("./doctor/doctor.module.ngfactory").then(function (m) { return m.ContactsDoctorModuleNgFactory; }); };
var routes = [
    {
        path: '',
        redirectTo: 'emergencia',
        pathMatch: 'prefix'
    },
    {
        path: 'emergencia',
        loadChildren: ɵ0
    },
    {
        path: 'medico',
        loadChildren: ɵ1
    },
];
var ContactsModule = /** @class */ (function () {
    function ContactsModule() {
    }
    return ContactsModule;
}());
export { ContactsModule };
export { ɵ0, ɵ1 };
