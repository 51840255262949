import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { BoldListComponent } from 'src/app/shared/ui/bold_list/bold_list.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';
var ConsultsMedicalComponent = /** @class */ (function () {
    function ConsultsMedicalComponent(pageService, endpoint, sweel) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.sweel = sweel;
        this.data = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
        this.bold_list_config();
    }
    ConsultsMedicalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.page_subscription = this.pageService.current.subscribe(function (state) {
            _this.page_state = state;
        });
    };
    ConsultsMedicalComponent.prototype.ngOnDestroy = function () {
        this.page_subscription.unsubscribe();
    };
    ConsultsMedicalComponent.prototype.bold_list_config = function () {
        this.bold_list = {
            configs: {
                title: "Consultas",
                //icon: "fa-duotone fa-calendar-check",
                paginate: true,
                search: true,
                open: true,
            },
            filters: {
                limit: 50,
                skip: 0,
                order_asc: false,
                order_by: "created_at"
            },
            headers: [
                {
                    label: "Diagnóstico",
                    name: "diagnosis",
                    order_by: "diagnosis"
                },
                {
                    label: "Fecha",
                    name: "created_at",
                    order_by: "created_at",
                    type: "date",
                    class: "secondary"
                },
            ],
            contents: [
                function (item) { return item.patient_name; }
            ]
        };
    };
    ConsultsMedicalComponent.prototype.on_update = function () {
        if (this.bold_list_ref)
            this.bold_list_ref.update();
    };
    ConsultsMedicalComponent.prototype.update = function (data) {
        var _this = this;
        if (true) {
            if (this.search_button_ref)
                data.search_word = this.search_button_ref.data;
            var load = tslib_1.__assign({}, data);
            this.endpoint.get_my_consults(load).subscribe(function (data) { return _this.data = data; }, function (err) { return console.error(err); });
        }
    };
    ConsultsMedicalComponent.prototype.events = function (data) {
        this.selected_item = data.item;
        if (data.name == "Detalles" || data.name == "row_click") {
            this.show_consult = true;
        }
    };
    return ConsultsMedicalComponent;
}());
export { ConsultsMedicalComponent };
