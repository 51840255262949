import * as tslib_1 from "tslib";
import { CustomClass } from "./custom-class";
;
;
var RecommendationClass = /** @class */ (function (_super) {
    tslib_1.__extends(RecommendationClass, _super);
    function RecommendationClass(data) {
        var _this = _super.call(this) || this;
        if (data)
            _this.overwrite(data);
        else
            _this.reset();
        return _this;
    }
    RecommendationClass.prototype.overwrite = function (data) {
        _super.prototype.overwrite.call(this, data);
        this.id = data.id;
        this.recomendations = data.recomendations;
        this.hidden_notes = data.hidden_notes;
    };
    RecommendationClass.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.id = null;
        this.recomendations = "";
        this.hidden_notes = "";
    };
    RecommendationClass.prototype.format = function () {
        if (this.recomendations)
            this.recomendations = this.recomendations.trim();
        if (this.hidden_notes)
            this.hidden_notes = this.hidden_notes.trim();
        return this;
    };
    RecommendationClass.prototype.valid = function () {
        if (this.recomendations.trim().length > 0) {
            return true;
        }
        return false;
    };
    RecommendationClass.prototype.to_string = function () {
        var data = this.format();
        return data;
    };
    RecommendationClass.prototype.raw = function () {
        return {
            id: this.id,
            recomendations: this.recomendations,
            hidden_notes: this.hidden_notes,
        };
    };
    return RecommendationClass;
}(CustomClass));
export { RecommendationClass };
