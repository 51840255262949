import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { DatatableComponent } from 'src/app/shared/ui/datatable/datatable.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';
var DateComponent = /** @class */ (function () {
    function DateComponent(endpoint, pageService, sweel, toast, acountservice, patientService) {
        this.endpoint = endpoint;
        this.pageService = pageService;
        this.sweel = sweel;
        this.toast = toast;
        this.acountservice = acountservice;
        this.patientService = patientService;
        this.data = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
    }
    DateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.doctor_subscription = this.patientService.patient_data_subscription.subscribe(function (data) { return _this.patient_data = data; });
        this.datatable_config();
        this.doctor_subscription = this.endpoint.refresh$.subscribe(function (data) {
            _this.update(_this.data);
        });
    };
    DateComponent.prototype.datatable_config = function () {
        this.datatable = {
            configs: {
                title: "Citas",
                //icon: "fa-duotone fa-address-book",
                paginate: false,
                search: false
            },
            filters: {
                limit: 20,
                skip: 0
            },
            headers: [
                {
                    label: "Descripción",
                    name: "description",
                    order_by: "description"
                },
                {
                    label: "Fecha",
                    name: "fecha_date",
                    order_by: "fecha_date",
                    type: "date"
                },
                {
                    label: "Hora",
                    name: "hour_date",
                    order_by: "hour_date",
                },
                {
                    label: "duración",
                    name: "duration",
                    order_by: "duration",
                },
            ],
            events: [
                {
                    name: "Detalles",
                    icon: "fa-duotone fa-file-circle-info text-info"
                },
                {
                    name: "Modificar",
                    icon: "fa-duotone fa-edit text-warning"
                },
                {
                    name: "Eliminar",
                    icon: "fa-duotone fa-trash-alt text-danger",
                },
            ]
        };
    };
    DateComponent.prototype.on_update = function () {
        if (this.datatable_ref)
            this.datatable_ref.update();
    };
    DateComponent.prototype.update = function (data) {
        var _this = this;
        if (this.patient_data.general.id_user) {
            if (this.search_button_ref)
                data.search_word = this.search_button_ref.data;
            var load = tslib_1.__assign({ id: this.patient_data.general.id_user }, data);
            this.endpoint.get_dates_of_patients(load).subscribe(function (data) { return _this.data = data; }, function (err) { return console.error(err); });
        }
    };
    DateComponent.prototype.events = function (data) {
        var _this = this;
        if (data.name == "Detalles" || data.name == "row_click") {
            this.selected_item = data.item;
            this.pageService.openModal(this.showModal, "lg");
        }
        if (data.name == "Eliminar") {
            this.sweel.confirm("Eliminar Cita", "Por favor confirme que desea eliminar la cita programada", "warning").then(function (res) {
                if (res) {
                    _this.delete(data.item.id_date);
                }
            });
        }
        if (data.name == "Modificar") {
            this.selected_item = data.item;
            this.pageService.openModal(this.updateModal, "lg");
        }
    };
    DateComponent.prototype.delete = function (id) {
        var _this = this;
        var params = {
            id: id
        };
        this.endpoint.delete_dates(params).subscribe(function (res) {
            if (res) {
                _this.toast.info("Operación Exitosa", "Cita eliminada correctamente");
            }
        });
    };
    DateComponent.prototype.create = function () {
        this.pageService.openModal(this.createModal, "lg");
    };
    return DateComponent;
}());
export { DateComponent };
