import { DependantsService } from '../dependants.service';
var UpdateRelationsDependantsComponent = /** @class */ (function () {
    function UpdateRelationsDependantsComponent(moduleService) {
        this.moduleService = moduleService;
    }
    UpdateRelationsDependantsComponent.prototype.close = function () {
        this.moduleService.cancel();
    };
    return UpdateRelationsDependantsComponent;
}());
export { UpdateRelationsDependantsComponent };
