import { AppService } from "src/app/services/app.service";
import { WebDBService } from "./webdb.service";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "./webdb.service";
import * as i2 from "./app.service";
var CatalogsService = /** @class */ (function () {
    function CatalogsService(dbService, endpoint) {
        this.dbService = dbService;
        this.endpoint = endpoint;
        this.jsons = {
            vaccines: ["descriptions", "dose"],
        };
        this.init();
    }
    CatalogsService.prototype.init = function () {
        this.clean();
        this.next();
        if (environment.dev) {
            this.fill();
        }
        else {
            var date = new Date();
            date.setHours(0, 0, 0, 0);
            var s = this.get_session();
            if (+date + "" != s) {
                this.fill();
            }
            else {
                this.fill();
                // this.bulk();
            }
        }
    };
    CatalogsService.prototype.fill = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function () { }; }
        this.endpoint.get_catalogs({ catalogs: this.get_keys() }).subscribe(function (catalogs) {
            _this.dbService.overwrite(catalogs, function () {
                _this.get_keys().forEach(function (value) {
                    var catalog = value ? catalogs[value] : [];
                    if (_this.jsons[value]) {
                        _this.jsons[value].forEach(function (j) {
                            catalog.forEach(function (c) {
                                if (c[j])
                                    c[j] = JSON.parse(c[j]);
                            });
                        });
                    }
                    if (value)
                        _this.catalogs[value] = catalog;
                });
                var date = new Date();
                localStorage.setItem("fdbv", +date + "");
                date.setHours(0, 0, 0, 0);
                localStorage.setItem("dbv", +date + "");
                console.warn("UPDATE:fill: IndexedDB");
                callback();
            });
        }, function (error) {
            console.error(error);
        });
    };
    CatalogsService.prototype.bulk = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = function () { }; }
        this.get_keys().forEach(function (value) {
            _this.dbService.getAll(value).subscribe(function (data) {
                _this.get_keys().forEach(function (value) {
                    var catalog = value ? data : [];
                    if (_this.jsons[value]) {
                        _this.jsons[value].forEach(function (j) {
                            catalog.forEach(function (c) {
                                if (c[j]) {
                                    c[j] = JSON.parse(c[j]);
                                }
                            });
                        });
                    }
                    if (value)
                        _this.catalogs[value] = catalog;
                });
            });
        });
        console.warn("UPDATE:bulk: IndexedDB");
        callback();
    };
    CatalogsService.prototype.load_catalog = function (catalog) {
        var _this = this;
        this.dbService.getAll(catalog).subscribe(function (data) {
            _this.catalogs[catalog] = data;
        });
    };
    CatalogsService.prototype.set_catalog = function (catalog, value) {
        this.catalogs[catalog] = value;
    };
    CatalogsService.prototype.next = function () {
        if (this.behavior_catalogs) {
            this.behavior_catalogs.next(this.catalogs);
        }
        else {
            this.behavior_catalogs = new BehaviorSubject(this.catalogs);
            this.observable_catalogs = this.behavior_catalogs.asObservable();
        }
    };
    CatalogsService.prototype.clean = function () {
        this.catalogs = {
            pathologies: [],
            allergies_reactions_levels: [],
            allergies_reactions_types: [],
            bloods: [],
            chat_conversation_end_types: [],
            chat_message_types: [],
            civil_statuses: [],
            consults_statuses: [],
            consults_types: [],
            countries: [],
            departments: [],
            doctors_patients_levels: [],
            doctors_specialties: [],
            educations: [],
            exams_names: [],
            exams_names_2: [],
            exams_types: [],
            file_types: [],
            genders: [],
            institutions_areas: [],
            institutions_roles: [],
            institutions_types: [],
            institutions_users_roles: [],
            medicines_concentrations: [],
            medicines_days: [],
            medicines_frecuencies: [],
            medicines_presentations: [],
            medicines_ways: [],
            procedures_types: [],
            towns: [],
            units_breathing_rate: [],
            units_diastolic_pressure: [],
            units_eye_vision: [],
            units_glasgow_eye: [],
            units_glasgow_move: [],
            units_glasgow_talk: [],
            units_heart_rate: [],
            units_height: [],
            units_IMC: [],
            units_pulse: [],
            units_sistolic_pressure: [],
            units_temp: [],
            units_weight: [],
            units_medicine_dosage: [],
            users_roles: [],
            users_types: [],
            tf: [],
            profiles_educations_types: [],
            profiles_educations_study_types: [],
            profiles_educations_modes: [],
            profiles_educations_honors: [],
            profiles_educations_places: [],
            profiles_educations_specialties: [],
            profiles_educations_subspecialties: [],
            profiles_experiences_types: [],
            specialties_areas: [],
            vaccines: [],
            vaccines_dosis_statuses: [],
            vaccines_people_groups: [],
            relationships: [],
        };
    };
    CatalogsService.prototype.get_keys = function () {
        if (!this.catalogs)
            this.clean();
        var keys = [];
        for (var key in this.catalogs) {
            keys.push(key);
        }
        return keys;
    };
    CatalogsService.prototype.generate = function () {
        this.clean();
        var result = [];
        for (var attr in this.catalogs) {
            var obj = {};
            obj.store = attr;
            obj.storeConfig = { keyPath: "id", autoIncrement: false };
            obj.storeSchema = [];
            result.push(obj);
        }
        return result;
    };
    CatalogsService.prototype.get_session = function () {
        return localStorage.getItem("dbv");
    };
    CatalogsService.prototype.get_full_session = function () {
        return localStorage.getItem("fdbv");
    };
    CatalogsService.prototype.selectWhereLike = function (list, columns) {
        return this.dbService.selectWhereLike(list, columns);
    };
    CatalogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatalogsService_Factory() { return new CatalogsService(i0.ɵɵinject(i1.WebDBService), i0.ɵɵinject(i2.AppService)); }, token: CatalogsService, providedIn: "root" });
    return CatalogsService;
}());
export { CatalogsService };
