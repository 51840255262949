import { OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { SocketService } from 'src/app/services/socket.service';
var ChatComponent = /** @class */ (function () {
    function ChatComponent(endpoint, toastr, pageService, socketService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.socketService = socketService;
        this.avatar_configs = {
            size: "2.5rem",
            font_size: "1.5rem",
        };
        this.clean();
        this.messages = [];
    }
    ChatComponent.prototype.ngOnInit = function () {
        this.get_messages();
        this.init_socket();
    };
    ChatComponent.prototype.ngOnDestroy = function () {
    };
    ChatComponent.prototype.init_socket = function () {
        var _this = this;
        this.socketService.getMessage().subscribe(function (_) {
            _this.get_messages();
        }, function (error) {
            console.error(error);
        });
    };
    ChatComponent.prototype.get_messages = function () {
        var _this = this;
        var load = {
            id_user: this.data.id_user
        };
        this.endpoint.chat_get_messages(load).subscribe(function (data) {
            _this.messages = data;
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
        });
    };
    ChatComponent.prototype.send_message = function () {
        var _this = this;
        var load = {
            id_user: this.data.id_user,
            id_type: this.message.id_type,
            content: this.message.content.trim(),
            created_at: this.message.created_at,
        };
        this.endpoint.chat_send_message(load).subscribe(function (_) {
            _this.clean();
        }, function (error) {
            console.error(error);
            _this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
        });
    };
    ChatComponent.prototype.load_image = function (url) {
        return this.endpoint.get_file_endpoint("profile_images", url);
    };
    ChatComponent.prototype.close = function () {
        this.pageService.closeModal(true);
    };
    ChatComponent.prototype.clean = function () {
        this.message = {
            content: ""
        };
    };
    ChatComponent.prototype.submit = function () {
        if (!(this.message.content.trim().length > 0))
            return;
        (new Date()).toLocaleTimeString();
        this.message.created_at = new Date().toISOString().slice(0, 19) + "-06:00";
        console.log("FIX THIS CJ");
        console.log(this.message.created_at);
        this.message.subject = "from";
        this.message.status = 1;
        this.message.id_type = 1;
        this.messages.push(this.message);
        this.send_message();
    };
    return ChatComponent;
}());
export { ChatComponent };
