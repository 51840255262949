import { EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
var CreateMedicineComponent = /** @class */ (function () {
    function CreateMedicineComponent(endpoint, toastr, patientServiceService, pageService, catalogsService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.patientServiceService = patientServiceService;
        this.pageService = pageService;
        this.catalogsService = catalogsService;
        this.update = new EventEmitter;
        this.data = {
            id_patient: "",
            name: "",
            frecuency: "",
            concentration: "",
            amount: "",
            id_presentation: "",
            id_concentration: "",
            id_way: "",
            id_frecuency: "1",
            id_days: "1",
            days: ""
        };
        this.form_configs();
    }
    CreateMedicineComponent.prototype.ngOnInit = function () {
    };
    CreateMedicineComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            submit: function () { return _this.submit(); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "name",
                            type: "name",
                            label: "Nombre",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 45; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "amount",
                            type: "number",
                            label: "Cantidad",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_presentation",
                            type: "select",
                            label: "Presentación",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.medicines_presentations; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "concentration",
                            type: "text",
                            label: "Concentración",
                            small: "",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 45; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "id_way",
                            type: "select",
                            label: "Vía",
                            placeholder: function () { return "- Seleccione -"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            catalog: {
                                list: function () { return _this.catalogsService.catalogs.medicines_ways; },
                                value: "id",
                                text: "name"
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "frecuency",
                            type: "decimal",
                            label: "Frecuencia",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 45; }
                                }
                            },
                            filter: {
                                name: "id_frecuency",
                                list: function () { return _this.catalogsService.catalogs.medicines_frecuencies; },
                                value: "id",
                                text: "name",
                                tooltip: "name"
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "days",
                            type: "number",
                            label: "Duración",
                            placeholder: function () { return _this.data.id_days == "1" ? "1" : _this.data.id_days == "4" ? "∞" : "##"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return !(_this.data.id_days == "1" || _this.data.id_days == "4"); }
                                },
                                maxlength: {
                                    value: function () { return 45; }
                                }
                            },
                            filter: {
                                name: "id_days",
                                list: function () { return _this.catalogsService.catalogs.medicines_days; },
                                value: "id",
                                text: "name",
                                tooltip: "name",
                                change: function () {
                                    if (_this.data.id_days == "1" || _this.data.id_days == "4")
                                        _this.data.days = "";
                                }
                            },
                            disabled: function () { return _this.data.id_days == "1" || _this.data.id_days == "4"; },
                        },
                    ]
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'right',
                                    },
                                    disabled: function () { return false; },
                                    click: function () { return _this.close(); }
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Agregar Medicina",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    disabled: function () { return _this.form_ref ? !_this.form_ref.valid() : false; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    CreateMedicineComponent.prototype.submit = function () {
        var _this = this;
        this.data.id_patient = this.patientServiceService.get_patient_id();
        this.endpoint.insert_medicines_prescription(this.data).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.update.emit();
            _this.close();
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    CreateMedicineComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return CreateMedicineComponent;
}());
export { CreateMedicineComponent };
