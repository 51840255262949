var ErrorSmallComponent = /** @class */ (function () {
    function ErrorSmallComponent() {
    }
    ErrorSmallComponent.prototype.ngOnChanges = function (changes) {
        if (changes.error) {
            this.error = changes.error.currentValue;
        }
        if (changes.title) {
            this.title = changes.title.currentValue;
        }
        if (changes.class) {
            this.class = changes.class.currentValue;
        }
        if (changes.inline) {
            this.inline = changes.inline.currentValue;
        }
    };
    return ErrorSmallComponent;
}());
export { ErrorSmallComponent };
