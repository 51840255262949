import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
var CreateDateComponent = /** @class */ (function () {
    function CreateDateComponent(endpoint, toastr, pageService, patientServiceService, accountService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.patientServiceService = patientServiceService;
        this.accountService = accountService;
        this.data = {
            id_patient: "",
            id_doctor: "",
            description: "",
            fecha_date: "",
            hour_date: "",
            duration: ""
        };
    }
    CreateDateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.doctor_subscription = this.patientServiceService.patient_data_subscription.subscribe(function (data) { return _this.patient_data = data; });
        this.doctor_data = this.accountService.get_current_state();
        this.form_configs();
    };
    CreateDateComponent.prototype.form_configs = function () {
        var _this = this;
        this.form_config = {
            submit: function () { return _this.submit(); },
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            name: "description",
                            type: "textarea",
                            label: "Descripción",
                            placeholder: function () { return ""; },
                            col_class: "col-md-12",
                            rows: 2.5,
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 100; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "fecha_date",
                            type: "calendar",
                            label: "Fecha",
                            placeholder: function () { return "#######"; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "hour_date",
                            type: "text",
                            label: "Hora",
                            placeholder: function () { return ""; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                        {
                            name: "duration",
                            type: "text",
                            label: "duración",
                            placeholder: function () { return ""; },
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: function () { return true; }
                                },
                                maxlength: {
                                    value: function () { return 500; }
                                }
                            },
                            disabled: function () { return false; },
                        },
                    ],
                },
                {
                    class: "row",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: 'right',
                                    },
                                    disabled: function () { return false; },
                                    click: function () { return _this.close(); }
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Agregar Cita",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: 'right',
                                    },
                                    disabled: function () { return _this.form_ref ? !_this.form_ref.valid() : false; },
                                },
                            ]
                        },
                    ],
                },
            ]
        };
    };
    CreateDateComponent.prototype.submit = function () {
        var _this = this;
        this.data.id_patient = this.patientServiceService.get_patient_id();
        this.data.id_doctor = this.doctor_data.id;
        this.endpoint.insert_dates(this.data).subscribe(function (data) {
            _this.toastr.success(data["message"], data["title"]);
            _this.close();
        }, function (error) {
            _this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    };
    CreateDateComponent.prototype.close = function () {
        this.pageService.closeModal("");
    };
    return CreateDateComponent;
}());
export { CreateDateComponent };
